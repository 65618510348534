import dynamic from "next/dynamic";

import useAuthentication from "hooks/useAuthentication";

const ActionCableInitializer = dynamic(
  () => import("./ActionCableInitializer"),
  { ssr: false }
);

const ActionCableContainer = () => {
  const { authenticated } = useAuthentication();

  if (typeof window !== "undefined" && authenticated) {
    return <ActionCableInitializer />;
  }

  return <></>;
};

export default ActionCableContainer;
