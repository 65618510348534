const _colors = {
  white: "#ffffff",
  inactiveGray: "#cccccc",
  activeBlack: "#1f1f20",
  subtleBlack: "#3a3a3c",
  grayGray: "#555555",
  shadyGray: "#999999",
  nearlyWhite: "#f2f2f2",

  // 11 main colors for Sector Distribution chart
  smartTeal: "#24a9a7",
  seaGreen: "#1c896e",
  bullishGreen: "#75ac33",
  grass: "#9fc571",
  teaGreen: "#e1e876",
  mellowYellow: "#fbbc04",
  intelligentSilver: "#707070",
  denim: "#0d4567",
  utahSky: "#0276be",
  cornflower: "#86bbe5",
  glacialBlue: "#05bdd9",

  // Extra chart colors
  jade: "#4db364", // a.k.a. gradientBottom
  inkPink: "#b63998",
  teaOrange: "#f59331",
  bearishRed: "#ac334b",
  amchartRed: "#cc0000",

  gradientOne: "#32ac91",
  gradientAverage: "#39ae86",
  gradientTwo: "#3fb07b",
};

export const colors = {
  ..._colors,
  bullishColor: _colors.bullishGreen,
  bearishColor: _colors.bearishRed,
  highlightColor: _colors.smartTeal,
};
