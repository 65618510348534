import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { twMerge } from "tailwind-merge";

import { faBell } from "@fortawesome/free-regular-svg-icons/faBell";
import { faBuilding } from "@fortawesome/free-regular-svg-icons/faBuilding";
import { faComments } from "@fortawesome/free-regular-svg-icons/faComments";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faListAlt } from "@fortawesome/free-regular-svg-icons/faListAlt";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";

import FontAwesomeIcon from "components/FontAwesomeIcon";
import GoogleSSO from "components/GoogleSSO";
import useSignInSignUp from "components/SignInSignUpModal/useSignInSignUp";
import useActivityTracker from "hooks/useActivityTracker";
import useAuthentication from "hooks/useAuthentication";
import useMediaQuery from "hooks/useMediaQuery";
import useTheme from "hooks/useTheme";
import useWindowSize from "hooks/useWindowSize";
import { emberAppBaseUrl } from "utils/envVariables";

import style from "./style.module.scss";
import { TopNavBarContext } from "./TopNavBarContext";
import TopNavBarMobile from "./TopNavBarMobile";
import TopNavBarSearchInput from "./TopNavBarSearchInput";

const TopNavBarAuthenicatedMenuMobile = dynamic(
  () => import("./TopNavBarAuthenicatedMenuMobile"),
  {
    ssr: false,
  }
);

const TopNavBarAuthenticatedMenu = dynamic(
  () => import("./TopNavBarAuthenticatedMenu"),
  { ssr: false }
);
const insights = {
  name: "Insights",
  href: `${emberAppBaseUrl}/insights`,
};
const events = { name: "Events", href: `${emberAppBaseUrl}/events` };
const analytics = { name: "Analytics", href: `${emberAppBaseUrl}/analytics` };
const directory = {
  name: "Directory",
  href: `${emberAppBaseUrl}/insight-provider-directory`,
};

const TopNavBar = () => {
  const {
    showBackButton,
    rightActionButtons,
    totalUnreadMessageGroups,
    totalUnreadNotifications,
  } = useContext(TopNavBarContext);

  const totalUnread =
    (totalUnreadMessageGroups || 0) + (totalUnreadNotifications > 0 ? 1 : 0);

  const { toggleModal } = useSignInSignUp();

  const router = useRouter();
  const { mobile } = useMediaQuery();
  const { trackEvent } = useActivityTracker();

  const { authenticated, account: loggedInAccount } = useAuthentication();

  const width = useWindowSize();
  const [showMore, setShowMore] = useState(false);

  const navElement = useRef<HTMLDivElement>(null);

  const responsiveHomeUrl =
    emberAppBaseUrl + (authenticated ? "/dashboard" : "/home/");

  const dashboard = useMemo(
    () => ({
      name: loggedInAccount?.isCorporateOfficer ? "Home" : "Dashboard",
      href: `/dashboard`,
      currentRoute: router.asPath.startsWith("/dashboard"),
      active: router.asPath.startsWith("/dashboard"),
      isNextJsRoute: !loggedInAccount?.isComplianceOfficer,
    }),
    [
      loggedInAccount?.isComplianceOfficer,
      loggedInAccount?.isCorporateOfficer,
      router.asPath,
    ]
  );

  const discussions = useMemo(
    () => ({
      name: "Discussions",
      href: "/discussions?tab=for-me",
      currentRoute: router.asPath.startsWith("/discussions"),
      active: router.asPath.startsWith("/discussions"),
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const watchlists = useMemo(
    () => ({
      name: "Watchlists",
      href: mobile ? `${emberAppBaseUrl}/watchlists` : "/watchlists",
      currentRoute: router.asPath.startsWith("/watchlists"),
      active: router.asPath.startsWith("/watchlists"),
      isNextJsRoute: true,
    }),
    [mobile, router.asPath]
  );

  const collections = useMemo(
    () => ({
      name: "Collections",
      href: mobile ? `${emberAppBaseUrl}/collections` : `/collections`,
      currentRoute: router.asPath === "/collections",
      active: router.asPath.startsWith("/collections"),
      isNextJsRoute: true,
    }),
    [mobile, router.asPath]
  );

  const tools = useMemo(
    () => ({
      name: "Tools",
      href: `/tools`,
      active: router.asPath.startsWith("/tools"),
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const targeting = useMemo(
    () => ({
      name: "Target",
      href: "/targeting",
      active: router.asPath === "/targeting",
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const contacts = useMemo(
    () => ({
      name: "Contacts",
      href: "/targeting/contacts",
      active: router.asPath.startsWith("/targeting/contacts"),
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const peersMonitoring = useMemo(
    () => ({
      name: "Monitor",
      href: "/peers-monitor",
      active: router.asPath.startsWith("/peers-monitor"),
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const peersBenchmarks = useMemo(
    () => ({
      name: "Benchmark",
      href: "/peers-benchmark",
      active: router.asPath.startsWith("/peers-benchmark"),
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const chatbot = useMemo(
    () => ({
      name: "Chatbot",
      href: `/chatbot`,
      active: router.asPath.startsWith("/chatbot"),
      isNextJsRoute: true,
    }),
    [router.asPath]
  );

  const allNavTabs = useMemo(() => {
    const base = [
      dashboard,
      discussions,
      watchlists,
      collections,
      tools,
      events,
    ];

    if (loggedInAccount?.isPreviewPass) {
      return base;
    }

    if (loggedInAccount?.isComplianceOfficer) {
      return [
        dashboard,
        discussions,
        insights,
        events,
        collections,
        analytics,
        directory,
      ];
    }

    if (loggedInAccount?.isCorporateOfficer) {
      return [
        {
          ...dashboard,
          href: `/entities/${loggedInAccount?.company?.slug}/research`,
          currentRoute: router.asPath.startsWith(
            `/entities/${loggedInAccount?.company?.slug}/research`
          ),
          active: router.asPath.startsWith(
            `/entities/${loggedInAccount?.company?.slug}/research`
          ),
        },
        contacts,
        targeting,
        peersMonitoring,
        peersBenchmarks,
        chatbot,
      ];
    }

    if (loggedInAccount?.isIndividualPass) {
      return base;
    }

    if (loggedInAccount?.isInsightProvider) {
      return [...base, directory];
    }

    if (loggedInAccount?.isClient) {
      if (["trial", "investor"].includes(loggedInAccount?.clientType)) {
        return base;
      }

      return [...base, directory];
    }

    return base;
  }, [
    dashboard,
    discussions,
    watchlists,
    collections,
    tools,
    loggedInAccount?.isPreviewPass,
    loggedInAccount?.isComplianceOfficer,
    loggedInAccount?.isCorporateOfficer,
    loggedInAccount?.isIndividualPass,
    loggedInAccount?.isInsightProvider,
    loggedInAccount?.isClient,
    loggedInAccount?.company?.slug,
    loggedInAccount?.clientType,
    router.asPath,
    contacts,
    targeting,
    peersMonitoring,
    peersBenchmarks,
    chatbot,
  ]);

  const visibleNavsNumber = 5;

  const visibleTabs = useMemo(() => {
    const allNavLenght = allNavTabs.length;
    let tabs: any[] = allNavTabs;

    if (allNavLenght > visibleNavsNumber) {
      tabs = allNavTabs.slice(0, visibleNavsNumber);
      tabs.push({
        name: "More",
        subMenus: allNavTabs.slice(visibleNavsNumber),
        href: "",
      });
    }

    return tabs;
  }, [allNavTabs, visibleNavsNumber]);

  useEffect(() => {}, [width, allNavTabs]);

  const [stickyTop, setStickyTop] = useState(false);
  const [top, setTop] = useState(0);
  useEffect(() => {
    if (!mobile) return;

    const upperNavBar = 58;

    const updateTabsPosition = () => {
      const top =
        document.body.getBoundingClientRect()?.top || Number.MAX_SAFE_INTEGER;
      setTimeout(() => {
        setStickyTop(top < -upperNavBar - 42);
        setTop(
          top < -upperNavBar - 42 - 58 ? -upperNavBar : top + upperNavBar + 42
        );
      }, 100);
    };

    updateTabsPosition();

    window.addEventListener("scroll", updateTabsPosition, { passive: true });

    return () => {
      window.removeEventListener("scroll", updateTabsPosition);
    };
  }, [mobile]);

  const [showMenu, setShowMenu] = useState(true);

  const theme = useTheme();
  const [logoSrc, setLogoSrc] = useState(
    theme ? `/assets/images/logo-${theme}.png` : "/assets/images/logo.png"
  );

  const onNavClick = useCallback(
    (item: { name: string }) => {
      trackEvent(`Navigation - ${item.name}`, {
        event_category: "Navigation",
        event_action: item.name,
      });
    },
    [trackEvent]
  );

  const onDashboardClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onNavClick({ name: "Home" });

      if (authenticated) {
        if (loggedInAccount?.isComplianceOfficer) {
          window.location.href = responsiveHomeUrl;
          return;
        }

        router.push(responsiveHomeUrl);
        return;
      }

      router.push(responsiveHomeUrl);
    },
    [
      authenticated,
      loggedInAccount?.isComplianceOfficer,
      onNavClick,
      responsiveHomeUrl,
      router,
    ]
  );

  const mobileNavMenus = useMemo(() => {
    if (loggedInAccount?.isCorporateOfficer) {
      return (
        <>
          <li
            className={twMerge(
              style.item,
              router.asPath.startsWith(
                `/entities/${loggedInAccount?.company?.slug}`
              )
                ? "bg-[rgba(0,0,0,.133333)]"
                : ""
            )}
          >
            <Link
              href={`${emberAppBaseUrl}/entities/${loggedInAccount?.company?.slug}`}
              title="Home"
            >
              <span className="flex flex-col items-center justify-center relative mb-[-2px]">
                <FontAwesomeIcon className="text-[20px]" icon={faBuilding} />
                <p className="text-[9px] mt-[4px] mb-0">Home</p>
              </span>
            </Link>
          </li>
          <li
            className={twMerge(
              style.item,
              router.asPath.startsWith("/targeting/contacts")
                ? "bg-[rgba(0,0,0,.133333)]"
                : ""
            )}
          >
            <Link
              href={`${emberAppBaseUrl}/targeting/contacts`}
              title="Contacts"
            >
              <span className="flex flex-col items-center justify-center relative mb-[-2px]">
                <FontAwesomeIcon className="text-[20px]" icon={faUsers} />
                <p className="text-[9px] mt-[4px] mb-0">Contacts</p>
              </span>
            </Link>
          </li>
          <li
            className={twMerge(
              style.item,
              router.asPath === "/targeting" ? "bg-[rgba(0,0,0,.133333)]" : ""
            )}
          >
            <Link href={`${emberAppBaseUrl}/targeting`} title="Target">
              <span className="flex flex-col items-center justify-center relative mb-[-2px]">
                <FontAwesomeIcon
                  className={twMerge("text-[20px]", "rotate-180")}
                  icon={faListAlt}
                />
                <p className="text-[9px] mt-[4px] mb-0">Target</p>
              </span>
            </Link>
          </li>
        </>
      );
    }

    return (
      <>
        <li
          className={twMerge(
            style.item,
            "flex justify-center items-center",
            router.asPath.startsWith("/dashboard")
              ? "bg-[rgba(0,0,0,.133333)]"
              : ""
          )}
        >
          <Link
            href={
              authenticated
                ? `${emberAppBaseUrl}/dashboard`
                : `${emberAppBaseUrl}/home`
            }
            title="Home"
            onClick={onDashboardClick}
          >
            <span className="flex flex-col items-center justify-center relative mb-[-2px]">
              <Image
                src={
                  [
                    "/dashboard/",
                    `/entities/${loggedInAccount?.company?.slug}`,
                  ].some((path) => router.asPath.startsWith(path))
                    ? require("./assets/home-active.svg")
                    : require("./assets/home-inactive.svg")
                }
                alt="home"
                width={20}
                height={20}
                priority
              />
              <p className="text-[9px] mt-[4px] mb-0">Dashboard</p>
            </span>
          </Link>
        </li>
        <li
          className={twMerge(
            style.item,
            router.asPath.startsWith("/discussions")
              ? "bg-[rgba(0,0,0,.133333)]"
              : ""
          )}
        >
          <Link href={`${emberAppBaseUrl}/discussions`} title="Discussions">
            <span className="flex flex-col items-center justify-center relative mb-[-2px]">
              <FontAwesomeIcon className="text-[20px]" icon={faComments} />
              <p className="text-[9px] mt-[4px] mb-0">Discussions</p>
            </span>
          </Link>
        </li>
        <li
          className={twMerge(
            style.item,
            router.asPath.startsWith("/watchlists")
              ? "bg-[rgba(0,0,0,.133333)]"
              : ""
          )}
        >
          <Link href={`${emberAppBaseUrl}/watchlists`} title="Watchlists">
            <span className="flex flex-col items-center justify-center relative mb-[-2px]">
              <FontAwesomeIcon
                className={twMerge("text-[20px]", "rotate-180")}
                icon={faListAlt}
              />
              <p className="text-[9px] mt-[4px] mb-0">Watchlists</p>
            </span>
          </Link>
        </li>
      </>
    );
  }, [
    authenticated,
    loggedInAccount?.company?.slug,
    loggedInAccount?.isCorporateOfficer,
    onDashboardClick,
    router.asPath,
  ]);

  if (mobile) {
    if (showBackButton) {
      return <TopNavBarMobile rightActionButtons={rightActionButtons} />;
    }

    return (
      <div
        className={twMerge(style.top_nav_bar, "skiptranslate")}
        style={stickyTop ? { top } : {}}
      >
        <nav className={style.top_nav_bar_container_mobile}>
          <ul className={style.nav}>
            {mobileNavMenus}
            <li
              className={twMerge(
                style.item,
                router.asPath.startsWith("/messifications")
                  ? "bg-[rgba(0,0,0,.133333)]"
                  : ""
              )}
            >
              <Link
                href={`${emberAppBaseUrl}/messifications`}
                rel="nofollow"
                title="Message Notifications"
              >
                <span className="flex flex-col items-center justify-center relative mb-[-2px]">
                  <FontAwesomeIcon className="text-[20px]" icon={faBell} />
                  {totalUnread > 0 ? (
                    <div
                      className={classNames(
                        "absolute right-[13px] top-[-3px] translate-[50%]",
                        "bg-ink-pink rounded-full min-w-[13px] h-[13px] text-[10px] text-smart-teal"
                      )}
                    ></div>
                  ) : (
                    <></>
                  )}
                  <p className="text-[9px] mt-[4px] mb-0">Notifications</p>
                </span>
              </Link>
            </li>
            <li
              className={twMerge(
                style.item,
                router.asPath.startsWith("/search")
                  ? "bg-[rgba(0,0,0,.133333)]"
                  : ""
              )}
            >
              <Link href={`${emberAppBaseUrl}/search`} title="Search">
                <span className="flex flex-col items-center justify-center relative mb-[-2px]">
                  <FontAwesomeIcon className="text-[20px]" icon={faSearch} />
                  <p className="text-[9px] mt-[4px] mb-0">Search</p>
                </span>
              </Link>
            </li>
            {authenticated ? (
              <li className={style.item}>
                <TopNavBarAuthenicatedMenuMobile />
              </li>
            ) : (
              <li
                className={twMerge(
                  style.item,
                  router.asPath.startsWith("/signin")
                    ? "bg-[rgba(0,0,0,.133333)]"
                    : ""
                )}
              >
                <Link
                  href={"/signin"}
                  rel="nofollow"
                  prefetch={false}
                  title="Sign In"
                >
                  <span className={style.icon}>
                    <FontAwesomeIcon className={style.fa} icon={faKey} />
                  </span>
                </Link>
              </li>
            )}
            {!authenticated && router.asPath.startsWith("/signin") && (
              <li className="p-[5px] pr-[5px] absolute right-[8px] top-[64px]">
                <GoogleSSO />
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <div
      className={twMerge(style.top_nav_bar, "print:hidden", "skiptranslate")}
    >
      <div className={style.top_nav_bar_container}>
        <a
          href={responsiveHomeUrl}
          onClick={(e) => {
            if (dashboard.active) e.preventDefault();
            onNavClick({ name: "Home" });
          }}
        >
          <div className={style.logo}>
            <Image
              src={logoSrc}
              onLoadingComplete={(result) => {
                if (result.naturalWidth === 0) {
                  // Broken image
                  setLogoSrc("/assets/images/logo.png");
                }
              }}
              alt="logo"
              width={35}
              height={35}
              priority
            />
          </div>
        </a>
        <div
          className={classNames(style.left_section, showMenu ? "" : "hidden")}
        >
          <nav ref={navElement} className={style.nav_links}>
            {visibleTabs.map((tab: any) => {
              if (tab.subMenus) {
                return (
                  <div
                    key={tab.name}
                    className={style.submenu_container}
                    onMouseEnter={() => {
                      setShowMore(true);
                    }}
                    onMouseLeave={() => {
                      setShowMore(false);
                    }}
                  >
                    <a
                      href=""
                      onClick={(ev) => {
                        ev.preventDefault();
                        onNavClick(tab);
                      }}
                    >
                      <span>
                        {tab.name} <FontAwesomeIcon icon={faCaretDown} />
                      </span>
                    </a>

                    <div
                      className={style.submenu}
                      style={
                        showMore ? { display: "block" } : { display: "none" }
                      }
                    >
                      <ul>
                        {tab.subMenus.map((item: any) => {
                          return (
                            <li key={item.name}>
                              {item.isNextJsRoute ? (
                                <Link
                                  href={item.href}
                                  onClick={(e) =>
                                    item.currentRoute && e.preventDefault()
                                  }
                                >
                                  {item.name}
                                </Link>
                              ) : (
                                <a href={item.href}>{item.name}</a>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              } else {
                return tab.isNextJsRoute ? (
                  <Link
                    key={tab.name}
                    href={tab.href}
                    className={tab.active ? style.active : ""}
                    onClick={(e) => tab.currentRoute && e.preventDefault()}
                  >
                    <span>{tab.name}</span>
                  </Link>
                ) : (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={tab.active ? style.active : ""}
                    onClick={(e) => {
                      tab.currentRoute && e.preventDefault();
                      onNavClick(tab);
                    }}
                  >
                    <span>{tab.name}</span>
                  </a>
                );
              }
            })}
          </nav>
        </div>
        <TopNavBarSearchInput toggleMenu={setShowMenu} />
        <div className={style.right_section}>
          {authenticated ? (
            <TopNavBarAuthenticatedMenu />
          ) : (
            <>
              <button
                aria-label="Discussion"
                className={style.btnIcon}
                onClick={() => toggleModal("sign-in")}
              >
                <FontAwesomeIcon icon={faEnvelope} fixedWidth />
              </button>
              <button
                aria-label="Notifications"
                className={style.btnIcon}
                onClick={() => toggleModal("sign-in")}
              >
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </button>
              <button
                onClick={() => toggleModal("sign-up")}
                className={`${style.btn} ${style.btn_primary}`}
              >
                SIGN UP
              </button>
              <button
                onClick={() => toggleModal("sign-in")}
                className={`${style.btn} ${style.btn_secondary}`}
              >
                SIGN IN
              </button>
            </>
          )}
        </div>
      </div>
      {!authenticated && (
        <div className="absolute right-[10px] top-[10px]">
          <GoogleSSO />
        </div>
      )}
      <div id="google_translate_element"></div>
    </div>
  );
};

export default TopNavBar;
