export const verticles: any = {
  "ipos-placements": { id: "ipos-placements", icon: "snowflake-o" },
  "equity-bottom-up": { id: "equity-bottom-up", icon: "bar-chart" },
  smartkarma: { id: "smartkarma", name: "Smartkarma", icon: "smartkarma" },
  "technical-analysis": { id: "technical-analysis", icon: "line-chart" },
  "governance-shenanigans": { id: "governance-shenanigans", icon: "gavel" },
  "fx-rates": { id: "fx-rates", icon: "usd" },
  "credit-analysis": { id: "credit-analysis", icon: "credit-card" },
  "commodity-analysis": { id: "commodity-analysis", icon: "fire" },
  macro: {
    id: "macro",
    icon: "pie-chart",
    idFromBackend: 18,
    isMacro: true,
  },
  "thematic-strategy": {
    id: "thematic-strategy",
    icon: "hashtag",
    idFromBackend: 24,
    isThematic: true,
  },
  "event-driven": { id: "event-driven", icon: "flash" },
  "cross-asset-strategy": {
    id: "cross-asset-strategy",
    icon: "globe",
    isMacro: true,
  },
  "forensic-accounting": { id: "forensic-accounting", icon: "search-plus" },
  "debt-capital-markets": { id: "debt-capital-markets", icon: "money" },
  "private-capital-markets": { id: "private-capital-markets", icon: "rocket" },
  "quantitative-analysis": {
    id: "quantitative-analysis",
    icon: "calculator",
    isThematic: true,
  },
};
