import React from "react";

import Bugsnag from "@bugsnag/js";

import ErrorMessage from "components/ErrorMessage";

type ErrorBoundaryProps = {
  errorComponent?: React.ReactNode;
  onError?: (_error: Error) => void;
  autoRerender?: boolean;
};

type ErrorBoundaryState = {
  hasError?: boolean;
  temporaryHide?: boolean;
};

export default class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {};

  componentDidCatch(e: any) {
    console.debug(e);

    if (this.props.autoRerender) {
      this.setState({ temporaryHide: true });
      setTimeout(() => {
        this.setState({ temporaryHide: false });
      }, 0);
    } else {
      this.setState({ hasError: true });
    }

    Bugsnag.notify(e, (event) => {
      event.severity = "error";
      event.unhandled = true;
      return true;
    });

    if (this.props.onError) {
      this.props.onError(e);
    }
  }

  render() {
    if (this.state.temporaryHide) return <></>;

    if (this.state.hasError) {
      return this.props.errorComponent || <ErrorMessage />;
    }

    return this.props.children;
  }
}
