import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import chunk from "lodash/chunk";
import { useDebounce } from "usehooks-ts";

import { fetchApi } from "utils/baseFetcher";

import { useEffectOnce } from "./useEffectOnce";

type PriceQuote = {
  country?: string;
  currency?: string;
  fundamentalDataCurrency: string;
  name?: string;
  percentage?: number;
  price?: number;
  region?: string;
  resourceId?: string;
  resourceSubtype?: string;
  resourceType?: string;
  securityType?: string;
  source?: string;
  symbol?: string;
  template?: string;
  ticker?: string;
  tickerName?: string;
  tinyName?: string;
  volume?: number;
  watchlist?: boolean;

  /* --- */
  isNotAvailable?: boolean;
  isLoading?: true;
};

type PriceQuotes = {
  [key: string]: PriceQuote;
};

type PriceQuotesContextValue = {
  priceQuotes: PriceQuotes;
  setPriceQuotes: (
    _priceQuotes: PriceQuotes | ((_: PriceQuotes) => PriceQuotes)
  ) => void;
  fetchingPriceQuotes?: MutableRefObject<Set<string>>;
};

type TickersContextValue = {
  tickers: string[];
  setTickers: (_tickers: string[]) => void;
  addTicker: (_ticker: string) => void;
};

export const PriceQuotesContext = createContext<PriceQuotesContextValue>({
  priceQuotes: {},
  setPriceQuotes: (_priceQuotes) => {},
});

export const TickersContext = createContext<TickersContextValue>({
  tickers: [],
  setTickers: (_tickers) => {},
  addTicker: (_ticker) => {},
});

export const PriceQuotesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const fetchingPriceQuotes = useRef<Set<string>>(new Set<string>());
  const [priceQuotes, setPriceQuotes] = useState<PriceQuotes>({});
  const [tickers, setTickers] = useState<string[]>([]);

  const debouncedTickers = useDebounce<string[]>(tickers, 200);

  useEffect(() => {
    const toBeFetch = debouncedTickers?.filter(
      (s) => !fetchingPriceQuotes.current.has(s)
    );

    if (!toBeFetch.length) return;

    toBeFetch.forEach((ticker) => fetchingPriceQuotes.current.add(ticker));

    setPriceQuotes((prev) => ({
      ...prev,
      ...toBeFetch.reduce(
        (acc, ticker) => ({ ...acc, [ticker]: { isLoading: true } }),
        {}
      ),
    }));

    const chunks = chunk(toBeFetch, 20);

    let allPriceQuotes: any = {};

    (async () => {
      for (const chunk of chunks) {
        try {
          if (!chunk.length || !chunk.join(",")) continue;

          let newPriceQuotes = await fetchApi(
            `/api/v2/price-api/get-compact?ticker=${chunk.join(",")}`
          );

          if (chunk.length === 1) {
            newPriceQuotes = { [chunk[0]]: newPriceQuotes };
          }

          allPriceQuotes = { ...allPriceQuotes, ...newPriceQuotes };

          for (const security of chunk) {
            if (!allPriceQuotes[security] && !priceQuotes[security]) {
              allPriceQuotes[security] = { isNotAvailable: true };
            }
          }

          setPriceQuotes((prev) => ({ ...prev, ...allPriceQuotes }));
        } catch (err) {
          console.debug(err);
          chunk.forEach((ticker) => {
            fetchingPriceQuotes.current.delete(ticker);
          });
        }
      }
    })();
  }, [debouncedTickers, priceQuotes]);

  const addTicker = useCallback((ticker: string) => {
    setTickers((prev) => {
      if (!prev.includes(ticker)) {
        return [...prev, ticker];
      }
      return prev;
    });
  }, []);

  return (
    <TickersContext.Provider value={{ tickers, setTickers, addTicker }}>
      <PriceQuotesContext.Provider
        value={{ priceQuotes, setPriceQuotes, fetchingPriceQuotes }}
      >
        {children}
      </PriceQuotesContext.Provider>
    </TickersContext.Provider>
  );
};

export const usePriceQuotes = () => {
  const { priceQuotes, setPriceQuotes, fetchingPriceQuotes } =
    useContext(PriceQuotesContext);

  const refreshPriceQuotes = useCallback(
    (tickers: string[] = []) => {
      setPriceQuotes((currentPriceQuotes: any) => {
        const newPriceQuotes = { ...currentPriceQuotes };
        tickers.forEach((ticker) => {
          delete newPriceQuotes[ticker];
          if (fetchingPriceQuotes) {
            fetchingPriceQuotes.current.delete(ticker);
          }
        });

        return newPriceQuotes;
      });
    },
    [fetchingPriceQuotes, setPriceQuotes]
  );

  return { priceQuotes, setPriceQuotes, refreshPriceQuotes };
};

export const usePriceQuote = (ticker: string) => {
  const { priceQuotes } = useContext(PriceQuotesContext);
  const { addTicker } = useContext(TickersContext);

  useEffectOnce((acknowledge) => {
    if (ticker) {
      addTicker(ticker);
      acknowledge();
    }
  });

  const priceQuote =
    useMemo(() => {
      return priceQuotes[ticker];
    }, [priceQuotes, ticker]) || {};

  return priceQuote;
};
