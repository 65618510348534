import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import React, { useCallback, useRef, useState } from "react";

import classNames from "classnames";
import { useDebounce, useEventListener } from "usehooks-ts";

import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

import FontAwesomeIcon from "components/FontAwesomeIcon";
import useAuthentication from "hooks/useAuthentication";
import { useGlobalState } from "hooks/useGlobalState";

const TopNavBarSearchHistories = dynamic(
  () => import("../TopNavBarSearchHistories"),
  { ssr: false }
);
const TopNavBarSearchResults = dynamic(
  () => import("../TopNavBarSearchResults"),
  { ssr: false }
);
const TopNavBarTrendingInsights = dynamic(
  () => import("../TopNavBarTrendingInsights"),
  { ssr: false }
);

type TopNavBarSearchInputProps = {
  toggleMenu: (_show: boolean) => void;
};

const TopNavBarSearchInput = ({ toggleMenu }: TopNavBarSearchInputProps) => {
  const { authenticated } = useAuthentication();
  const router = useRouter();

  const searchElement = useRef<HTMLInputElement>(null);

  const [isFocusing, setIsFocusing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);

  const onFocus = useCallback(() => {
    toggleMenu(false);
    setIsFocusing(true);
    document.body.style.overflowY = "hidden";
  }, [toggleMenu]);

  const onBlur = useCallback(() => {
    setQuery("");
    toggleMenu(true);
    setIsFocusing(false);
    setLoading(false);
    document.body.style.overflowY = "";
  }, [toggleMenu]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && query?.length > 1) {
        router.push(`/search?q=${encodeURIComponent(query)}`);
        (e.currentTarget as HTMLElement)?.blur?.();
        onBlur();
      }
    },
    [onBlur, query, router]
  );

  const [showInsightModal] = useGlobalState("insightModal");
  const [showingModal] = useGlobalState("showingModal", false);
  useEventListener("keydown", (event: KeyboardEvent) => {
    if (
      (event.metaKey || event.ctrlKey) &&
      event.key === "f" &&
      !showInsightModal &&
      !showingModal &&
      !location.pathname.startsWith("/insights/")
    ) {
      event.preventDefault();
      searchElement.current?.focus();
    } else if (isFocusing && event.key === "Escape") {
      event.preventDefault();
      searchElement.current?.blur();
      onBlur();
    }
  });

  return (
    <div
      className={classNames(
        "relative",
        isFocusing ? "flex-1" : "w-[75px] lg:w-[250px]"
      )}
    >
      <div className={classNames("relative m-[12.5px]", "flex items-center")}>
        <FontAwesomeIcon
          className={classNames(
            "absolute mx-[11.5px]",
            "text-[15px",
            isFocusing ? "" : "text-white"
          )}
          icon={faSearch}
        />
        <input
          className={classNames(
            "w-full h-[33px] indent-[38px]",
            "outline-none border-none",
            "placeholder-transparent lg:placeholder-white",
            isFocusing
              ? "bg-white rounded-t"
              : "bg-white/25 rounded-full text-white"
          )}
          ref={searchElement}
          placeholder="Search"
          value={query}
          onFocus={onFocus}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
        <FontAwesomeIcon
          className={classNames(
            "animate-spin absolute right-[12.5px]",
            loading ? "" : "hidden"
          )}
          icon={faSpinner}
        />
      </div>
      {isFocusing && (
        <>
          <div className="fixed top-[60px] bottom-0 left-0 right-0 bg-black/70"></div>
          <div
            className="fixed top-0 bottom-0 left-0 right-0"
            onClick={onBlur}
          ></div>
          <div
            className={classNames(
              "absolute left-[12.5px] right-[12.5px] top-[45.5px] p-[4px]",
              "bg-white shadow rounded-b"
            )}
          >
            {debouncedQuery ? (
              <TopNavBarSearchResults
                query={debouncedQuery}
                onItemClick={onBlur}
                onLoading={setLoading}
              />
            ) : (
              <div>
                <div className="bg-[#eee] px-[7px] py-[3px]">
                  Recent Search History
                </div>
                <div>
                  {authenticated ? (
                    <TopNavBarSearchHistories
                      onItemClick={onBlur}
                      onLoading={setLoading}
                    />
                  ) : (
                    <TopNavBarTrendingInsights
                      onItemClick={onBlur}
                      onLoading={setLoading}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TopNavBarSearchInput;
