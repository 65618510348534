import { useRouter } from "next/router";

import { GoogleLogin } from "@react-oauth/google";

import ErrorBoundary from "components/ErrorBoundary";
import { useGlobalState } from "hooks/useGlobalState";
import useMediaQuery from "hooks/useMediaQuery";
import { fetchApi } from "utils/baseFetcher";
import { setSessionCookies } from "utils/setSessionCookies";

const GoogleSSO = () => {
  const router = useRouter();
  const [signupSource] = useGlobalState("signupSource");
  const { mobile } = useMediaQuery();

  return (
    <ErrorBoundary errorComponent={() => <></>}>
      <GoogleLogin
        onSuccess={(credentialResponse: any) => {
          console.log("credentialResponse", credentialResponse);
          fetchApi("/api/v2/accounts/google-oauth-authentication", {
            method: "POST",
            body: JSON.stringify({
              token: credentialResponse.credential,
              "source-type": signupSource?.type || router.asPath,
              "source-id": signupSource?.id,
            }),
          }).then(async (data) => {
            console.log(data);
            if (data?.token) {
              const account = await setSessionCookies(data, {
                registerDevice: true,
              });

              try {
                if (account?.interfaceSetting?.requiresOnboarding) {
                  localStorage.setItem("firstLogin", "true");
                  sessionStorage.setItem(
                    "storage:signup",
                    JSON.stringify({
                      preSignupPath: `${window.location.pathname}`,
                    })
                  );
                } else if (localStorage.getItem("firstLogin")) {
                  localStorage.removeItem("firstLogin");
                }
              } catch {}

              if (
                router.query["magic-token"] ||
                router.query["temporary-token"]
              ) {
                const query = { ...router.query };
                delete query["magic-token"];
                delete query["temporary-token"];
                await router.replace({
                  pathname: router.pathname,
                  query,
                });
              }
              if (["/signin", "/login"].includes(window.location.pathname)) {
                if (
                  !document.referrer ||
                  new URL(document.referrer).pathname.startsWith("/home") ||
                  new URL(document.referrer).pathname.startsWith("/signin") ||
                  new URL(document.referrer).pathname.startsWith("/login")
                ) {
                  if (account?.isInternalUser) {
                    router.push("/dashboard");
                    window.location.href = "/dashboard";
                  } else {
                    window.location.href = "/dashboard";
                  }
                } else {
                  window.history.back();
                }
              } else {
                if (mobile) {
                  window.location.reload();
                } else {
                  router.reload();
                }
              }
            }
          });
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        useOneTap
        type="icon"
        shape="pill"
        ux_mode="popup"
        cancel_on_tap_outside={false}
      />
    </ErrorBoundary>
  );
};

export default GoogleSSO;
