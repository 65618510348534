import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import kebabCase from "lodash/kebabCase";
import transform from "lodash/transform";

const kebabCaseObject = (obj: any) => {
  return transform(obj, (acc: any, value, key: string, target) => {
    const kebabKey = isArray(target) ? key : kebabCase(key);

    acc[kebabKey] = isObject(value) ? kebabCaseObject(value) : value;
  });
};

export default kebabCaseObject;
