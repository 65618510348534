type ComposeComponentsProps = {
  components: [
    React.JSXElementConstructor<React.PropsWithChildren<any>>,
    any?
  ][];
  children: React.ReactNode;
};

const ComposeComponents = ({
  components = [],
  children,
}: ComposeComponentsProps) => {
  return (
    <>
      {components.reduceRight((acc, [Provider, props]) => {
        return <Provider {...props}>{acc}</Provider>;
      }, children)}
    </>
  );
};

export default ComposeComponents;
