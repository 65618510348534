import { createContext } from "react";

import type { ModalType } from ".";

export const SignInSignUpContext = createContext<{
  showModal: ModalType;
  setShowModal: (_value: ModalType) => void;
}>({
  showModal: undefined,
  setShowModal: (_value: ModalType) => {},
});
