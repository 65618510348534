import { watchlistsUrl } from "hooks/useWatchlists";

const whitelistKeys = [
  "/api/v2/content-verticals?sort=name",
  "/api/v2/countries?sort=name",
  "/api/v2/geographies?include=countries",
  "/api/v2/sectors?include=industry-groups&sort=name",
  "/api/v2/tools/",
  watchlistsUrl,
];

export default function localStorageProvider() {
  try {
    if (typeof localStorage === "undefined" || !localStorage) return new Map();

    // When initializing, we restore the data from `localStorage` into a map.
    const appCache = JSON.parse(localStorage.getItem("app-cache") || "[]");
    const filteredAppCache = appCache.filter(([key]: [string, any]) =>
      whitelistKeys.some((k) => key.startsWith(k))
    );
    const map = new Map(filteredAppCache);

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener("beforeunload", () => {
      const appCache = Array.from(map.entries()) as Array<[string, any]>;
      const filteredAppCache = appCache.filter(([key]: [string, any]) =>
        whitelistKeys.some((k) => key.startsWith(k))
      );
      localStorage.setItem("app-cache", JSON.stringify(filteredAppCache));
    });

    // We still use the map for write & read for performance.
    return map;
  } catch {
    return new Map();
  }
}
