import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { useCallback, useEffect, useRef, useState } from "react";

import { twMerge } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

import FontAwesomeIcon from "components/FontAwesomeIcon";
import TopNavBarMobile from "components/TopNavBar/TopNavBarMobile";
import useMediaQuery from "hooks/useMediaQuery";

import styles from "./style.module.scss";

const Modal = dynamic(() => import("react-modal"));

type IframeModalProps = {
  url: string;
  show?: boolean;
  onClose?: () => void;
};

const IframeModal = ({ url, show, onClose }: IframeModalProps) => {
  const router = useRouter();
  const { mobile } = useMediaQuery();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [showCloseButton, setShowCloseButton] = useState(true); // improve navigation
  const [currentUrl, setUrl] = useState(url);

  const toggleModal = useCallback(() => {
    document.body.style.overflowY = "";
    if (onClose) {
      return onClose();
    }
    if (router.query.slug) {
      router.push(`/entities/${router.query.slug}/research`);
    } else {
      router.back();
    }
  }, [onClose, router]);

  const escPressed = useCallback(
    (event: KeyboardEvent) => {
      if (show && event.key === "Escape") {
        toggleModal();
      }
    },
    [show, toggleModal]
  );
  useEventListener("keydown", escPressed);

  const postNavigateMessage = useCallback(() => {
    if (iframeRef.current?.contentWindow?.postMessage) {
      iframeRef.current?.contentWindow?.postMessage({
        type: "transitionTo",
        url,
      });
      iframeRef.current?.contentWindow?.focus?.();
    }
  }, [url]);

  const messageEvent = useCallback((event: MessageEvent) => {
    if (event.data?.type === "navigateTo") {
      const destUrl = new URL(event.data.url).pathname;
      window.history.pushState(destUrl, "", destUrl);
    }
  }, []);

  const historyPopState = useCallback((event: Event) => {
    toggleModal();
    // if (iframeRef.current?.contentWindow?.postMessage) {
    //   iframeRef.current?.contentWindow?.postMessage({
    //     type: "transitionTo",
    //     url,
    //   });
    //   iframeRef.current?.contentWindow?.focus?.();
    // }
  }, []);

  useEffect(() => {
    if (url && !currentUrl) {
      setUrl(url);
    } else {
      postNavigateMessage();
    }
  }, [currentUrl, postNavigateMessage, show, url]);

  useEffect(() => {
    if (show) {
      document.body.style.overflowY = "hidden";
      postNavigateMessage();
      window.addEventListener("message", messageEvent);
      window.addEventListener("popstate", historyPopState);
    } else {
      document.body.style.overflowY = "";
      window.removeEventListener("message", messageEvent);
      window.removeEventListener("popstate", historyPopState);
    }
  }, [historyPopState, messageEvent, postNavigateMessage, show]);

  return (
    <Modal
      portalClassName={twMerge(styles.modal, show ? "" : "hidden")}
      overlayClassName={twMerge(styles.overlay, show ? "" : "hidden")}
      className={styles.content}
      isOpen
      shouldCloseOnEsc
      onRequestClose={toggleModal}
    >
      {mobile ? (
        <TopNavBarMobile onGoBack={toggleModal} />
      ) : (
        <div className={styles.actionsBar}>
          {showCloseButton && (
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.closeButton}
              onClick={toggleModal}
            />
          )}
        </div>
      )}
      <iframe
        title="url"
        ref={iframeRef}
        className={styles.iframe}
        src={currentUrl}
        onLoadStart={() => {
          console.log("onLoadStart");
        }}
        onLoad={() => {
          iframeRef.current?.contentWindow?.focus?.();
          const href = iframeRef.current?.contentWindow?.location?.href;
          console.log("onLoad", href);
          if (href && !href.includes("/embed/")) {
            setShowCloseButton(false);
            window.location.href =
              iframeRef.current?.contentWindow?.location?.href;
          }
          iframeRef.current?.contentDocument?.addEventListener?.(
            "keydown",
            escPressed
          );
        }}
      />
    </Modal>
  );
};

export default IframeModal;
