import type { AppContext, AppProps } from "next/app";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import { SWRConfig } from "swr";

import { ReactElement, ReactNode } from "react";
import SSRProvider from "react-bootstrap/SSRProvider";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "styles/bootstrap.scss";
import "styles/globals.scss";
import Bugsnag from "@bugsnag/js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import I18nContainer from "i18n/I18nContainer";
import type { NextPage } from "next";
import { twMerge } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import AccountMFA from "components/AccountMFA";
import AccountSessionLock from "components/AccountSessionLock";
import ActionCableContainer from "components/ActionCableContainer";
import ComposeComponents from "components/ComposeComponents";
import ErrorBoundary from "components/ErrorBoundary";
import Layout from "components/Layout";
import { NotificationContextProvider } from "components/Notification/NotificationContextProvider";
import PlatformUpdate from "components/PlatformUpdate";
import { SignInSignUpContextProvider } from "components/SignInSignUpModal/SignInSignUpContextProvider";
import { TopNavBarContextProvider } from "components/TopNavBar/TopNavBarContextProvider";
import TranslationElement from "components/TranslationElement";
import { AuthenticationContextProvider } from "hooks/useAuthentication";
import { GlobalDataContextProvider } from "hooks/useGlobalData";
import { GlobalStateContextProvider } from "hooks/useGlobalState";
import { IframeModalContextProvider } from "hooks/useIframeModal";
import { UserAgentContext } from "hooks/useMediaQuery";
import { PriceQuotesContextProvider } from "hooks/usePriceQuote";
import { StripeContextProvider } from "hooks/useStripe";
import { ThemeContextProvider } from "hooks/useTheme";
import { useWatchlists } from "hooks/useWatchlists";
import { Provider as ActionCableProvider } from "contexts/actionCableContext";
import { start } from "utils/bugsnag";
import { GOOGLE_SSO_CLIENT_ID } from "utils/envVariables";
import { ptSerif, roboto } from "utils/fonts";
import { Gtag } from "utils/gtag";
import localStorageProvider from "utils/localStorageProvider";

import type { NextWebVitalsMetrics } from "../../types/NextWebVitalsMetrics";

start();

const { publicRuntimeConfig } = getConfig();
console.log(
  `%cSmartkarma Platform v${publicRuntimeConfig?.version}`,
  "background-color: #77bd22; color: white; padding-top: 5px; padding-bottom: 5px; padding-left: 10px; padding-right: 10px;"
);

// Fix google translation
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // @ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot remove a child from a different parent",
          child,
          this
        );
      }
      return child;
    }
    // @ts-ignore
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;

  // @ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }
      return newNode;
    }
    // @ts-ignore
    return originalInsertBefore.apply(this, arguments);
  };
}

const Page404 = dynamic(() => import("./404"));
const Page500 = dynamic(() => import("./500"));

export type NextPageWithLayout = NextPage & {
  noLayout?: boolean;
  getLayout?: (_page: ReactElement, _options: any) => ReactNode;
};

type MyAppProps = AppProps & {
  Component: NextPageWithLayout;
  userAgent: string;
  cookie: string;
  url: string;
};

function MyApp({
  Component,
  pageProps,
  userAgent,
  cookie,
  url,
  router,
}: MyAppProps) {
  // preload watchlist
  useWatchlists();

  useEventListener("message", (event: MessageEvent) => {
    if (event.data?.type === "transitionTo" && event.data?.url) {
      console.debug(event.data);
      router.push(event.data?.url);
    }
  });

  if (pageProps?.error) {
    if (pageProps.error.statusCode == 404) return <Page404 />;

    return <Page500 />;
  }

  const getLayout = Component.getLayout ?? ((page) => page);

  const page = Component.noLayout ? (
    getLayout(<Component {...pageProps} />, { pageProps })
  ) : (
    <Layout>{getLayout(<Component {...pageProps} />, { pageProps })}</Layout>
  );

  return (
    <>
      <style jsx global>
        {`
          html,
          body {
            font-family: ${roboto.style.fontFamily};
          }
        `}
      </style>{" "}
      <div
        className={twMerge(
          roboto.variable,
          ptSerif.variable,
          "font-roboto",
          "min-h-[calc(100vh-60px)]",
          router.query?.whiteBg === "true" ||
            router.pathname.endsWith("/notifications")
            ? "bg-white"
            : "bg-white md:bg-[#eee]"
        )}
      >
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* <script
            crossOrigin="anonymous"
            src="//unpkg.com/react-scan/dist/auto.global.js"
          /> */}
        </Head>
        <ComposeComponents
          components={[
            [ErrorBoundary],
            [UserAgentContext.Provider, { value: { userAgent } }],
            [
              SWRConfig,
              {
                value: {
                  fallback: pageProps?.fallback || {},
                  provider: localStorageProvider,
                },
              },
            ],
            [
              GoogleOAuthProvider,
              {
                clientId: GOOGLE_SSO_CLIENT_ID,
              },
            ],
            [GlobalStateContextProvider],
            [GlobalDataContextProvider],
            [
              AuthenticationContextProvider,
              {
                cookie:
                  cookie ||
                  (typeof document !== "undefined" ? document.cookie : null),
              },
            ],
            [TopNavBarContextProvider],
            [NotificationContextProvider],
            [ThemeContextProvider, { cookie, url }],
            [StripeContextProvider],
            [SignInSignUpContextProvider],
            [PriceQuotesContextProvider],
          ]}
        >
          <I18nContainer />
          <TranslationElement />
          <Gtag />
          <ComposeComponents
            components={[
              [
                ErrorBoundary,
                {
                  errorComponent: (
                    <Page500
                      header="Oops!"
                      message="Something went wrong. Please try again."
                    />
                  ),
                },
              ],
              [ActionCableProvider],
              [SSRProvider],
              [IframeModalContextProvider],
            ]}
          >
            <ActionCableContainer />
            <AccountMFA />
            <PlatformUpdate />
            <AccountSessionLock>{page}</AccountSessionLock>
          </ComposeComponents>
        </ComposeComponents>
      </div>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  return {
    userAgent: appContext.ctx.req?.headers["user-agent"],
    cookie: appContext.ctx.req?.headers.cookie,
    url: appContext.ctx.req?.url,
  };
};

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetrics) {
  try {
    window?.gtag?.("event", name, {
      event_category:
        label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  } catch (error: any) {
    Bugsnag.notify(error);
  }
}

export default MyApp;
