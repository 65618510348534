import { verticals } from "consts/verticals";

export const transform = (raw: any): any => {
  return {
    ...raw,

    get securityTypeText() {
      const type = this.securityType;
      if (type) {
        return type.split(",").join(", ");
      }
    },

    get matchedVertical() {
      return verticals.find((v) => v.id === this.slug);
    },

    get isMacro() {
      return this.matchedVertical?.isMacro;
    },

    get isThematic() {
      return this.matchedVertical?.isThematic;
    },

    get isTopDown() {
      return this.isMacro || this.isThematic;
    },
  };
};
