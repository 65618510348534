import { useRouter } from "next/router";

import { useEffect, useRef, useState } from "react";
import type { ReactNode } from "react";

import useAuthentication from "hooks/useAuthentication";

import { TopNavBarContext } from "./TopNavBarContext";

const detailsRoutes = ["/insights/[slug]"];

export function TopNavBarContextProvider({ children }: any) {
  const router = useRouter();
  const initalShowBackButton = detailsRoutes.includes(router.route);

  const { account } = useAuthentication();
  const accountRef = useRef(account);

  const [showBackButton, setShowBackButton] = useState(initalShowBackButton);
  const [rightActionButtons, setRightActionButtons] =
    useState<ReactNode | null>(null);

  const [notificationsReadAt, setNotificationsReadAt] = useState(
    account?.notificationsReadAt || new Date(0)
  );
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(
    account?.totalUnreadNotifications || 0
  );
  const [totalUnreadMessageGroups, setTotalUnreadMessageGroups] = useState(
    account?.totalUnreadMessageGroups || 0
  );

  useEffect(() => {
    if (account && account !== accountRef.current) {
      accountRef.current = account;

      if (account.notificationsReadAt !== notificationsReadAt) {
        setNotificationsReadAt(account.notificationsReadAt || new Date(0));
      }
      if (account.totalUnreadNotifications !== totalUnreadNotifications) {
        setTotalUnreadNotifications(account.totalUnreadNotifications || 0);
      }
      if (account.totalUnreadMessageGroups !== totalUnreadMessageGroups) {
        setTotalUnreadMessageGroups(account.totalUnreadMessageGroups || 0);
      }
    }
  }, [
    account,
    notificationsReadAt,
    totalUnreadMessageGroups,
    totalUnreadNotifications,
  ]);

  const context = {
    showBackButton,
    setShowBackButton,
    rightActionButtons,
    setRightActionButtons: (elements: ReactNode) =>
      setRightActionButtons(elements),
    notificationsReadAt,
    setNotificationsReadAt,
    totalUnreadNotifications,
    setTotalUnreadNotifications,
    totalUnreadMessageGroups,
    setTotalUnreadMessageGroups,
  };

  return (
    <TopNavBarContext.Provider value={context}>
      {children}
    </TopNavBarContext.Provider>
  );
}
