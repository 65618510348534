import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Script from "next/script";

import { useEffect } from "react";

import useAuthentication from "hooks/useAuthentication";

const GoogleTranslationToogle = dynamic(
  () => import("components/GoogleTranslationToogle"),
  {
    ssr: false,
  }
);

const TranslationElement = () => {
  const router = useRouter();
  const { authenticated } = useAuthentication();

  useEffect(() => {
    (window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout: (window as any).google.translate.TranslateElement.InlineLayout
            .SIMPLE,
          // includedLanguages: "zh-CN,en,ja,ko",
        },
        "google_translate_element"
      );
    };
  }, []);

  if (authenticated) {
    return (
      <>
        {!router.asPath?.startsWith?.("/onboarding") && (
          <Script
            type="text/javascript"
            src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
          />
        )}
        <GoogleTranslationToogle type="dummy" />
      </>
    );
  }

  return null;
};

export default TranslationElement;
