import { useState } from "react";

import { twMerge } from "tailwind-merge";
import { useInterval, useIsClient } from "usehooks-ts";

import intervals from "consts/intervals";

type CountdownProps = {
  className?: string;
  digitClassName?: string;
  minutesOnly?: boolean;
  endTime: Date;
  onEnded?: () => void;
};

const Countdown = ({
  className,
  digitClassName,
  endTime,
  minutesOnly,
  onEnded,
}: CountdownProps) => {
  const isClient = useIsClient();

  const t = endTime?.getTime?.()
    ? (endTime?.getTime?.() || 0) - new Date().getTime()
    : 0;

  const [counters, setCounters] = useState({
    days: Math.floor(t / intervals.oneDay),
    hours: Math.floor((t % intervals.oneDay) / intervals.oneHour),
    minutes: Math.floor((t % intervals.oneHour) / intervals.oneMinute),
    seconds: Math.floor((t % intervals.oneMinute) / intervals.oneSecond),
  });

  const { days, hours, minutes, seconds } = counters;

  useInterval(() => {
    const days = Math.max(0, Math.floor(t / intervals.oneDay));
    const hours = Math.max(
      0,
      Math.floor((t % intervals.oneDay) / intervals.oneHour)
    );
    const minutes = Math.max(
      0,
      Math.floor((t % intervals.oneHour) / intervals.oneMinute)
    );
    const seconds = Math.max(
      0,
      Math.floor((t % intervals.oneMinute) / intervals.oneSecond)
    );
    setCounters({
      days,
      hours,
      minutes,
      seconds,
    });
    if (days + hours + minutes + seconds === 0) onEnded?.();
  }, intervals.oneSecond);

  if (!isClient || !endTime?.getTime?.() || t <= 0) return <></>;

  return (
    <div className={className}>
      {!minutesOnly && (
        <div>
          <span
            className={twMerge(
              "text-[15px] font-bold leading-[calc(22/15)]",
              digitClassName
            )}
          >
            {days}
          </span>
          d
        </div>
      )}
      {!minutesOnly && (
        <div>
          <span
            className={twMerge(
              "text-[15px] font-bold leading-[calc(22/15)]",
              digitClassName
            )}
          >
            {hours}
          </span>
          h
        </div>
      )}
      <div>
        <span
          className={twMerge(
            "text-[15px] font-bold leading-[calc(22/15)]",
            digitClassName
          )}
        >
          {minutes}
        </span>
        m
      </div>
      <div>
        <span
          className={twMerge(
            "text-[15px] font-bold leading-[calc(22/15)]",
            digitClassName
          )}
        >
          {seconds}
        </span>
        s
      </div>
    </div>
  );
};

export default Countdown;
