import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import type { Dispatch } from "react";

type State = {
  data: any;
  dispatch: Dispatch<{
    [key: string]: any;
    type: string;
  }>;
};

type GlobalDataContextValue = {
  comments: State;
};

const DEFAULT_STATE = {
  data: {},
  dispatch: () => {},
};

export const GlobalDataContext = createContext<GlobalDataContextValue>({
  comments: DEFAULT_STATE,
});

const reducer = (state: any, action: { type: string; [key: string]: any }) => {
  switch (action.type) {
    case "merge": {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data,
        },
      };
    }
    case "replace": {
      return {
        ...state,
        [action.key]: action.data,
      };
    }
    default:
      return state;
  }
};

export const GlobalDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [comments, commentsDispatch] = useReducer(reducer, {});

  return (
    <GlobalDataContext.Provider
      value={{ comments: { data: comments, dispatch: commentsDispatch } }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = (
  type: "comments",
  key: string | number,
  options: {
    initialData: any;
  }
) => {
  const { initialData } = options;

  const globalDataContext = useContext(GlobalDataContext);
  const state = globalDataContext[type];
  const data = state.data[`${key}`];

  const merge = useCallback(
    (data) => {
      state.dispatch({ type: "merge", key, data });
    },
    [key, state]
  );

  const replace = useCallback(
    (data) => {
      state.dispatch({ type: "replace", key, data });
    },
    [key, state]
  );

  useEffect(() => {
    if (initialData) {
      if (data) {
        if (JSON.stringify(initialData) || JSON.stringify(data)) {
          merge(initialData);
        }
      } else {
        replace(initialData);
      }
    }
    // only update if initial data changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  return {
    data: data || initialData,
    merge,
    replace,
  };
};
