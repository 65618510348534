import dynamic from "next/dynamic";

import { useState } from "react";

import type { ModalType } from ".";

import { SignInSignUpContext } from "./SignInSignUpContext";

const SignInSignUpModal = dynamic(() => import("."));

export function SignInSignUpContextProvider({ children }: any) {
  const [showModal, setShowModal] = useState<ModalType>();

  const context = {
    showModal,
    setShowModal,
  };

  return (
    <SignInSignUpContext.Provider value={context}>
      <SignInSignUpModal
        type={showModal}
        isOpen={!!showModal}
        onClose={() => setShowModal(undefined)}
        onSwitch={() =>
          setShowModal(showModal === "sign-in" ? "sign-up" : "sign-in")
        }
      />
      {children}
    </SignInSignUpContext.Provider>
  );
}
