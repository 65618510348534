import Link from "next/link";
import { useRouter } from "next/router";

import { twMerge } from "tailwind-merge";

import GoogleSSO from "components/GoogleSSO";
import useAuthentication from "hooks/useAuthentication";

import style from "./style.module.scss";

type TopNavBarMobileProps = {
  onGoBack?: () => void;
  rightActionButtons?: React.ReactNode;
  customBackButtonLabel?: string;
};

const TopNavBarMobile = ({
  onGoBack,
  rightActionButtons,
  customBackButtonLabel,
}: TopNavBarMobileProps) => {
  const router = useRouter();

  const { authenticated, account } = useAuthentication();

  return (
    <div className={twMerge(style.top_nav_bar, "skiptranslate")}>
      <div className={style.top_nav_bar_container_mobile}>
        <div className={style.left_section}>
          <div className={style.nav_links_mobile}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();

                if (window.history.length < 2) {
                  if (
                    account?.isComplianceOfficer ||
                    account?.isCorporateOfficer
                  ) {
                    location.href = "/dashboard";
                    return;
                  }

                  router.push("/dashboard");
                  return;
                }

                onGoBack ? onGoBack() : router.back();
              }}
              scroll={false}
              prefetch={false}
            >
              <svg className={style.backIcon}>
                <line x1="1" y1="7.5" x2="15" y2="7.5" />
                <polyline points="7.6,1 1,7.5 7.6,14 " />
              </svg>
              {customBackButtonLabel || "Back"}
            </Link>
          </div>
        </div>
        <div className={style.right_section}>{rightActionButtons}</div>
        {!authenticated && (
          <div className="p-[5px]">
            <GoogleSSO />
          </div>
        )}
        <div id="google_translate_element"></div>
      </div>
    </div>
  );
};

export default TopNavBarMobile;
