import getConfig from "next/config";

import Bugsnag from "@bugsnag/js";

const { publicRuntimeConfig } = getConfig();

const ignorePatterns = [/Minified React error #4.+/];

export function start() {
  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE
  if (
    process.env.NEXT_PHASE !== "phase-production-build" &&
    process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  ) {
    const config = {
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      appVersion: `${
        publicRuntimeConfig?.appVersion || process.env.APP_VERSION
      }-${process.env.NEXT_BUILD_ID}`,
      releaseStage: process.env.APP_ENV,
    };
    Bugsnag.start({
      ...config,
      onError: (event) => {
        if (
          ignorePatterns.some((re) => re.test(event.errors[0].errorMessage))
        ) {
          return false;
        }
      },
    });

    if (typeof document !== "undefined") {
      try {
        import("@bugsnag/browser-performance").then((BugsnagPerformance) =>
          BugsnagPerformance.default.start(config)
        );
      } catch (err) {
        console.debug(err);
      }
    }
  }
}
