import { useRouter } from "next/router";
import useSWR from "swr";

import React, { useEffect, useState } from "react";

import Bugsnag from "@bugsnag/js";
import isEmpty from "lodash/isEmpty";
import { useToggle } from "usehooks-ts";

import AccountSessionLockModal from "components/AccountSessionLockModal";
import useAuthentication from "hooks/useAuthentication";
import { useEffectOnce } from "hooks/useEffectOnce";
import { baseFetcher, fetchApi } from "utils/baseFetcher";
import getBrowserFingerprint from "utils/getBrowserFingerprint";

export const FINGERPRINT_OPTIONS = {
  hardwareOnly: true,
  enableWebgl: true,
  enablePermissions: true,
  enableAudio: true,
};

export const registerDevice = (fingerprint: number) => {
  try {
    return fetchApi(`/api/v3/account-devices`, {
      method: "POST",
      body: JSON.stringify({
        fingerprint_id: fingerprint,
      }),
    });
  } catch (err: any) {
    Bugsnag.notify(err);
    return Promise.resolve(null);
  }
};

export const updateDeviceStatus = async () => {
  try {
    const { data, fingerprint } = await getBrowserFingerprint(
      FINGERPRINT_OPTIONS
    );
    const res = await fetchApi(`/api/v3/account-devices/${fingerprint}`, {
      method: "PATCH",
      body: JSON.stringify({
        status: "active",
      }),
    });

    if (!res?.status) {
      await registerDevice(fingerprint);
      try {
        Bugsnag.notify(new Error("New Device Registered"), function (event) {
          event.severity = "info";
          event.addMetadata("DeviceAttribute", JSON.parse(data || "{}"));
        });
      } catch {}
    }
  } catch (err: any) {
    Bugsnag.notify(err);
    return Promise.resolve(null);
  }
};

export const currentAccountDeviceUrl = (fingerprint: number) => {
  if (!fingerprint) return null;

  return `/api/v3/account-devices/${fingerprint}`;
};

const AccountSessionLock = ({ children }: { children: React.ReactNode }) => {
  const { authenticated, account } = useAuthentication();

  const router = useRouter();

  const [showModal, toggleModal, setShowModal] = useToggle();
  const [registeringDevice, setRegisteringDevice] = useState(false);
  const [registeredDevice, setRegisteredDevice] = useState<any>(null);
  const [fingerprint, setFingerprint] = useState(0);

  const {
    data: accountDevice,
    isValidating: isAccountDeviceValidating,
    mutate,
  } = useSWR(
    authenticated ? currentAccountDeviceUrl(fingerprint) : null,
    (url: string) =>
      baseFetcher(url + (url.includes("?") ? "&" : "?") + new Date().getTime()),
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
    }
  );

  useEffectOnce(() => {
    (async () => {
      const { fingerprint } = await getBrowserFingerprint(FINGERPRINT_OPTIONS);
      setFingerprint(fingerprint);
    })();
  }, []);

  useEffect(() => {
    if (
      !fingerprint ||
      isAccountDeviceValidating ||
      !authenticated ||
      registeringDevice ||
      account?.isExcludedFromSessionLock ||
      account?.isSkResearch ||
      router.pathname.startsWith("/embed/") ||
      !!router.query["magic-token"]
    )
      return;

    if (!registeredDevice && (!accountDevice || isEmpty(accountDevice))) {
      setRegisteringDevice(true);
      registerDevice(fingerprint)
        .then((newDevice: any) => {
          setRegisteredDevice(newDevice);
        })
        .finally(() => {
          setRegisteringDevice(false);
        });
      return;
    }

    const shouldLock =
      (accountDevice?.status || registeredDevice?.status) === "inactive";

    setShowModal(shouldLock);
  }, [
    accountDevice,
    authenticated,
    registeringDevice,
    fingerprint,
    isAccountDeviceValidating,
    setShowModal,
    toggleModal,
    account?.isInternalUser,
    registeredDevice,
    router.pathname,
    account?.isExcludedFromSessionLock,
    account?.isSkResearch,
    router.query,
  ]);

  return (
    <>
      {showModal ? (
        <AccountSessionLockModal
          onContinue={async () => {
            updateDeviceStatus();
            setShowModal(false);
            mutate(
              {
                ...accountDevice,
                status: "active",
              },
              false
            );
          }}
        />
      ) : (
        children
      )}
    </>
  );
};

export default AccountSessionLock;
