import type { CSSProperties } from "react";

import classNames from "classnames";

import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";

type FontAwesomeIconProps = {
  icon: IconDefinition;
  className?: string;
  pathClassName?: string;
  onClick?: (_e: React.MouseEvent) => void;
  color?: string;
  size?: "lg";
  fixedWidth?: boolean;
  style?: CSSProperties;
  width?: number | string;
  height?: number | string;
  rotation?: number;
  spin?: boolean;
};

const FontAwesomeIcon = ({
  className,
  pathClassName,
  icon,
  color,
  style,
  onClick,
  width,
  height,
  rotation,
  spin,
}: FontAwesomeIconProps) => {
  const widthClass =
    icon.prefix === "fas" || icon.prefix === "far"
      ? "w-[1.125em]"
      : "w-[0.875em]";

  return (
    <svg
      className={classNames(
        "inline-block",
        widthClass,
        "h-[1em]",
        "align-[-0.125em]",
        spin && "animate-spin",
        className
      )}
      style={{
        ...style,
        ...(color ? { color } : {}),
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
        ...(rotation ? { transform: `transform: rotate(${rotation}deg)` } : {}),
      }}
      viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
      onClick={onClick}
    >
      <path
        className={pathClassName}
        fill="currentColor"
        d={icon.icon[4] as string}
      />
    </svg>
  );
};

export default FontAwesomeIcon;
