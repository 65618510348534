export default function replaceQuotes(
  input: string,
  styledQuotes: boolean = true
) {
  if (!input) {
    return "";
  }
  if (styledQuotes) {
    return input
      .replace(/^'|(\s)'/g, "$1‘")
      .replace(/^"|(\s)"/g, "$1“")
      .replace(/'/g, "’")
      .replace(/"/g, "”");
  } else {
    return input.replace(/‘|’/g, "'").replace(/“|”/g, '"');
  }
}
