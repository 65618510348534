import { useCallback, useContext, useEffect, useRef } from "react";

import ActionCableContext from "../contexts/actionCableContext";

export function useChannel({
  key,
  name,
  channel: identifier,
  onConnected,
  onReceived,
  onRejected,
  onDisconnected,
}: any) {
  const channel = useRef<any>();

  const { cable, channels, handlers } = useContext(ActionCableContext);

  const onReceivedChannel = useCallback(
    (message: any) => {
      Object.values(handlers.current[name].onReceived).forEach((handler) => {
        handler?.(message);
      });
    },
    [handlers, name]
  );

  const onConnectedChannel = useCallback(() => {
    onConnected?.(channel.current);
  }, [onConnected]);

  const onRejectedChannel = useCallback(() => {
    onRejected?.();
  }, [onRejected]);

  const onDisconnectedChannel = useCallback(() => {
    delete channels.current[name];
    onDisconnected?.(cable);
  }, [name, channels, onDisconnected, cable]);

  useEffect(() => {
    if (cable && !channel.current) {
      channel.current = channels.current[name];
      if (channel.current) {
        onConnectedChannel();
      } else {
        channel.current = cable.subscriptions.create(identifier, {
          received: onReceivedChannel,
          connected: onConnectedChannel,
          disconnected: onDisconnectedChannel,
          rejected: onRejectedChannel,
        });
        channels.current[name] = channel.current;
        console.debug("[UseChannel] new-channel", name);
      }
    }

    if (!handlers.current[name]) {
      handlers.current[name] = {
        onReceived: {},
      };
    }

    handlers.current[name].onReceived[key || name] = onReceived;
  }, [
    cable,
    channels,
    handlers,
    identifier,
    key,
    name,
    onConnectedChannel,
    onDisconnectedChannel,
    onReceived,
    onReceivedChannel,
    onRejectedChannel,
  ]);

  return null;
}

export const Channel = useChannel;
