import type { Insight } from "models/insight";

import { emberAppBaseUrl } from "utils/envVariables";
import { abbrs, verticles } from "consts";

export function getTopDownTicker(insight: Insight, geographies: any[] = []) {
  const matchedVerticle = verticles[insight.contentVertical?.slug];

  if (
    insight &&
    matchedVerticle &&
    (matchedVerticle.isMacro || matchedVerticle.isThematic)
  ) {
    let gics, ticker;
    let countries: any = insight.countries || [];

    const country = _computeFirstOnly(countries);
    const gicsList = insight.gics;
    const noSector = !gicsList?.sectors.length;
    const sector = !noSector && _computeFirstOnly(gicsList.sectors);
    const geography = geographies.find(
      (geo: any) =>
        geo.countries.sort().join(",") === countries.sort().join(",")
    )?.name;

    if (matchedVerticle.isMacro) {
      if (countries.length <= 2) {
        ticker = countries.join(" & ").trim();
      } else {
        ticker = geography || "Multiple Countries";
      }
    } else if (matchedVerticle.isThematic) {
      if (!noSector) {
        gics = [
          gicsList.sectors,
          gicsList["industry-groups"],
          gicsList.industries,
        ]
          .map(_computeFirstOnly)
          .filter(Boolean)
          .pop();
        if (abbrs[gics]) {
          gics = abbrs[gics];
        }
      }

      if (!gics && countries.length <= 2) {
        countries = countries.join(" & ").trim();
      } else {
        countries = country || geography;
      }

      ticker = [countries, gics].filter(Boolean).join(" ").trim();

      if (!ticker) {
        if (noSector) {
          ticker = "Multiple Countries";
        } else {
          ticker = "Multiple Countries & Sectors";
        }
      }
    }

    if (!ticker) {
      ticker = insight.contentVertical?.name;
    }

    return ticker;
  }
}

function _computeFirstOnly(array: any) {
  if (Array.isArray(array) && array.length === 1) {
    return array[0];
  }

  return "";
}

function _getImperitiveURL(country: any, sector: any, vertical: any) {
  const filterSet = [];
  let filters;

  if (country) {
    filterSet.push(`countries:${country}`);
  }

  if (sector) {
    filterSet.push(`sectors:${sector}`);
  }

  if (filterSet.length) {
    filters = filterSet.join("|");
  } else {
    filters = `verticals:${vertical}`;
  }

  return `${emberAppBaseUrl}/insights?collection=all-insights&filters=${filters}&reset=filters`;
}

export function getExpertiesTags(expertise: any) {
  let tags: any[] = [];

  if (!expertise) {
    return tags;
  }

  ["country", "sector"].forEach((key) => {
    if (!expertise[`${key}-generalist`] && !expertise[`${key}Generalist`]) {
      const items = [
        expertise[`${key}-primary`] || expertise[`${key}Primary`],
        expertise[`${key}-secondary`] || expertise[`${key}Secondary`],
      ].filter(Boolean);
      if (items.length) {
        tags.push(items.join(" & "));
      }
    }
  });

  let verticals = [];
  if (
    !expertise["content-vertical-generalist"] &&
    !expertise["contentVerticalGeneralist"]
  ) {
    verticals = [
      expertise["content-vertical-primary"] || expertise.contentVerticalPrimary,
      expertise["content-vertical-secondary"] ||
        expertise.contentVerticalSecondary,
    ].filter(Boolean);
  }
  const assetClass =
    expertise["asset-class-primary"] || expertise.assetClassPrimary;
  if (assetClass === "Equities") {
    if (verticals.indexOf("Equity Bottom-Up") < 0) {
      tags.push(assetClass);
    }
  } else if (assetClass === "Credit") {
    if (verticals.indexOf("Credit Analysis") < 0) {
      tags.push(assetClass);
    }
  } else if (assetClass === "Multi-Asset") {
    if (verticals.indexOf("Top-Down & Thematic") < 0) {
      tags.push(assetClass);
    }
  } else if (assetClass) {
    tags.push(assetClass);
  }

  if (verticals.length) {
    tags = tags.concat(verticals);
  }

  tags = Array.from(new Set(tags));
  return tags;
}
