import getConfig from "next/config";
import useSWR from "swr";

import { useCallback, useEffect } from "react";

import { SK_CHANNEL } from "hooks/useActionCable";
import { useChannel } from "hooks/useChannel";
import { baseFetcher } from "utils/baseFetcher";
import { clearCache } from "utils/clearCache";

const { publicRuntimeConfig } = getConfig();
const currentVersion = publicRuntimeConfig?.version;
const versionPrefix = `${currentVersion.split(".")[0]}.`;

const PlatformUpdate = () => {
  const { data: frontendUpdates } = useSWR(
    "/api/v2/frontend-updates?page%5Bsize%5D=1&sort=-created-at",
    baseFetcher
  );

  useEffect(() => {
    const latestUpdate = frontendUpdates?.data?.[0];
    if (latestUpdate) {
      const { version, updateType } = latestUpdate;

      if (version.startsWith(versionPrefix) && version !== currentVersion) {
        if (updateType === "minor") {
          clearCache(false);
        } else if (updateType === "major") {
          clearCache(false);
        } else if (updateType === "critical") {
          clearCache(false);
        }
      }
    }
  });

  const platformUpdatesOnReceived = useCallback((message: any) => {
    const { event } = message;
    if (event === "frontend-updated") {
      const { id: _id, version, update_type: updateType } = message;

      if (version.startsWith(versionPrefix) && version !== currentVersion) {
        if (updateType === "minor") {
          clearCache(false);
        } else if (updateType === "major") {
          clearCache(false);
        } else if (updateType === "critical") {
          clearCache(false);
        }
      }
    }
  }, []);

  useChannel({
    key: "PlatformUpdate",
    name: "platform-updates",
    channel: {
      channel: SK_CHANNEL,
      room: "platform-updates",
    },
    onReceived: platformUpdatesOnReceived,
  });

  return null;
};

export default PlatformUpdate;
