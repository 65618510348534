import React, { createContext, useEffect, useRef, useState } from "react";

import type { Consumer as Cable } from "@rails/actioncable";

const ActionCableContext = createContext<{
  cable?: Cable;
  setCable: (_cable: Cable) => void;
  channels: React.MutableRefObject<any>;
  handlers: React.MutableRefObject<{
    [name: string]: {
      onReceived: { [key: string]: any };
    };
  }>;
}>({
  setCable: () => {},
  channels: { current: {} },
  handlers: { current: {} },
});

export default ActionCableContext;

type Props = {
  children: React.ReactNode;
};

export const Consumer = ActionCableContext.Consumer;

export function Provider(props: Props) {
  const [cable, setCable] = useState<any>();

  useEffect(() => () => cable?.disconnect(), [cable]);

  const channelsRef = useRef({});
  const handlersRef = useRef({});

  const value = {
    cable,
    setCable,
    channels: channelsRef,
    handlers: handlersRef,
  };

  return (
    <ActionCableContext.Provider value={value}>
      {props.children}
    </ActionCableContext.Provider>
  );
}
