import JSONAPISerializer from "json-api-serializer";

import { transform as accountTransform } from "./account";
import { transform as collectionTransform } from "./collection";
import {
  schema as commentSchema,
  transform as commentTransform,
} from "./comment";
import { transform as companyTransform } from "./company";
import { transform as contentVerticalTransform } from "./contentVertical";
import { transform as entityTransform } from "./entity";
import { transform as insightTransform } from "./insight";
import { transform as lockerItemTransform } from "./lockerItem";
import {
  schema as messageSchema,
  transform as messageTransform,
} from "./message";
import { transform as planTransform } from "./plan";
import { transform as watchlistTransform } from "./watchlist";

export { transform as accountTransform } from "./account";
export { transform as collectionTransform } from "./collection";
export { transform as companyTransform } from "./company";
export { transform as contentVerticalTransform } from "./contentVertical";
export { transform as entityTransform } from "./entity";
export { transform as insightTransform } from "./insight";
export { transform as lockerItemTransform } from "./lockerItem";
export { transform as planTransform } from "./plan";
export { transform as watchlistTransform } from "./watchlist";

export const transform = (raw: any): any => {
  if (!raw) return;

  if (raw.data?.constructor === Array) {
    return { ...raw, data: raw.data.map((item: any) => transform(item)) };
  }

  if (raw.constructor === Array) {
    return raw.map((item: any) => transform(item));
  }

  switch (raw.type) {
    case "accounts":
      return accountTransform(raw);
    case "companies":
      return companyTransform(raw);
    case "content-verticals":
      return contentVerticalTransform(raw);
    case "entities":
      return entityTransform(raw);
    case "watchlists":
      return watchlistTransform(raw);
    case "collections":
      return collectionTransform(raw);
    case "insights":
      return insightTransform(raw);
    case "locker-items":
      return lockerItemTransform(raw);
    case "plans":
      return planTransform(raw);
    case "comments":
      return commentTransform(raw);
    case "message":
      return messageTransform(raw);
  }
  return raw;
};

export const serializer = new JSONAPISerializer({
  convertCase: "kebab-case",
  unconvertCase: "camelCase",
  convertCaseCacheSize: 0,
});

const TYPES: [string, ((_raw: any) => any)?][] = [
  ["accounts", accountTransform],
  ["collections", collectionTransform],
  ["companies", companyTransform],
  ["content-verticals", contentVerticalTransform],
  ["entities", entityTransform],
  ["Entity", entityTransform], // v3 api
  ["Insight", insightTransform], // v3 api
  ["insights", insightTransform],
  ["locker-items", lockerItemTransform],
  ["plans", planTransform],
  ["watchlists", watchlistTransform],

  ["account-devices"],
  ["activities"],
  ["alpha-captures"],
  ["attachments"],
  ["contact-lists"],
  ["corporate-attachments"],
  ["corporate-events"],
  ["corporate-plans"],
  ["corporate-presentations"],
  ["countries"],
  ["entity-smart-scores"],
  ["entity-stats"],
  ["exchange-announcements"],
  ["executive-trade-companies"],
  ["executive-trade-countries"],
  ["executive-trade-histories"],
  ["executive-trade-sectors"],
  ["executive-trades"],
  ["external-reports"],
  ["factset-institution-funds"],
  ["factset-institution-people"],
  ["factset-institutions"],
  ["factset-target-investors"],
  ["filter-presets"],
  ["frontend-updates"],
  ["geographies"],
  ["images"],
  ["insight-disclosures"],
  ["insight-documents"],
  ["insight-providers"],
  ["insight-smart-summaries"],
  ["insight-themes"],
  ["investor-companies"],
  ["message-groups"],
  ["notification-actors"],
  ["notifications"],
  ["phone-numbers"],
  ["premium-service-catalogues"],
  ["search-histories"],
  ["sectors"],
  ["shared-items"],
  ["sorted-entities"],
  ["target-investors"],
  ["tools"],
];

TYPES.forEach(([type, transform]) => {
  serializer.register(type, {
    id: "id",
    afterDeserialize: (data: any) => {
      return {
        type: type,
        ...(transform ? deleteUndefined(transform(data)) : data),
      };
    },
  });
});

serializer.register("comments", commentSchema);
serializer.register("messages", messageSchema);

export const typeConfig: any = {
  type: (data: { type: string; [key: string]: any }) => data.type,
};

export const deleteUndefined = (obj: Record<string, any> | undefined): any => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] && typeof obj[key] === "object") {
        deleteUndefined(obj[key]);
      } else if (typeof obj[key] === "undefined") {
        delete obj[key]; // eslint-disable-line no-param-reassign
      }
    });
    return obj;
  }
};
