export const verticals = [
  {
    id: "ipos-placements",
    icon: "snowflake-o",
  },
  {
    id: "equity-bottom-up",
    icon: "bar-chart",
  },
  {
    id: "smartkarma",
    name: "Smartkarma",
    icon: "smartkarma",
  },
  {
    id: "technical-analysis",
    icon: "line-chart",
  },
  {
    id: "governance-shenanigans",
    icon: "gavel",
  },
  {
    id: "fx-rates",
    icon: "usd",
  },
  {
    id: "credit-analysis",
    icon: "credit-card",
  },
  {
    id: "commodity-analysis",
    icon: "fire",
  },
  {
    id: "macro",
    icon: "pie-chart",
    idFromBackend: 18,
    isMacro: true,
  },
  {
    id: "thematic-strategy",
    icon: "hashtag",
    idFromBackend: 24,
    isThematic: true,
  },
  {
    id: "event-driven",
    icon: "flash",
  },
  {
    id: "cross-asset-strategy",
    icon: "globe",
    isMacro: true,
  },
  {
    id: "forensic-accounting",
    icon: "search-plus",
  },
  {
    id: "debt-capital-markets",
    icon: "money",
  },
  {
    id: "private-capital-markets",
    icon: "rocket",
  },
  {
    id: "quantitative-analysis",
    icon: "calculator",
    isThematic: true,
  },
];
