import { createContext } from "react";
import type { ReactNode } from "react";

export const TopNavBarContext = createContext<{
  showBackButton: boolean;
  setShowBackButton: (_value: boolean) => void;
  rightActionButtons: ReactNode | null;
  setRightActionButtons: (_elements: ReactNode) => void;
  notificationsReadAt: Date;
  setNotificationsReadAt: (_value: Date) => void;
  totalUnreadNotifications: number;
  setTotalUnreadNotifications: (_value: number) => void;
  totalUnreadMessageGroups: number;
  setTotalUnreadMessageGroups: (_value: number) => void;
}>({
  showBackButton: false,
  setShowBackButton: (_value: boolean) => {},
  rightActionButtons: null,
  setRightActionButtons: (_elements: ReactNode) => {},
  notificationsReadAt: new Date(0),
  setNotificationsReadAt: (_value: Date) => {},
  totalUnreadNotifications: 0,
  setTotalUnreadNotifications: (_value: number) => {},
  totalUnreadMessageGroups: 0,
  setTotalUnreadMessageGroups: (_value: number) => {},
});
