import { useCallback, useMemo } from "react";

import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { twMerge } from "tailwind-merge";

export default function CountrySelect({
  value,
  onChange,
  options,
  disabled,
  readOnly,
  ...rest
}: any) {
  const onChange_ = useCallback(
    (event) => {
      const value = event.target.value;
      onChange(value === "ZZ" ? undefined : value);
    },
    [onChange]
  );

  // "ZZ" means "International".
  // (HTML requires each `<option/>` have some string `value`).
  return (
    <select
      {...rest}
      disabled={disabled || readOnly}
      readOnly={readOnly}
      value={value || "ZZ"}
      onChange={onChange_}
      // className={twMerge(
      //   "mr-[5px] max-w-[120px] truncate",
      //   "focus:border-smart-teal hover:border-smart-teal focus:ring-0 hover:ring-0"
      // )}
    >
      {options.map(({ value, label, divider }: any) => (
        <option
          key={divider ? "|" : value || "ZZ"}
          value={divider ? "|" : value || "ZZ"}
          disabled={divider ? true : false}
          style={divider ? DIVIDER_STYLE : undefined}
        >
          {label}
        </option>
      ))}
    </select>
  );
}

export function CountrySelectWithIcon({
  value,
  options,
  className,
  iconComponent: Icon,
  getIconAspectRatio,
  arrowComponent: Arrow = DefaultArrowComponent,
  unicodeFlags,
  ...rest
}: any) {
  const selectedOption = useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  return (
    <div className="PhoneInputCountry w-[120px] pr-[5px]">
      <CountrySelect
        {...rest}
        value={value}
        options={options}
        className={twMerge("PhoneInputCountrySelect", className)}
      />

      {/* Either a Unicode flag icon. */}
      {unicodeFlags && value && (
        <div className="PhoneInputCountryIconUnicode">
          {getUnicodeFlagIcon(value)}
        </div>
      )}

      {/* Or an SVG flag icon. */}
      {!unicodeFlags && value && (
        <Icon
          aria-hidden
          country={value}
          label={selectedOption && selectedOption.label}
          aspectRatio={unicodeFlags ? 1 : undefined}
        />
      )}

      <span className="ml-[5px] truncate text-left flex-1">
        {selectedOption.label === "International"
          ? "Country Code"
          : selectedOption.label}
      </span>

      <Arrow />
    </div>
  );
}

function DefaultArrowComponent() {
  return <div className="PhoneInputCountrySelectArrow" />;
}

function getSelectedOption(options: any[], value: any) {
  for (const option of options) {
    if (!option.divider && option.value === value) {
      return option;
    }
  }
}

const DIVIDER_STYLE = {
  fontSize: "1px",
  backgroundColor: "currentColor",
  color: "inherit",
};
