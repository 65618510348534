import { getExpertiesTags } from "utils/insight";

export const transform = (raw: any) => {
  return {
    ...raw,
    isCompany: true,

    get description() {
      if (this.title) {
        return this.title;
      }
      if (this.isInsightProvider) {
        return "Insight Provider Company";
      }
      if (this.isClient) {
        return "Client Company";
      }
    },

    get hadInsightsPublished() {
      return !!this.publishedInsightsCount;
    },

    get hadReportsPublished() {
      return !!this.publishedExternalReportsCount;
    },

    get showAnalysisTab() {
      return this.hadInsightsPublished || this.hadReportsPublished;
    },

    get showStatisticsTab() {
      return this.showAnalysisTab;
    },

    get showDiscussionsTab() {
      return !((this.isIssuerPaid || this.isSyndicator) && !this.isCompany);
    },

    get showTeamTab() {
      return (
        this.isInsightProvider && this.analystsCount > 0
        // || (this.isClient && this.clientsCount > 0)
      );
    },

    get hasPremiumServices() {
      return false;
    },

    get showPremiumServicesTab() {
      return false;
    },

    get canBeFollowed() {
      return raw.analystsCount > 0;
    },

    get expertiesTags() {
      if (raw.areasOfExpertise) return getExpertiesTags(raw.areasOfExpertise);
    },
  };
};
