import { createContext, useContext, useEffect, useRef, useState } from "react";

import IframeModal from "components/IframeModal";

export const IframeModalContext = createContext({
  showModal: (_show: boolean, _url: string, _onClose?: () => void) => {},
  url: "",
  setUrl: (_url: string) => {},
});

type IframeModalContextProviderProps = {
  children: React.ReactNode;
};

export const IframeModalContextProvider = ({
  children,
}: IframeModalContextProviderProps) => {
  const [showModal, setShowModal] = useState(false);
  const [alwaysShow, setAlwaysShow] = useState(false);
  const [url, setUrl] = useState("");

  const onCloseRef = useRef<(() => void) | undefined>();

  const value = {
    showModal: (show: boolean, url?: string, onClose?: () => void) => {
      setUrl(url || "");
      setShowModal(show);
      onCloseRef.current = onClose;
    },
    url,
    setUrl,
  };

  useEffect(() => {
    setAlwaysShow(alwaysShow || showModal);
  }, [alwaysShow, showModal]);

  return (
    <IframeModalContext.Provider value={value}>
      {children}
      {alwaysShow && (
        <IframeModal
          show={showModal}
          url={url}
          onClose={() => {
            if (onCloseRef.current) {
              setShowModal(false);
              return onCloseRef.current();
            }

            window.history.back();
            setShowModal(false);
          }}
        />
      )}
    </IframeModalContext.Provider>
  );
};

export default function useIframeModal() {
  return useContext(IframeModalContext);
}
