import { IncomingMessage } from "http";
import ipaddr from "ipaddr.js";

import { cloudflareIpRanges } from "consts/cloudflareIps";

const getRequestIP = (req: IncomingMessage) => {
  const cfConnectingIP = (req.headers?.["CF-Connecting-IP"] ||
    req.headers?.["cf-connecting-ip"]) as string;

  if (cfConnectingIP) return cfConnectingIP;

  const forwarded = req.headers?.["x-forwarded-for"] as string;

  const ip =
    typeof forwarded === "string"
      ? getForwaredForIP(forwarded)
      : req.socket.remoteAddress;

  return ip;
};

export const getForwaredForIP = (forwaredHeader: string) => {
  const ips = forwaredHeader.split("/, /");
  const ip = ips
    .filter((ip) => !!ip?.trim())
    .find((ip) => {
      try {
        const parsedIP = ipaddr.parse(ip);
        return cloudflareIpRanges.some(
          (range) => parsedIP.kind === range[0].kind && parsedIP.match(range)
        );
      } catch {
        return false;
      }
    });
  return ip;
};

export default getRequestIP;
