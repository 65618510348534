import useSWR, { Fetcher } from "swr";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";

import { useEffect, useState } from "react";

export const useApi = <T = any>(
  key: any,
  fetcher: Fetcher<T> | null = null,
  options?: { laggy?: boolean; [key: string]: any }
) => {
  const { laggy, ...otherOptions } = options || {};
  const [oldData, setOldData] = useState<any>(null);
  const [result, setResult] = useState<any>(null);

  const { data, isValidating, isLoading, error, ...rest } = useSWR<T>(
    key,
    fetcher,
    otherOptions
  );

  useEffect(() => {
    if (laggy && data && data !== oldData) {
      setOldData(data);
      setResult(data);
    }
  }, [data, laggy, oldData]);

  return {
    data: (options?.laggy ? result : data) as T,
    isValidating,
    isFirstLoading: isLoading,
    isLoading: (!data && !error) || isValidating,
    ...rest,
  };
};

export const useListApi = <T = any>(
  key: any,
  fetcher: Fetcher<T> | null = null,
  options?: SWRInfiniteConfiguration
) => {
  const { data, isValidating, isLoading, error, ...rest } = useSWRInfinite(
    key,
    fetcher,
    options
  );

  return {
    data,
    isValidating,
    isFirstLoading: isLoading,
    isLoading: (!data && !error) || isValidating,
    error,
    ...rest,
  };
};
