import { useRouter } from "next/router";
import useSWR from "swr";

import React from "react";

import addDays from "date-fns/addDays";
import isAfter from "date-fns/isAfter";
import parseJSON from "date-fns/parseJSON";
import { useToggle } from "usehooks-ts";

import AccountMFAModal from "components/AccountMFAModal";
import useAuthentication from "hooks/useAuthentication";
import { useEffectOnce } from "hooks/useEffectOnce";
import { baseFetcher } from "utils/baseFetcher";

export const REMINDER_DAYS = 7;
export const REVERIFY_DAYS = 30;

const AccountMFA = () => {
  const router = useRouter();

  const { account, accountId, interfaceSetting } = useAuthentication();

  const [showModal, toggleModal, setShowModal] = useToggle();

  const { data: phoneNumber, isValidating: isPhoneNumberValidating } = useSWR(
    accountId ? `/api/v2/accounts/${accountId}/phone-number` : null,
    baseFetcher
  );

  useEffectOnce(
    (acknowledge) => {
      if (
        isPhoneNumberValidating ||
        !interfaceSetting ||
        router.pathname.startsWith("/embed/")
      )
        return;

      const { mfaReminderTime, requiresOnboarding } = interfaceSetting;

      const hasPhoneNumber = phoneNumber?.verified;
      const shouldRemind =
        !phoneNumber?.skipVerification &&
        (!hasPhoneNumber ||
          isAfter(
            new Date(),
            addDays(parseJSON(phoneNumber.verifiedAt), REVERIFY_DAYS)
          )) &&
        (!mfaReminderTime ||
          isAfter(
            new Date(),
            addDays(parseJSON(mfaReminderTime), REMINDER_DAYS)
          ));

      if (
        (account?.isInternalUser ||
          (account?.isProfessionalClient &&
            [
              "Singapore",
              "Hong Kong",
              "United States",
              "Australia",
              "India",
            ].includes(account?.country!))) &&
        !account?.isSkResearch &&
        !requiresOnboarding &&
        shouldRemind
      ) {
        setShowModal(true);
      }

      acknowledge();
    },
    [
      account?.isInternalUser,
      account?.isSkResearch,
      interfaceSetting,
      phoneNumber,
      isPhoneNumberValidating,
    ]
  );

  return (
    <>
      {showModal && (
        <AccountMFAModal phoneNumber={phoneNumber} onClose={toggleModal} />
      )}
    </>
  );
};

export default AccountMFA;
