export const ranks: any = {
  "Top 5%": {
    id: "Top 5%",
    slug: "top-five",
    text: "Top 5%",
    description:
      "This represents an elite group of Insight Providers who have demonstrated their thought leadership and value add to the Smartkarma ecosystem.",
    isTopHalf: true,
    value: 0.05,
  },
  "Top Quartile": {
    id: "Top Quartile",
    slug: "top-quartile",
    text: "Top Quartile",
    description:
      "Top Quartile Insight Providers have a consistent and successful work history on Smartkarma. They are strong drivers of engagement, collaboration and value add.",
    isTopHalf: true,
    value: 0.25,
  },
  "2nd Quartile": {
    id: "2nd Quartile",
    slug: "rising",
    text: "Rising",
    description:
      "These Insight Providers have demonstrated strong performance and a credible track record.",
    isTopHalf: true,
    value: 0.5,
  },
  "3rd Quartile": {
    id: "3rd Quartile",
    text: "3rd Quartile",
    isTopHalf: false,
    value: 0.75,
  },
  "Bottom Quartile": {
    id: "Bottom Quartile",
    text: "Bottom Quartile",
    isTopHalf: false,
    value: 1,
  },
};
