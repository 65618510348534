const parseCookie = (cookie: string) => {
  return (
    cookie?.split(";").reduce((acc, c) => {
      const split = c.trim().split("=");
      return { ...acc, [split[0]]: decodeURIComponent(split[1]) };
    }, {} as { [key: string]: string }) || {}
  );
};

export default parseCookie;
