/* @flow */

import useSWR, { useSWRConfig } from "swr";

import { useCallback, useContext, useEffect, useState } from "react";

import {
  currentAccountDeviceUrl,
  FINGERPRINT_OPTIONS,
} from "components/AccountSessionLock";
import { TopNavBarContext } from "components/TopNavBar/TopNavBarContext";
import { websocketUrl } from "utils/envVariables";
import getBrowserFingerprint from "utils/getBrowserFingerprint";

import ActionCableContext from "../contexts/actionCableContext";
import { baseFetcher } from "../utils/baseFetcher";

import useAuthentication from "./useAuthentication";
import { useChannel } from "./useChannel";

export const SK_CHANNEL = "SkChannel";

export default function useActionCable() {
  const [connecting, setConnecting] = useState(false);

  const { account: userProfile, mutateAccount } = useAuthentication();

  const { cable, setCable } = useContext(ActionCableContext);
  const {
    setNotificationsReadAt,
    setTotalUnreadNotifications,
    setTotalUnreadMessageGroups,
  } = useContext(TopNavBarContext);

  const { data: webSocketToken } = useSWR(
    "/api/v2/web-socket/authenticate",
    baseFetcher
  );

  const { mutate: globalMutate } = useSWRConfig();

  useEffect(() => {
    if (webSocketToken && !connecting && !cable && userProfile) {
      setConnecting(true);
      try {
        import("@rails/actioncable").then((ActionCable) => {
          const newCable = ActionCable.createConsumer(
            `${websocketUrl}?token=${webSocketToken?.token}`
          );
          setCable(newCable);
          setConnecting(false);
        });
      } catch (err) {
        console.debug(err);
      }
    }
  }, [
    cable,
    connecting,
    setCable,
    userProfile,
    webSocketToken,
    webSocketToken?.token,
  ]);

  const onNewMessage = useCallback(
    (data: any) => {
      // eslint-disable-next-line no-console
      console.debug("[ActionCable] new message", data);

      const {
        message_id: _messageId,
        message_group_id: _messageGroupId,
        total_unread_message_groups: totalUnreadMessageGroups,
      } = data;

      setTotalUnreadMessageGroups(totalUnreadMessageGroups);
    },
    [setTotalUnreadMessageGroups]
  );

  const onMessageRead = useCallback(
    (data: any) => {
      // eslint-disable-next-line no-console
      console.debug("[ActionCable] message read", data);

      const {
        message_group_id: _messageGroupId,
        total_unread_message_groups: totalUnreadMessageGroups,
      } = data;

      setTotalUnreadMessageGroups(totalUnreadMessageGroups);
    },
    [setTotalUnreadMessageGroups]
  );

  const onNewNotification = useCallback(
    (data: any) => {
      // eslint-disable-next-line no-console
      console.debug("[ActionCable] onNewNotification", data);

      const {
        notification_id: notificationId,
        total_unread_notifications: totalUnreadNotifications,
      } = data;

      if (!notificationId) {
        // eslint-disable-next-line no-console
        console.debug(
          "[ActionCable] Invalid web socket data for onNewNotification: ",
          data
        );
        return;
      }

      setTotalUnreadNotifications(totalUnreadNotifications);
    },
    [setTotalUnreadNotifications]
  );

  const onNotificationRead = useCallback(
    (data: any) => {
      const notificationsReadAt = new Date(data.notifications_read_at);
      const totalUnreadNotifications = 0;

      setNotificationsReadAt(notificationsReadAt);
      setTotalUnreadNotifications(totalUnreadNotifications);
    },
    [setNotificationsReadAt, setTotalUnreadNotifications]
  );

  const onNewSearchHistory = useCallback(() => {
    // dispatch({ type: "Search/FETCH_HISTORY" });
  }, []);

  const onAccountDeactivated = useCallback(() => {
    // dispatch(logout("web-socket:account-deactivated"));
  }, []);

  const onAccountUpdated = useCallback(() => {
    // NOTE: event has an payload with: { is_preview_pass: [true, false], is_client: [true, false] }
    // each has array, indicating prev and next value
    mutateAccount();
  }, [mutateAccount]);

  const reAuthenticate = (_disconnectedCable: any) => {
    // getWebSocketToken()
    //   .then(({ token }) => {
    //     // eslint-disable-next-line no-param-reassign
    //     disconnectedCable.url = `${WEBSOCKET_URL}?token=${token}`;
    //   })
    //   .catch(logError);
  };

  const refreshDiscussionStream = () => {
    // if (listState) {
    //   dispatch({
    //     type: "List/skListDiscussionStream/REFRESH",
    //     instance: "default",
    //     noIndicator: true,
    //   });
    // }
    // // NOTE: refresh widget (both tabs)
    // if (discussionStreamLatest) {
    //   dispatch(fetchDiscussionStreamLatest());
    // }
    // if (discussionStreamLatestForMe) {
    //   dispatch(fetchDiscussionStreamLatest(true));
    // }
  };

  const refreshMessageGroup = (_data: any) => {
    // const { message_group_id: messageGroupId } = data;
    // dispatch(loadMessageGroup(messageGroupId));
  };

  const refreshMessageGroups = () => {
    // if (messageGroups) {
    //   dispatch({
    //     type: "List/skListMessageGroupList/REFRESH",
    //     instance: "default",
    //     noIndicator: true,
    //     props: {
    //       currentUserId: userToken.account_id.toString(),
    //     },
    //   });
    // }
  };

  const handleReceived = useCallback(
    (room: string, message: any = {}) => {
      console.debug("[ActionCable] Cable received", room, message); // eslint-disable-line no-console
      const { event, ...data } = message;
      switch (room) {
        case `private-account-${userProfile?.id}`:
          switch (event) {
            case "new-message":
              onNewMessage(data);
              break;
            case "message-read":
              onMessageRead(data);
              break;
            case "participants-changed":
              refreshMessageGroup(data);
              break;
            case "left-group":
              refreshMessageGroups();
              break;
            case "new-notification":
              onNewNotification(data);
              break;
            case "notifications-read":
              onNotificationRead(data);
              break;
            case "account-deactivated":
              onAccountDeactivated();
              break;
            case "account-updated":
              onAccountUpdated();
              break;
            case "new-search-history":
              onNewSearchHistory();
              break;
            case "account-device-activated":
              getBrowserFingerprint(FINGERPRINT_OPTIONS).then(
                ({ fingerprint }) => {
                  globalMutate(currentAccountDeviceUrl(fingerprint));
                  mutateAccount();
                }
              );
              break;
            default:
              break;
          }
          break;
        case "private-comments":
          switch (event) {
            case "new-comment":
              refreshDiscussionStream();
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    [
      globalMutate,
      mutateAccount,
      onAccountDeactivated,
      onAccountUpdated,
      onMessageRead,
      onNewMessage,
      onNewNotification,
      onNewSearchHistory,
      onNotificationRead,
      userProfile?.id,
    ]
  );

  const privateCommentOnReceived = useCallback(
    (message: any) => handleReceived("private-comments", message),
    [handleReceived]
  );

  const privateAccountOnReceived = useCallback(
    (message: any) =>
      handleReceived(`private-account-${userProfile?.id}`, message),
    [handleReceived, userProfile?.id]
  );

  const platformUpdatesOnReceived = useCallback(
    (message: any) => handleReceived("platform-updates", message),
    [handleReceived]
  );

  useChannel({
    key: "useActionCable",
    name: "private-comments",
    channel: { channel: SK_CHANNEL, room: "private-comments" },
    onReceived: privateCommentOnReceived,
  });

  useChannel({
    key: "useActionCable",
    name: `private-account-${userProfile?.id}`,
    channel: {
      channel: SK_CHANNEL,
      room: `private-account-${userProfile?.id}`,
    },
    onReceived: privateAccountOnReceived,
    onDisconnected: reAuthenticate,
  });

  useChannel({
    key: "useActionCable",
    name: "platform-updates",
    channel: {
      channel: SK_CHANNEL,
      room: "platform-updates",
    },
    onReceived: platformUpdatesOnReceived,
  });
}
