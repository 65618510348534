export const INSIGHT_PROVIDER_BADGES = [
  {
    id: "insight-provider",
    name: "Insight Provider",
  },
  {
    id: "syndicator",
    name: "Syndicator",
  },
  {
    id: "issuer-paid",
    name: "Issuer-paid",
  },
  {
    id: "broker",
    name: "Broker",
  },
  {
    id: "buy-side",
    name: "Buy-side",
  },
];
