const objectToQueryString = (query: { [key: string]: string | string[] }) => {
  let queryString = "";
  if (Object.keys(query).length) {
    queryString = `?${Object.entries(query)
      .map(([key, value]) => {
        if (typeof value === "string") {
          return `${key}=${value}`;
        }

        return value?.map?.((v) => `${key}=${v}`).join("&");
      })
      .join("&")}`;
  }

  return queryString;
};

export default objectToQueryString;
