import { useCallback } from "react";

import Bugsnag from "@bugsnag/js";

import { fetchApi } from "../utils/baseFetcher";

import useAuthentication from "./useAuthentication";

export const createActivity = async (
  key: string,
  trackable: any = null,
  parameters: any = {},
  options?: {
    context?: any;
  }
) => {
  return fetchApi("/api/v2/activities", {
    method: "POST",
    body: JSON.stringify({
      data: {
        type: "activities",
        attributes: {
          key,
          parameters,
        },
        relationships: {
          ...(trackable
            ? {
                trackable: {
                  data: { type: trackable.type, id: trackable.id },
                },
              }
            : {}),
        },
      },
    }),
    ...options,
  });
};

const useActivityTracker = () => {
  const { account, authenticated } = useAuthentication();

  const trackActivity = useCallback(
    async (key, trackable = null, parameters = {}) => {
      if (!authenticated) return;

      try {
        const newActivity = await createActivity(key, trackable, parameters);

        return newActivity;
      } catch (error: any) {
        Bugsnag.notify(error, (e) => {
          e.severity = "info";
          e.unhandled = false;
          return true;
        });
      }
    },
    [authenticated]
  );

  const trackEvent = useCallback(
    (event, parameters) => {
      if (typeof gtag === "undefined") return;

      gtag?.("event", event, {
        user_id: account?.id,
        account_type: account?.accountType,
        account_subtype: account?.clientType || account?.insightProviderType,
        ...parameters,
      });
    },
    [
      account?.accountType,
      account?.clientType,
      account?.id,
      account?.insightProviderType,
    ]
  );

  return {
    trackActivity,
    trackEvent,
  };
};

export default useActivityTracker;
