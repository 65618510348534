export const transform = (raw: any) => {
  return {
    ...raw,

    get permission() {
      return raw.mySharedItem?.permission;
    },

    get canEdit() {
      return (
        !this.isSharedToMe ||
        this.permission === "edit" ||
        this.permission === "manage"
      );
    },

    get canManage() {
      return !this.isSharedToMe || this.permission === "manage";
    },
  };
};
