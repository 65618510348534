export const schema = {
  id: "id",
  afterDeserialize: (data: any) => {
    return {
      type: "comments",
      ...(typeof window !== "undefined" ? transform(data) : data),
    };
  },
  relationships: {
    parent: {
      type: "comments",
    },
    commenter: {
      type: "accounts",
    },
    commentable: {
      type(relationshipData: any, _data: any) {
        return relationshipData.type;
      },
    },
    commentableInsights: {
      type: "insights",
    },
    commentableEntities: {
      type: "entities",
    },
    commentableAccounts: {
      type: "accounts",
    },
    commentableCompanies: {
      type: "companies",
    },
  },
};

export const transform = (raw: any) => {
  return {
    ...raw,
  };
};
