export default function shortSecurity(security: string) {
  if (!security || !security.match) {
    return security;
  }

  const match = security
    .replace(/\s+/g, " ")
    .match(/([\d\-&_A-Z_/]+ [A-Z]{2}) EQUITY/i);
  if (match) {
    return match[1];
  } else {
    return security;
  }
}
