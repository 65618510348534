import { createContext, useContext, useMemo } from "react";

import parseCookie from "utils/parseCookie";

export const ThemeContext = createContext("");

type ThemeContextProviderProps = {
  children: React.ReactNode;
  cookie: string;
  url: string;
};

export const ThemeContextProvider = ({
  children,
  cookie,
  url,
}: ThemeContextProviderProps) => {
  const theme = useMemo(() => {
    if (typeof window !== "undefined") {
      const themeParam = new URL(window.location.href).searchParams.get(
        "theme"
      );
      const themeCookie = parseCookie(document.cookie)["sk-theme"];
      if (themeParam && (!themeCookie || themeCookie !== themeParam)) {
        document.cookie = `sk-theme=${themeParam}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
      }
      return (themeParam || themeCookie)?.toLowerCase();
    }

    const themeParam = new URL("https://dummy.com" + url).searchParams.get(
      "theme"
    );
    const themeCookie = parseCookie(cookie)["sk-theme"];
    return (themeParam || themeCookie)?.toLowerCase();
  }, [cookie, url]);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default function useTheme() {
  return useContext(ThemeContext);
}
