import Bugsnag from "@bugsnag/js";

import { expirationTime } from "consts/expireTime";

import { fetchApi } from "./baseFetcher";

export const setSessionCookies = async (
  data: {
    account_id: string;
    token: string;
  },
  options?: {
    registerDevice?: boolean;
  }
) => {
  try {
    const authenticated = {
      ...data,
      authenticator: "authenticator:access-token",
    };

    const expireTime = expirationTime();

    document.cookie = `ember_simple_auth-session=${encodeURIComponent(
      JSON.stringify({ authenticated })
    )}; expires=${expireTime}; path=/`;
    document.cookie = `ember_simple_auth-session-expiration_time=2592000; expires=${expireTime}; path=/`;

    const accountId = data?.account_id;

    if (options?.registerDevice) {
      import("components/AccountSessionLock").then(({ updateDeviceStatus }) => {
        updateDeviceStatus();
      });
    }

    const account = await fetchApi(
      `/api/v2/accounts/${accountId}?include=company`,
      {
        authenticatedData: data,
      }
    );
    if (account.isCorporateOfficer) {
      const managedEntities = await fetchApi(
        `/api/v2/companies/${account.company.id}/managed-entities`
      );
      const managedEntity = managedEntities?.data?.[0];
      document.cookie = `sk-account-type=corporate-officer; path=/`;
      document.cookie = `sk-account-subtype=; path=/`;
      document.cookie = `sk-managed-entity=${managedEntity?.slug}; path=/`;
      document.cookie = `sk-preview-pass-interest=; path=/`;
    } else if (account.isPreviewPass) {
      document.cookie = `sk-account-type=preview-pass; path=/`;
      document.cookie = `sk-account-subtype=; path=/`;
      document.cookie = `sk-preview-pass-interest=${account?.previewPassInterest}; path=/`;
      document.cookie = `sk-preview-pass-interest=; path=/`;
    } else {
      document.cookie = `sk-account-type=${account?.accountCookieType}; path=/`;
      document.cookie = `sk-account-subtype=${
        account?.clientType || account?.insightProviderType
      }; path=/`;
      document.cookie = `sk-managed-entity=; path=/`;
      document.cookie = `sk-preview-pass-interest=; path=/`;
    }
    document.cookie = `sk-top-author-ids=${account?.topAuthorIds}; path=/`;
    document.cookie = `sk-top-entity-ids=${account?.topEntityIds}; path=/`;
    document.cookie = `sk-top-content-vertical-ids=${account?.topContentVerticalIds}; path=/`;
    document.cookie = `sk-top-sectors=${account?.topSectors}; path=/`;
    document.cookie = `sk-top-countries=${account?.topCountries}; path=/`;
    document.cookie = `sk-total-activities=${account?.totalActivities}; path=/`;
    document.cookie = `sk-total-watched-entities=${account?.totalWatchedEntities}; path=/`;
    document.cookie = `sk-total-followed-insight-providers=${account?.totalFollowedInsightProviders}; path=/`;
    document.cookie = `sk-signup-source-type=${account?.sourceType}; path=/`;
    document.cookie = `sk-signup-source-id=${account?.sourceId}; path=/`;
    document.cookie = `sk-introducer-company-id=${account?.introducerCompany?.id}; path=/`;
    document.cookie = `sk-cfa-expiry-at=${account?.cfaExpiryAt}; path=/`;

    return account;
  } catch (error: any) {
    Bugsnag.notify(error, (e) => {
      e.severity = "warning";
      e.unhandled = false;
      return true;
    });
    return null;
  }
};
