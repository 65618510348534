import { useCallback } from "react";

import { twMerge } from "tailwind-merge";

import Modal from "components/Modal";
import useActivityTracker from "hooks/useActivityTracker";
import useAuthentication from "hooks/useAuthentication";

export default function InsightProviderPublicReferencesAgreement() {
  const { account, updateInterfaceSetting } = useAuthentication();
  const { trackActivity } = useActivityTracker();

  const onCloseAgreement = useCallback(() => {
    trackActivity("ip_updated_agreement_2025q1.agree", account, {
      agreed_at: new Date().toISOString(),
    });
    updateInterfaceSetting({
      ipUpdatedAgreement2025Q1AgreedAt: new Date().toISOString(),
    });
  }, [account, trackActivity, updateInterfaceSetting]);

  const onOpenAgreement = useCallback(() => {
    trackActivity("ip_updated_agreement_2025q1.view", account);
  }, [account, trackActivity]);

  const openLink = useCallback((event, link) => {
    event.preventDefault();
    window.open(link, "_blank", "width=800,height=800,resizable=yes");
  }, []);

  if (
    account?.isInsightProvider &&
    ["external", "trial"].includes(account.insightProviderType) &&
    new Date(account?.createdAt) < new Date("2025-03-24 23:59:59")
  ) {
    if (account?.interfaceSetting?.ipUpdatedAgreement2025Q1AgreedAt)
      return null;
    return (
      <Modal
        className={twMerge("md:w-[600px]")}
        overlayClassName="bg-black/90 flex flex-col md:justify-center"
        onClose={onCloseAgreement}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        customBackButtonLabel=" "
        hideCloseCTA
        onAfterOpen={() => {
          document.body.classList.add("!max-h-[calc(100vh-100px)]");
          onOpenAgreement();
        }}
        onAfterClose={() => {
          document.body.classList.remove("!max-h-[calc(100vh-100px)]");
        }}
      >
        <div className={twMerge("p-[15px] md:p-[30px]")}>
          <h1 className="text-[20px] font-bold flex items-center justify-between">
            <span className="flex-1">
              Important Update: Data Usage & Insight Provider Agreement
            </span>
          </h1>
          <p>
            As part of our ongoing commitment to compliance and data integrity,
            we are introducing additional safeguards related to data usage.
          </p>
          <p>
            By continuing to use our platform after reading this notice, you
            certify that only publicly available data has been referenced,
            replicated, or otherwise used in any and all Content created and
            disseminated by you through the Smartkarma platform, whether by way
            of publication on the Smartkarma website, directly to Smartkarma
            clients, or otherwise.
          </p>
          <p>
            At the same time, we are also updating your existing Insight
            Provider Agreement, primarily to incorporate a new Content Warranty
            that reflects the above, and also to implement some administrative
            amendments. Please refer to the{" "}
            <a
              className="text-smart-teal hover:text-smart-teal/80"
              href="https://docs.smartkarma.com/ip/IP%20Agreement%20-%20FAQs.pdf"
              onClick={(event) =>
                openLink(
                  event,
                  "https://docs.smartkarma.com/ip/IP%20Agreement%20-%20FAQs.pdf"
                )
              }
            >
              FAQ
            </a>
            , as well as the full text of the updated{" "}
            <a
              className="text-smart-teal hover:text-smart-teal/80"
              href="https://docs.smartkarma.com/ip/ipagreementT&Cs.pdf"
              onClick={(event) =>
                openLink(
                  event,
                  "https://docs.smartkarma.com/ip/ipagreementT&Cs.pdf"
                )
              }
            >
              Agreement
            </a>
            .
          </p>
          <p className="">
            By continuing to use our platform after reading this notice, you
            also agree to the above mentioned updated Insight Provider
            Agreement.
          </p>
          <p className="mb-0">
            Thank you for your continued commitment and for being a valued
            member of the Smartkarma community.
          </p>
          <div className="flex flex-1 mt-[30px]">
            <button
              className={twMerge(
                "bg-smart-teal hover:bg-dark-teal flex-1",
                "border-none rounded-full",
                "text-white font-medium",
                "leading-[1] md:leading-[24px]",
                "text-[15px]",
                "px-[1.25em] py-[0.75em] md:px-[1em] md:py-[0.5em]"
              )}
              onClick={onCloseAgreement}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
}
