export default function htmlText(html: string) {
  if (!html || !html.replace) {
    return html || "";
  }

  const cleanedHtml = html
    .replace(/(<[^>]+\s)src=[^>\s]+/gi, "$1") // remove src so we don't load images
    .replace(/<br\s*\/?>/gi, " "); // replace <br> with space'

  if (typeof window !== "undefined") {
    const tmp = document.implementation.createHTMLDocument("New").body;
    tmp.innerHTML = cleanedHtml;
    return tmp.textContent || tmp.innerText || "";
  }

  return cleanedHtml.replace(/<(?:.|\s)*?>/g, "");
}
