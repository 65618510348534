import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey:
    process.env.NEXT_PUBLIC_FIREBASE_apiKey ||
    "AIzaSyCLvmPWZ1HYsgBvGorWjSrT3WsDKWZFDak",
  authDomain:
    process.env.NEXT_PUBLIC_FIREBASE_authDomain ||
    "smartkarma-dev-ceb93.firebaseapp.com",
  databaseURL:
    process.env.NEXT_PUBLIC_FIREBASE_databaseURL ||
    "https://smartkarma-dev-ceb93.firebaseio.com",
  projectId:
    process.env.NEXT_PUBLIC_FIREBASE_projectId || "smartkarma-dev-ceb93",
  storageBucket:
    process.env.NEXT_PUBLIC_FIREBASE_storageBucket ||
    "smartkarma-dev-ceb93.appspot.com",
  messagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_messagingSenderId || "440677982545",
  appId:
    process.env.NEXT_PUBLIC_FIREBASE_appId ||
    "1:440677982545:web:110ad2602f712fe5817370",
  measurementId:
    process.env.NEXT_PUBLIC_FIREBASE_measurementId || "G-TX70X50XT5",
};

const app = initializeApp(firebaseConfig);

export default app;
