export const assetBucket = "https://assets.smartkarma.com";
export const defaultSocialImage = `${assetBucket}/v3/img/smartkarma-research-reinvented-dark.png`;
export const lightSocialImage = `${assetBucket}/v3/img/smartkarma-research-reinvented-light.png`;
export const lightSocialOriginal = `${assetBucket}/v3/img/smartkarma-research-reinvented-originals-v2.png`;
export const logo = `${assetBucket}/v2/img/smartkarma-logo.png`;
export const fullLogo =
  "https://branding.smartkarma.com/assets/uploaded/sites/10/2021/03/smartkarma-primary-logo-full-colour-1000px.svg";
export const compactLogo =
  "https://branding.smartkarma.com/assets/uploaded/sites/10/2019/06/curation-compass-box-full-colour-1000px.svg";
export const emptyMan = `${assetBucket}/v3/img/404/404-man.svg`;
export const compassLogo =
  "https://sk-assets.s3.amazonaws.com/online-branding-manual/01-logotypes/smartkarma-secondary-logo-full-colour-1000px.png";
export const symphonyLogo = `${assetBucket}/v2/img/symphony_shards.svg`;
export const socGenLoginRequest =
  "https://info.smartkarma.com/smartkarma-soc-gen-new-login-request";
export const socGenPSRequest =
  "https://info.smartkarma.com/smartkarma-soc-gen-new-premium-service-request";
export const researchIdea = "/home/share-your-research-ideas/";
export const contactCSV = `${assetBucket}/v3/c-suite/contacts-upload-template.csv`;
export const investors = "/home/investor-solutions";
export const corporates = "/home/corporate-solutions";
export const insightProviders = "/home/research-provider-solutions";
export const ipCodeOfConduct =
  "/home/research-provider-solutions/smartkarma-insight-provider-code-of-conduct";
export const press = "/home/press-pass";
export const suitablePlan = "/home/pricing";
export const privacyPolicy = "/home/privacy-policy/";
export const termsOfUse = "/home/terms-of-use/";
export const sounds = `${assetBucket}/sounds`;
export const corporatePlan =
  "/home/corporate-solutions/choose-your-subscription-plan";
export const corporateEmail = "corporates@smartkarma.com";
export const salesEmail = "sales@smartkarma.com";
export const helpdeskEmail = "helpdesk@smartkarma.com";
export const plusEmail = "plus@smartkarma.com";
export const researchEmail = "research@smartkarma.com";
export const intercomEmail = "il4s4swc@incoming.intercom-mail.com";
export const iosApp = "https://itunes.apple.com/app/smartkarma/id1363869384";
export const androidApp =
  "https://play.google.com/store/apps/details?id=com.smartkarma.app";
export const socialLinks = {
  twitter: "https://twitter.com/smartkarma",
  facebook: "https://www.facebook.com/smartkarmahq/",
  linkedin: "https://sg.linkedin.com/company/smartkarma-innovations-pte-ltd",
  youtube: "https://www.youtube.com/channel/UCQmXoiLTif8GiamyRni2YkQ",
  rss: "https://feeds.smartkarma.com/feed.rss",
  github: "https://github.com/smartkarma",
};
export const corporateTerms =
  "https://info.smartkarma.com/smartkarma-corporate-solutions-access-terms";
export const clientTerms =
  "https://info.smartkarma.com/smartkarma-access-terms-for-online-subscription";
export const essentialTerms =
  "https://www.smartkarma.com/home/smartkarma-plus-access-terms";
export const ipApplicationForm =
  "https://info.smartkarma.com/insight-provider-application-form";
export const plusAccessTerms =
  "https://on.skr.ma/SmartkarmaPlusOnlineAccessTerms";
export const proAccessTerms =
  "https://www.smartkarma.com/home/smartkarma-pro-access-terms";
