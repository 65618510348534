const intervals = {
  activityNew: 3e4, // activity stays new for 30 seconds,
  autosaveInterval: 30000, // 30 seconds
  commentNew: 12e4, // comment stays new for 2 minutes
  entityQuoteRefresh: 9e5, // 15 minutes
  insightNew: 3e5, // insight stays new for 5 minutes
  liveQuoteRefresh: 5e3,
  markAsReadTime: 500,
  messageCollapse: 30, // combine messages within 30 minutes into one
  messageSentSuccessDisplay: 2000, // display success tick for 2 seconds
  newIntroducedAccount: 2000,
  newIPDays: 30, // 30 days
  oneSecond: 1000, // in ms
  oneMinute: 60 * 1000, // in ms
  oneHour: 60 * 60 * 1000, // in ms
  oneDay: 24 * 60 * 60 * 1000, // in ms
  rollingMonth: 3, // 3 months
  scrollingDebounceInterval: 50,
  trendingDays: 14, // 14 days
  trialEndDays: 14, // 14 days
  typingDebounce: 200,
};

export default intervals;
