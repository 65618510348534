import { twMerge } from "tailwind-merge";

const ErrorMessage = ({
  header,
  message,
}: {
  header?: string;
  message?: string;
}) => {
  return (
    <div className={twMerge("text-center", "mb-[15px]")}>
      <h1 className="text-shady-gray font-light text-[33px] my-[15px]">
        {header || "Oops…"}
      </h1>
      <div className="bg-white rounded-lg shadow-lg flex">
        <div className="w-[47px] rounded-l bg-bearish-red text-white text-[45px] flex items-center justify-center">
          <div>!</div>
        </div>
        <div className="p-[15px] w-[320px] text-left">
          {message || "Oops! Something went wrong. Please try again."}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
