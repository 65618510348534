import useSWR, { useSWRConfig } from "swr";

import { useCallback, useMemo } from "react";

import Bugsnag from "@bugsnag/js";

import useNotification from "components/Notification/useNotification";
import { baseFetcher, fetchApi } from "utils/baseFetcher";

import useAuthentication from "./useAuthentication";

export const watchlistsUrl = `/api/v2/watchlists?sort=name&include=entities,shared-items,my-shared-item,account`;

export const useWatchlists = () => {
  const { authenticated } = useAuthentication();

  const {
    data: watchlists,
    isValidating,
    isLoading,
  } = useSWR(authenticated ? watchlistsUrl : null, baseFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { mutate: globalMutate } = useSWRConfig();

  const mutate = useCallback(
    (data?, options?) => {
      globalMutate(watchlistsUrl, data, { revalidate: false, ...options });
    },
    [globalMutate]
  );

  return {
    watchlists,
    isValidating,
    isLoading,
    mutate,
  };
};

export const useEntityWatchlists = (entity: any) => {
  const {
    watchlists: allWatchlists,
    isValidating,
    isLoading,
  } = useWatchlists();

  const watchlists = useMemo(() => {
    return (
      allWatchlists?.filter((watchlist: any) =>
        watchlist.entities.some(
          (e: any) => e.id === entity.id || e.slug === entity.slug
        )
      ) || []
    );
  }, [allWatchlists, entity.id, entity.slug]);

  return {
    watchlists,
    isWatching: watchlists?.length > 0,
    isValidating,
    isLoading,
  };
};

export const useWatchlistInteractions = (watchlist?: any) => {
  const { setNotification } = useNotification();

  const { watchlists, mutate: mutateWatchlists } = useWatchlists();

  const removeSharedWatchlist = useCallback(async () => {
    const sharedItem = watchlist?.mySharedItem;
    if (!sharedItem) {
      console.log("shared item not available for ", watchlist?.id);
      return;
    }

    try {
      const response = await fetchApi(`/api/v2/shared-items/${sharedItem.id}`, {
        method: "DELETE",
      });
      if (response?.errors) {
        setNotification(
          response.errors?.[0]?.title || "Something went wrong!",
          "error"
        );
        return;
      }

      await mutateWatchlists(
        watchlists.filter((w: any) => w.id !== watchlist.id)
      );

      setNotification("Shared Watchlist Unfollowed!", "success");
    } catch (error: any) {
      Bugsnag.notify(error);
      setNotification("Something went wrong!", "error");
    }
  }, [
    mutateWatchlists,
    setNotification,
    watchlist?.id,
    watchlist?.mySharedItem,
    watchlists,
  ]);

  const deleteWatchlist = useCallback(async () => {
    if (!watchlist) return;

    try {
      const response = await fetchApi(`/api/v2/watchlists/${watchlist?.id}`, {
        method: "DELETE",
      });
      if (response?.errors) {
        setNotification(
          response.errors?.[0]?.title || "Something went wrong!",
          "error"
        );
        return;
      }

      await mutateWatchlists(
        watchlists.filter((w: any) => w.id !== watchlist.id)
      );

      setNotification("Watchlist deleted!", "success");
    } catch (error: any) {
      Bugsnag.notify(error);
      setNotification("Something went wrong!", "error");
    }
  }, [mutateWatchlists, setNotification, watchlist, watchlists]);

  const removeEntity = useCallback(
    async (entity: any) => {
      try {
        await fetchApi(`/api/v2/watchlists/${watchlist.id}/remove_entities`, {
          method: "PATCH",
          body: JSON.stringify({
            data: {
              id: watchlist.id,
              type: "watchlists",
              relationships: {
                entities: { data: [{ id: entity.id, type: "entities" }] },
              },
            },
          }),
        });
      } catch (error: any) {
        Bugsnag.notify(error);
      }
    },
    [watchlist?.id]
  );

  return {
    removeSharedWatchlist,
    deleteWatchlist,
    removeEntity,
  };
};
