import { parseJSON } from "date-fns";

import { NEXT_PUBLIC_PAYMENT_ANALYTICS_DISABLED_ID } from "utils/envVariables";
import { getExpertiesTags } from "utils/insight";
import { cannotMessageReasons } from "consts/cannot-message-reasons";
import { INSIGHT_PROVIDER_BADGES } from "consts/insight-provider-badges";

import type { Profile } from "./profile";

export type Account = Profile & {
  type: "accounts" | "companies";

  title: string;

  name?: string;
  address?: string; // WIP
  postalcode?: string; // WIP
  maskedEmail?: string;

  country: string;

  isClient: boolean;
  isInsightProvider: boolean;
  isPreviewPass: boolean;
  isIndividualPass: boolean;
  isSyndicator: boolean;
  isIssuerPaid: boolean;
  isInstaPass: boolean;
  isIntroducer: boolean;
  isOperationsOfficer: boolean;
  isMarketingOfficer: boolean;
  isComplianceOfficer: boolean;
  isCorporateOfficer: boolean;
  isPsManager: boolean;
  isTeamLeader: boolean;
  isCompanyLeader: boolean;

  geographyMandates: string[];
  countryMandates: string[];
  education: string[];
  sectorMandates: string[];
  skills: string[];
  workExperience: string[];

  activated: boolean;
  isDormant: boolean;
  hasChangedPassword: boolean;

  insightsMtdTotalConvictions: number;
  isContactable: boolean;
  isIndexable: boolean;

  restrictViewableContentVerticals: string[];

  clientAgreedAt: Date;
  joinedInAt: Date;
  lastActiveAt: Date;
  pressAccessAgreedAt: Date;
  trialEndDate: Date;
  termsAgreedAt: Date;

  accessEndAt: Date;

  accreditations: { [key: string]: any };
  communicationSetting: { [key: string]: any };
  interfaceSetting: { [key: string]: any };
  publicationSetting: { [key: string]: any };
  pushNotificationSetting: { [key: string]: any };

  maxMarketCap: number;
  minMarketCap: number;
  premiumServicesBucketRate: number;
  totalActivities: number;
  totalAlerts: number;
  totalFollowedInsightProviders: number;
  totalWatchedEntities: number;
  currentMonthRepublishedInsights: number;

  topAuthorIds?: Array<string | number>;
  topEntityIds?: Array<string | number>;
  topContentVerticalIds?: Array<string | number>;
  topSectors?: Array<string | number>;
  topCountries?: Array<string | number>;

  sourceType?: string;
  sourceId?: string | number;
  cfaExpiryAt?: Date;

  cannotMessageReason: string;
  clientType: string;
  companyName: string;
  customFooter: string;
  email: string;
  funnelStatus: string;
  gender: string;
  insightProviderType: string;
  insightProviderBadge: string;
  jurisdiction: string;
  landline: string;
  mobile: string;
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
  premiumServicesBucket: string;

  // attribute also used to store individual pass's related company
  previewPassRelatedCompany: any;
  previewPassInterest: string;
  shortName: string;
  timeZone: string;

  totalUnreadMessageGroups: number;
  totalUnreadNotifications: number;
  totalUnreadPsRequests: number;
  notificationsReadAt: Date;
  isMuted: boolean;

  unsubmittedInsightsCount: number;
  unsubmittedExternalReportsCount: number;
  underReviewInsightsCount: number;
  expiringInsightsCount: number;
  scheduledInsightsCount: number;
  scheduledExternalReportsCount: number;

  intercomHash: string;

  hasEmail: boolean;

  isFollowed: boolean;

  cocAccepted: boolean;

  canIndividualPassPostComments: boolean;
  twitterConnected: boolean;
  linkedinConnected: boolean;

  disclaimer?: string;

  mutedContents: any;

  accountLead?: Account;
  accountOriginator?: Account;
  company?: any;
  // when downgrading account from client to preview pass
  // backend will remove the company relationship
  // but preserve the relatedCompany
  relatedCompany?: any;
  introducer?: Account;
  introducerCompany?: Account;
  team?: any;

  plan?: any; // insta pass plan
  corporatePlan?: any;

  contentVerticals?: any[];
  subscribedVerticals?: any[];

  premiumServiceCatalogues?: any[];
  messageGroups?: any[];

  /* --- getters --- */

  titleText: string;
  headline: string;

  accountType?: string;
  accountCookieType?: string;

  insightProviderBadgeText?: string;

  isCompany: boolean;
  isClientComplianceOfficer: boolean;
  isTeamIntroducerLeader: boolean;
  isCompanyIntroducerLeader: boolean;
  isTeamIPLeader: boolean;
  isCompanyIPLeader: boolean;
  isSmartkarma: boolean;
  isTrialEnd: boolean;
  isPreviewOrIndividualPass: boolean;
  isClientOrIP: boolean;
  isIndependentIP: boolean;
  isInternalUser: boolean;
  isOfficer: boolean;
  isFreeCorporateOfficer: boolean;
  isBasicCorporateOfficer: boolean;
  isProCorporateOfficer: boolean;
  isProfessionalClient: boolean;
  isPressClient: boolean;
  isInvestorClient: boolean;
  isTrialClient: boolean;

  canComposeInsight: boolean;
  canComposeReport: boolean;
  canCompose: boolean;
  canViewComment: boolean;
  cannotViewCommentReason?: string;
  cannotPostCommentReason?: string;
  canFollow: boolean;
  canBeFollowed: boolean;
  canMessage: boolean;
  canUsePrivateMessages: boolean;
  hasPremiumServices: boolean;
  canPostComment: boolean;
  canPostComments: boolean;
  canPostCommentAnonymously: boolean;
  canViewEntityShareholders: boolean;
  canViewFullEntityData: boolean;
  canViewSmartSummary: boolean;
  canViewExecutivesAnalytics: boolean;
  canViewInsightThemes: boolean;
  canRequestInsightAccess: boolean;
  canViewQuiddityTools: boolean;
  canAccessPSCenter: boolean;
  canRequestPS: boolean;

  hadInsightsPublished: boolean;
  hadReportsPublished: boolean;

  showAnalysisTab: boolean;
  showStatisticsTab: boolean;
  showDiscussionsTab: boolean;
  showPremiumServicesTab: boolean;
  canSendMessage: boolean;

  canEmail: boolean;
  canSendEmail: boolean;

  corpOfficerCanViewDiscussions?: boolean;
  corpOfficerContactListsLimit?: number;
  corpOfficerContactablesLimit?: number;
  corpOfficerEmailsLimit?: number;
  corpOfficerMessagesLimit?: number;
  corpOfficerInvestorsLimit?: number;

  isOnTrial?: boolean;

  expertiesTags?: string[];

  isMandatesEmpty?: boolean;

  isExcludedFromSessionLock?: boolean;
  isSkResearch?: boolean;
  isBot?: boolean;
  canViewPeersComparison?: boolean;
  canViewPaymentAnalytics?: boolean;
};

export const transform = (raw: Account): Account => {
  return {
    ...raw,

    get titleText() {
      const title = this.title;
      if (title) {
        return title;
      } else if (this.isIndependentIP) {
        return "Independent Insight Provider";
      } else {
        return "";
      }
    },

    get headline() {
      return [this.titleText, this.companyName].filter(Boolean).join(", ");
    },

    get description() {
      return this.companyName || this.headline;
    },

    get accountType() {
      if (this.isPreviewPass) {
        return "Preview Pass";
      }
      if (this.isIndividualPass) {
        return "Individual Pass";
      }
      if (this.isCorporateOfficer) {
        return "Corporate Officer";
      }
      if (this.isOfficer) {
        return "Officer";
      }
      if (this.isClient && this.isInsightProvider) {
        return "Power User";
      }
      if (this.isClient) {
        return "Client";
      }
      if (this.isInsightProvider) {
        return "Insight Provider";
      }
    },

    get accountCookieType() {
      if (this.isPreviewPass) {
        return "preview-pass";
      }
      if (this.isIndividualPass) {
        return "individual-pass";
      }
      if (this.isCorporateOfficer) {
        return "corporate-officer";
      }
      if (this.isComplianceOfficer) {
        return "compliance-officer";
      }
      if (this.isIntroducer) {
        return "introducer";
      }
      if (this.isInstaPass) {
        return "insta-pass";
      }
      if (this.isInternalUser) {
        return "internal-user";
      }
      if (this.isClient) {
        return "client";
      }
      if (this.isInsightProvider) {
        if (this.isSyndicator) {
          return "syndicator-insight-provider";
        }
        if (this.isIssuerPaid) {
          return "issuer-paid-insight-provider";
        }
        return "insight-provider";
      }
      return "";
    },

    get isCompany(): boolean {
      return this.type === "companies";
    },

    get isSmartkarma(): boolean {
      return this.company?.id === "1";
    },

    get isPreviewOrIndividualPass(): boolean {
      return this.isPreviewPass || this.isIndividualPass;
    },

    get isClientOrIP(): boolean {
      return (
        this.isClient ||
        this.isInsightProvider ||
        this.isPreviewOrIndividualPass
      );
    },

    get isIndependentIP() {
      const onlyIpInCompany = this.company?.analystsCount === 1;
      return (!this.companyName || onlyIpInCompany) && this.isInsightProvider;
    },

    get isSyndicator() {
      return (
        this.isInsightProvider && this.insightProviderType === "syndicator"
      );
    },

    get title() {
      const { company } = raw;
      if (raw.title) {
        return raw.title;
      }

      const onlyIpInCompany = company?.analystsCount;

      const isIndependentIP =
        (!raw.companyName || onlyIpInCompany) && raw.isInsightProvider;

      if (isIndependentIP) return "Independent Insight Provider";

      return "";
    },

    get insightProviderBadgeText() {
      if (this.isInsightProvider) {
        return (
          INSIGHT_PROVIDER_BADGES.find(
            (b) => b.id === raw.insightProviderBadge
          ) || INSIGHT_PROVIDER_BADGES[0]
        ).name;
      }
    },

    get canComposeInsight(): boolean {
      return this.isInsightProvider && !this.isSyndicator && !this.isIssuerPaid;
    },
    get canComposeReport(): boolean {
      return this.isSyndicator || this.isIssuerPaid || this.isCorporateOfficer;
    },
    get canCompose(): boolean {
      return this.canComposeInsight || this.canComposeReport;
    },
    get canViewPaymentAnalytics(): boolean {
      return (
        this.canComposeInsight &&
        !NEXT_PUBLIC_PAYMENT_ANALYTICS_DISABLED_ID?.split(",").includes(this.id)
      );
    },
    get canViewElysium(): boolean {
      return this.isIntroducer;
    },
    get hadInsightsPublished(): boolean {
      return !!(this.canComposeInsight && this.publishedInsightsCount);
    },

    get hadReportsPublished(): boolean {
      return !!(this.canComposeReport && this.publishedExternalReportsCount);
    },

    get showAnalysisTab(): boolean {
      return this.hadInsightsPublished || this.hadReportsPublished;
    },

    get showStatisticsTab(): boolean {
      return this.showAnalysisTab;
    },

    get showDiscussionsTab(): boolean {
      return !((this.isIssuerPaid || this.isSyndicator) && !this.isCompany);
    },

    get showPremiumServicesTab(): boolean {
      return (this.premiumServiceCatalogues?.length || 0) > 0;
    },

    get canSendMessage(): boolean {
      if (
        this.isInstaPass ||
        this.isPreviewPass ||
        this.isSyndicator ||
        this.isIssuerPaid
      ) {
        return false;
      }
      if (this.isCorporateOfficer) {
        const limit = this.corpOfficerMessagesLimit || -1;
        const totalCount = this.messageGroups?.length || 0;
        if (limit === -1 || totalCount < limit) {
          return true;
        }
        return false;
      }

      return true;
    },

    get cannotSendMessageReason(): any {
      try {
        if (this.isInstaPass) {
          return cannotMessageReasons.find((r) => r.id === "sender-insta-pass");
        }
        if (this.isPreviewPass) {
          return cannotMessageReasons.find(
            (r) => r.id === "sender-preview-pass"
          );
        }
        if (this.isSyndicator) {
          return cannotMessageReasons.find(
            (r) => r.id === "sender-syndicator-ip"
          );
        }
        if (this.isIssuerPaid) {
          return cannotMessageReasons.find(
            (r) => r.id === "sender-issuer-paid-ip"
          );
        }
        if (this.isCorporateOfficer) {
          const limit = this.corpOfficerMessagesLimit || -1;
          const totalCount = this.messageGroups?.length || 0;
          if (limit === -1 || totalCount < limit) {
            return null;
          }
          return cannotMessageReasons.find((r) => r.id === "reach-limit");
        }
      } catch (ex: any) {
        return cannotMessageReasons.find((r) => r.id === "other");
      }
    },

    get canFollow(): boolean {
      return !raw.isCorporateOfficer;
    },

    get canBeFollowed(): boolean {
      return raw.activated && raw.isInsightProvider;
    },

    get canMessage(): boolean {
      return !raw.cannotMessageReason;
    },

    get canEmail(): boolean {
      return this.isInsightProvider && !!this.maskedEmail;
    },

    get canSendEmail(): boolean {
      return (
        raw.company?.disablePrivateMessages &&
        raw.company?.enableEmailCommunication
      );
    },

    get canUsePrivateMessages(): boolean {
      return !raw.company?.disablePrivateMessages && !raw.isPreviewPass;
    },

    get hasPremiumServices(): boolean {
      return this.showPremiumServicesTab;
    },

    get canViewComment(): boolean {
      if (
        !this.isClientOrIP &&
        !this.isComplianceOfficer &&
        !this.isCorporateOfficer &&
        !this.isIndividualPass
      ) {
        return false;
      }

      if (this.isPreviewPass) {
        return false;
      }

      if (this.isSyndicator) {
        return false;
      }

      if (this.isIssuerPaid) {
        return false;
      }

      if (this.company && this.company.disableViewingComments) {
        return false;
      }
      return true;
    },

    get cannotViewCommentReason(): string | undefined {
      if (
        !this.isClientOrIP &&
        !this.isComplianceOfficer &&
        !this.isCorporateOfficer &&
        !this.isIndividualPass
      ) {
        return "not-client-or-ip";
      }

      if (this.isPreviewPass) {
        return "preview-pass";
      }

      if (this.isSyndicator) {
        return "syndicator-ip";
      }

      if (this.isIssuerPaid) {
        return "issuer-paid-ip";
      }

      if (this.company && this.company.disableViewingComments) {
        return "company-disable-viewing-comments";
      }
    },

    get canPostComment(): boolean {
      if (!this.canViewComment) return false;

      if (this.isInstaPass) {
        return false;
      }

      if (this.isIndividualPass) {
        if (raw.canPostComments) {
          return true;
        }

        return false;
      }

      if (this.company && this.company?.disablePostingComments) {
        return false;
      }

      return true;
    },

    get cannotPostCommentReason(): string | undefined {
      if (!this.canViewComment) return;

      if (this.isInstaPass) {
        return "insta-pass";
      }

      if (this.isIndividualPass) {
        if (raw.canPostComments) {
          return;
        }

        return "individual-pass";
      }

      if (this.company && this.company?.disablePostingComments) {
        return "company-disable-posting-comments";
      }

      return;
    },

    get canPostCommentAnonymously(): boolean {
      return (
        this.canPostComment &&
        !this.isInsightProvider &&
        !this.isCorporateOfficer
      );
    },

    get canViewEntityShareholders(): boolean {
      return !this.isPreviewPass;
    },

    get canViewFullEntityData(): boolean {
      return !this.isPreviewPass;
    },

    get canViewSmartSummary(): boolean {
      return (
        this.isInternalUser ||
        this.isIndividualPass ||
        this.isInsightProvider ||
        this.isProfessionalClient ||
        this.isTrialClient ||
        this.isCorporateOfficer
      );
    },

    get canViewExecutivesAnalytics(): boolean {
      return (
        this.isInternalUser ||
        this.isCorporateOfficer ||
        this.isIntroducer ||
        this.isIndividualPass ||
        this.isInsightProvider ||
        this.isProfessionalClient ||
        this.isTrialClient
      );
    },

    get canViewInsightThemes(): boolean {
      return (
        this.isInternalUser ||
        this.isIndividualPass ||
        this.isInsightProvider ||
        this.isProfessionalClient ||
        this.isTrialClient
      );
    },

    get canRequestInsightAccess(): boolean {
      return this.isClient;
    },

    get isInternalUser(): boolean {
      return (
        this.insightProviderType === "internal" ||
        this.clientType === "internal"
      );
    },

    get corpOfficerCanViewDiscussions() {
      return this.corporatePlan?.settings?.can_view_discussions;
    },

    get corpOfficerContactListsLimit() {
      return this.corporatePlan?.settings?.contact_lists_limit;
    },

    get corpOfficerContactablesLimit() {
      return this.corporatePlan?.settings?.contactables_limit;
    },

    get corpOfficerEmailsLimit() {
      return this.corporatePlan?.settings?.monthly_email_broadcast_limit;
    },

    get corpOfficerMessagesLimit() {
      return this.corporatePlan?.settings?.monthly_message_sending_limit;
    },

    get corpOfficerInvestorsLimit() {
      return this.corporatePlan?.settings?.target_investors_limit;
    },

    get isOnTrial() {
      return (
        (this.isClient && this.clientType === "trial") ||
        (this.isInsightProvider && this.insightProviderType === "trial")
      );
    },

    get isInstaPass() {
      return this.plan?.isInstaPassPlan;
    },

    get accessEndAt() {
      return typeof window === "undefined"
        ? raw.accessEndAt
        : raw.accessEndAt && parseJSON(raw.accessEndAt);
    },

    get trialEndDate() {
      return typeof window === "undefined"
        ? raw.trialEndDate
        : raw.trialEndDate && parseJSON(raw.trialEndDate);
    },

    get isOfficer() {
      return (
        this.isComplianceOfficer ||
        this.isOperationsOfficer ||
        this.isMarketingOfficer ||
        this.isCorporateOfficer
      );
    },

    get expertiesTags() {
      if (raw.areasOfExpertise) return getExpertiesTags(raw.areasOfExpertise);
    },

    get isFreeCorporateOfficer() {
      return (
        this.isCorporateOfficer &&
        this.company?.corporatePlan?.name === "Starter"
      );
    },
    get isBasicCorporateOfficer() {
      return (
        this.isCorporateOfficer && this.company?.corporatePlan?.name === "Basic"
      );
    },
    get isProCorporateOfficer() {
      return (
        this.isCorporateOfficer && this.company?.corporatePlan?.name === "Pro"
      );
    },

    get isProfessionalClient() {
      return this.isClient && this.clientType === "professional";
    },

    get isPressClient() {
      return this.isClient && this.clientType === "press";
    },

    get isInvestorClient() {
      return this.isClient && this.clientType === "investor";
    },

    get isTrialClient() {
      return this.isClient && this.clientType === "trial";
    },

    get isMandatesEmpty() {
      return (
        !this.geographyMandates?.length &&
        !this.countryMandates?.length &&
        !this.sectorMandates?.length &&
        !this.maxMarketCap &&
        !this.minMarketCap
      );
    },

    get isExcludedFromSessionLock() {
      return [
        "12978",
        12978,
        "13392",
        13392,
        "25349",
        25349,
        "28979",
        28979,
        "13226",
        13226,
        "49542",
        49542,
      ].includes(this.id);
    },

    get isSkResearch() {
      return raw.id === "3960" || (raw.id as any) === 3960;
    },

    get isBot() {
      return (
        raw.id === "44" ||
        (raw.id as any) === 44 ||
        raw.id === "46677" ||
        (raw.id as any) === 46677
      );
    },

    get canViewQuiddityTools() {
      return (
        this.isSmartkarma ||
        this.isProfessionalClient ||
        this.isIntroducer ||
        this.isCorporateOfficer ||
        this.isTrialClient ||
        this.isIndividualPass ||
        ["374", 374].includes(this.company?.id)
      );
    },

    get canAccessPSCenter() {
      return (
        this.isInsightProvider &&
        ["external", "trial"].includes(this.insightProviderType)
      );
    },

    get canRequestPS() {
      return (
        this.isClient &&
        !this.isPressClient &&
        !this.isPsManager &&
        this.company?.enablePsRequests
      );
    },

    get canViewPeersComparison() {
      return (
        this.isInsightProvider ||
        this.isClient ||
        this.isCorporateOfficer ||
        this.isIndividualPass
      );
    },
  };
};
