import addHours from "date-fns/addHours";
import isBefore from "date-fns/isBefore";
import parseJSON from "date-fns/parseJSON";
import capitalize from "lodash/capitalize";

import { emberAppBaseUrl } from "utils/envVariables";
import htmlText from "utils/htmlToText";
import objectToQueryString from "utils/objectToQueryString";
import replaceQuotes from "utils/replaceQuotes";
import { abbrs } from "consts/abbrs";

import { Account } from "./account";
import { BaseModel } from "./base";

export type Insight = BaseModel & {
  // Basic
  tagline: string;
  slug: string;
  aasmState: string;
  aasmStateUpdatedAt: Date;
  executiveSummary: string;
  detail: string;
  formatType: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  republishedAt: Date;
  boomerangReason: string;
  coverImageUrl: string;
  searchHighlight: string; // only in search endpoint
  isRead: boolean;
  timeToRead: number;

  // Meta data, read-only
  totalAccountRead: number;
  totalPublicRead: number;
  totalCombinedRead: number; // totalAccountRead + totalPublicRead
  viewerIsRestricted: boolean;
  restrictionReason: string;
  isEmbargoed: boolean;
  isAlwaysEmbargoed: boolean;
  embargoEndsAt: Date;
  shareableLinks: any;

  // Classification
  imperative: string;
  timeHorizon: string;
  automaticKeywords: string[];
  customKeywords: string[];
  strategies: string[];
  countries: string[];
  gics: { [key: string]: string };

  // Relationships
  account: Account;
  company: any;
  primaryEntity: any;
  secondaryEntities: any[];
  lockerItems: any[];
  smartSummary?: {
    bullishPoints?: string[];
    bearishPoints?: string[];
    tableOfContents?: string[];
    suggestedQuestions?: string[];
    catalysts?: string[];
    relatedThemes?: string[];
    keySummaryPoints?: string[];
    rawResponse?: string;
  };

  // Getters
  publicationType?: string;
  author?: Account;
  authorTitle?: string;
  entity?: any;

  [key: string]: any;
};

export const transform = (raw: Insight): Insight => {
  return {
    ...raw,

    get isPublished() {
      return this.aasmState === "published";
    },

    get isUnderReview() {
      return this.aasmState === "under_review";
    },

    get isScheduled() {
      return this.aasmState === "scheduled";
    },

    get isIssuerPaid() {
      return raw.isIssuerpaid;
    },

    get publicationType() {
      if (this.isBuyside) {
        return "Buy-side";
      } else if (this.isExchangeSponsored) {
        return "Exchange-sponsored";
      } else if (this.isBroker) {
        return "Broker";
      } else if (this.isIssuerpaid) {
        return "Issuer-paid";
      } else if (this.isSyndicated) {
        return "Syndicated";
      } else if (this.isPodcast) {
        return "Podcast";
      }
    },

    get publicationTypeMuteText() {
      if (this.isBuyside) {
        return "Buy-side reports";
      } else if (this.isExchangeSponsored) {
        return "Exchange-sponsored reports";
      } else if (this.isBroker) {
        return "Broker reports";
      } else if (this.isIssuerpaid) {
        return "Issuer-paid reports";
      } else if (this.isSyndicated) {
        return "Syndicated reports";
      } else if (this.isPodcast) {
        return "Podcasts";
      }
    },

    get author() {
      const { company, account } = raw;
      if (!company && !account) return;

      const isCompany = company?.publishUnderCompanyName;
      return isCompany ? company : account;
    },

    get authorName() {
      return this.author?.name;
    },

    get authorTitle() {
      const { company } = raw;

      if (!company) return;

      if (this.author?.title) {
        return this.author.title;
      }

      const onlyIpInCompany = company?.analystsCount;

      const isIndependentIP =
        (!this.author?.companyName || onlyIpInCompany) &&
        this.author?.isInsightProvider;

      if (isIndependentIP) return "Independent Insight Provider";

      return "";
    },

    get entity() {
      return raw.primaryEntity || raw.secondaryEntities?.[0];
    },

    get publishUnderCompanyName() {
      return this.company?.publishUnderCompanyName;
    },

    get timestamp() {
      return this.publishedAt || this.updatedAt;
    },

    get executiveSummaryPlain() {
      return htmlText(this.executiveSummary);
    },

    get text() {
      return this.tagline;
    },

    // Add zero-width space before ‘/’ to break it
    get taglineFormatted() {
      const tagline = this.tagline || "";
      return replaceQuotes(tagline).replace(/(\/)/gi, "$1\u200B");
    },

    get stateText() {
      if (this.isPublished) {
        return "Published";
      } else if (this.isUnderReview) {
        return "Under Review";
      } else if (this.isScheduled) {
        return "Scheduled";
      } else {
        return "Draft";
      }
    },

    get isQuickNote() {
      return this.formatType === "quick-note";
    },
    get isInDepth() {
      return this.formatType === "in-depth";
    },

    get jurisdictions() {
      return this.author?.jurisdictions;
    },

    get isRepublished() {
      return !!this.republishedAt;
    },

    get canonicalUrl() {
      return emberAppBaseUrl + "/insights/" + this.slug;
    },

    get showEmbargoCountdown() {
      const publishedMoreThanAnHour = isBefore(
        parseJSON(this.publishedAt),
        addHours(new Date(), -1)
      );
      return (
        !this.isAlwaysEmbargoed &&
        !!this.embargoEndsAt &&
        publishedMoreThanAnHour
      );
    },

    // sk-common

    get description() {
      const ticker = this.isTopDown
        ? this.topDownTicker
        : this.primaryEntity?.text;
      return [ticker, this.contentVertical?.name].filter(Boolean).join(" / ");
    },

    get sectors() {
      return this.gics?.sectors;
    },
    get industryGroups() {
      return this.gics?.industryGroups;
    },
    get industries() {
      return this.gics?.industries;
    },
    get noSector() {
      return !this.sectors?.length;
    },

    get geography() {
      if (!this.isTopDown) {
        return;
      }
      // const countriesString = this.countries?.slice().sort().join(',');
      // const matchedGeography = get(this, 'geographies').find((geography) => {
      //   return countriesString === get(geography, 'countriesString');
      // });
      // return matchedGeography && get(matchedGeography, 'text');
      return this.geographies?.[this.geographies?.length - 1];
    },
    get country() {
      return this.countries?.[0];
    },
    get sector() {
      return this.sectors?.[0];
    },
    get industryGroup() {
      return this.industryGroups?.[0];
    },
    get industry() {
      return this.industries?.[0];
    },
    get strategy() {
      return this.strategies?.[0];
    },

    get isTopDown() {
      return this.contentVertical?.isTopDown;
    },
    get isMacro() {
      return this.contentVertical?.isMacro;
    },
    get isThematic() {
      return this.contentVertical?.isThematic;
    },

    get topDownTicker() {
      if (!this.gics) return;

      let ticker;
      // external report doesn't have countries, gics
      if (this.isReport) {
        return "";
      }
      if (this.isMacro) {
        if (this.countries?.length <= 2) {
          ticker = this.countries?.map(capitalize).join(" & ").trim();
        } else {
          ticker = this.geography || "Multiple Countries";
        }
      } else if (this.isThematic) {
        let gics;
        let countries;
        const noSector = this.noSector;
        if (!noSector) {
          gics = [this.sector, this.industryGroup, this.industry]
            .filter(Boolean)
            .pop();
          if (abbrs[gics]) {
            gics = abbrs[gics];
          }
        }
        if (!gics && this.countries.length <= 2) {
          countries = this.countries.map(capitalize).join(" & ").trim();
        } else {
          countries = this.country || this.geography;
        }
        ticker = [countries, gics]
          .filter(Boolean)
          .map(capitalize)
          .join(" ")
          .trim();

        if (!ticker) {
          if (noSector) {
            ticker = "Multiple Countries";
          } else {
            ticker = "Multiple Countries & Sectors";
          }
        }
      }
      if (!ticker) {
        ticker = this.contentVertical?.name;
      }
      return ticker;
    },
    get topDownURL() {
      const { country, sector } = this;
      const filterSet = [];
      let filters;

      if (country) {
        filterSet.push(`countries:${country}`);
      }

      if (sector) {
        filterSet.push(`sectors:${sector}`);
      }

      if (filterSet.length) {
        filters = filterSet.join("|");
      } else {
        filters = `verticals:${this.contentVertical?.id}`;
      }

      const query = {
        reset: "filters",
        collection: "all-insights",
        filters,
      };

      return `/insights?${objectToQueryString(query)}`;
    },

    get hasDistributionRestriction() {
      return this.author?.hasDistributionRestriction;
    },
    get distributionRestrictionDisclaimer() {
      return this.author?.disclaimer;
    },

    get isViewerRestricted() {
      const reason = this.restrictionReason;
      const isRestricted =
        this.viewerIsRestricted &&
        reason !== "insta_pass_preview" &&
        reason !== "insta_pass_limit";
      return isRestricted;
    },
  };
};

export const LIST_FIELDS = [
  "content-vertical",
  "primary-entity",
  "account",
  "company",
  "locker-items",
  "latest-comment-thread",
  "smart-summary",

  "boomerang-reason",
  "byline",
  "conviction",
  "executive-summary",
  "imperative",
  "imperative",
  "is-boost",
  "is-broker",
  "is-buyside",
  "is-commissioned",
  "is-embargoed",
  "is-exchange-sponsored",
  "is-issuerpaid",
  "is-read",
  "is-skpi",
  "is-syndicated",
  "is-podcast",
  "my-like-score",
  "published-at",
  "republished-at",
  "shareable-links",
  "slug",
  "tagline",
  "tagline",
  "total-combined-read",
  "total-discussions",
];
