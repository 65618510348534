export const cannotMessageReasons = [
  {
    id: "receiver-deactivated",
    isHidden: true,
  },
  {
    id: "receiver-myself",
    isHidden: true,
  },
  {
    id: "receiver-company-disable-messaging",
  },
  {
    id: "receiver-issuer-paid-ip",
  },
  {
    id: "receiver-syndicator-ip",
  },
  {
    id: "my-company-disable-messaging",
  },
  // above are possible values from backend
  // below are frontend use only
  {
    id: "other",
  },
  {
    id: "receiver-company",
    isHidden: true,
  },
  {
    id: "receiver-investor",
    isHidden: true,
  },
  {
    id: "receiver-preview-pass",
    isHidden: true,
  },
  {
    id: "sender-individual-pass-receiver-client",
  },
  {
    id: "sender-individual-pass-receiver-ip",
  },
  {
    id: "sender-insta-pass",
  },
  {
    id: "sender-preview-pass",
  },
  {
    id: "sender-syndicator-ip",
  },
  {
    id: "sender-issuer-paid-ip",
  },
  {
    id: "reach-limit",
  },
];
