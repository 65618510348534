import dynamic from "next/dynamic";

import { useState } from "react";

import { NotificationContext, NotificationType } from "./NotificationContext";

const NotificationContainer = dynamic(() => import("./NotificationContainer"));

const DEFAULT_TIMEOUT = 5000;

export function NotificationContextProvider(props: any) {
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    timeout: DEFAULT_TIMEOUT,
  });

  const context = {
    notification,
    setNotification: (
      message: string,
      type: NotificationType,
      timeout?: number
    ) => {
      setNotification({ message, type, timeout: timeout || DEFAULT_TIMEOUT });
    },
    removeNotification: () =>
      setNotification({ message: "", type: "", timeout: DEFAULT_TIMEOUT }),
  };

  return (
    <NotificationContext.Provider value={context}>
      {props.children}
      <NotificationContainer />
    </NotificationContext.Provider>
  );
}
