import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { mediaQueries } from "consts/media-query";

export const UserAgentContext = createContext({
  userAgent: "",
});

export default function useMediaQuery() {
  const { userAgent } = useContext(UserAgentContext);

  const [queriesToMedia, stateObj] = useMemo(() => {
    const queriesToMedia: any = {};
    const stateObj: any = {};

    if (typeof window === "undefined") {
      const UAParser = require("ua-parser-js");

      for (let media in mediaQueries) {
        queriesToMedia[mediaQueries[media]] = media;
        stateObj[media] = false;
      }

      try {
        const deviceType = new UAParser(userAgent).getDevice().type;
        stateObj.mobile = deviceType === "mobile";
        stateObj.handheld = deviceType === "tablet";
      } catch {}
    } else {
      for (let media in mediaQueries) {
        queriesToMedia[mediaQueries[media]] = media;
        stateObj[media] = window.matchMedia(mediaQueries[media]).matches;
      }
    }

    return [queriesToMedia, stateObj];
  }, [userAgent]);

  const [media, updateMedia] = useState(stateObj);

  useEffect(() => {
    const mediaArray: any[] = [];
    const handleMediaQuery = (e: any) => {
      let media: string = queriesToMedia[e.media];

      if (media) {
        updateMedia((oMedia: any) => {
          oMedia[media] = e.matches;
          return { ...oMedia };
        });
      }
    };

    for (let query in queriesToMedia) {
      const matchMedia = window.matchMedia(query);
      handleMediaQuery(matchMedia);
      matchMedia.onchange = handleMediaQuery;

      mediaArray.push(matchMedia);
    }

    const unload = () => {
      for (let matchMedia of mediaArray) {
        matchMedia.onchange = undefined;
      }
    };

    return unload;
  }, [queriesToMedia]);

  return media;
}
