import { createContext } from "react";

export type NotificationType = "error" | "success" | "warn" | "info";

export const NotificationContext = createContext({
  notification: { message: "", type: "", timeout: 5000 },
  setNotification: (
    _message: string,
    _type: NotificationType,
    _timeout?: number
  ) => {},
  removeNotification: () => {},
});
