import { useRouter } from "next/router";

import { useEffect } from "react";

import i18n from "..";

const I18nContainer = () => {
  const router = useRouter();
  const lang = (router.query.lang || "en") as string;

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return null;
};

export default I18nContainer;
