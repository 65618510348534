const config = {
  xs: 400,
  // => @media (min-width: 400px) { ... }

  sm: 640,
  // => @media (min-width: 640px) { ... }

  md: 768,
  // => @media (min-width: 768px) { ... }

  lg: 1024,
  // => @media (min-width: 1024px) { ... }

  xl: 1680,
  // => @media (min-width: 1280px) { ... }
};

export const mediaQueries: any = {
  tiny: `screen and (max-width: ${config.xs - 1}px)`,
  phablet: `screen and (min-width: ${config.xs}px) and (max-width: ${
    config.md - 1
  }px)`,
  mobile: `screen and (max-width: ${config.md - 1}px)`, // tiny + phablet
  tablet: `screen and (min-width: ${config.md}px) and (max-width: ${
    config.lg - 1
  }px)`,
  handheld: `screen and (max-width: ${config.lg - 1}px)`, // mobile + tablet
  desktop: `screen and (min-width: ${config.lg}px) and (max-width: ${
    config.xl - 1
  }px)`,
  jumbo: `screen and (min-width: ${config.xl}px)`,
  portrait: `(orientation: portrait)`,
  landscape: `(orientation: landscape)`,
};
