import { DependencyList, useCallback, useEffect, useRef } from "react";

declare const UNDEFINED_VOID_ONLY: unique symbol;

type Destructor = () => void | { [UNDEFINED_VOID_ONLY]: never };

export function useEffectOnce(
  effect: (_acknowledge: () => void) => void | Promise<void> | Destructor,
  deps?: DependencyList
) {
  const executed = useRef(false);

  const acknowledge = useCallback(() => {
    executed.current = true;
  }, []);

  useEffect(() => {
    if (!executed.current) {
      effect(acknowledge);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
