import shortSecurity from "utils/shortSecurity";

export type Entity = {
  [key: string]: any;
};

export const transform = (raw: any): Entity => {
  return {
    ...raw,

    get text() {
      return this.prettyName;
    },
    get fulltext() {
      const name = this.prettyName;
      const security = this.shortSecurity;
      if (security) {
        return `${name} (${security})`;
      }
      return name;
    },

    get shortSecurity() {
      if (raw.security) return shortSecurity(raw.security);
    },

    get showPriceQuote() {
      return (
        !this.security?.toLowerCase()?.endsWith(" index") &&
        !this.prettyName?.toLowerCase()?.includes(" ftse") &&
        !this.prettyName?.toLowerCase()?.includes(" msci")
      );
    },
    get showPriceChart() {
      return this.showPriceQuote;
    },
  };
};
