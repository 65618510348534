import { useCallback, useContext } from "react";

import { ModalType } from ".";

import { SignInSignUpContext } from "./SignInSignUpContext";

const useSignInSignUp = () => {
  const { showModal, setShowModal } = useContext(SignInSignUpContext);

  const toggleModal = useCallback(
    (type: ModalType) => {
      if (!!showModal) {
        setShowModal(undefined);
      } else {
        setShowModal(type);
      }
    },
    [setShowModal, showModal]
  );

  return {
    toggleModal,
  };
};

export default useSignInSignUp;
