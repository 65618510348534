import { initReactI18next } from "react-i18next";

import i18n from "i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {},
  },
  ja: {
    translation: {
      "EXECUTIVE SUMMARY": "エグゼクティブサマリー",
      SUMMARY: "サマリー",
      "INSIGHT STREAM": "インサイトストリーム",
      "Insight Stream": "インサイトストリーム",
      "Sign Up to Access": "アクセスするにはサインアップしてください",
      "Related Entities": "関連事業体",
      "Price Chart": "価格チャート",
      Discussions: "議論",
      "Paid Plans Only": "有料プランのみ",
      "Your subscription enables you to engage in discussions with our network of independent analysts, investor relations professionals, and investors.":
        "ご登録いただくと、当社の独立アナリスト、投資家向け広報担当者、投資家のネットワークとのディスカッションに参加できるようになります。",
      "Subscribe to read our full archive of Insights produced by our network of independent analysts. Select the right plan for you.":
        "弊社の独立アナリスト ネットワークが作成した Insights の完全なアーカイブを読むには、購読してください。適切なプランを選択してください。",
      "See all plans": "すべてのプランを見る",
      "Try Now": "今すぐ試す",
      "Begin exploring Smartkarma's AI-augmented investing intelligence platform with a complimentary Preview Pass to:":
        "無料のプレビュー パスを利用して、Smartkarma の AI 強化投資インテリジェンス プラットフォームを探索してみましょう。",
      "Join 55,000+ investors, including top global asset managers overseeing $13+ trillion.":
        "13 兆ドルを超える資産を管理する世界トップクラスの資産運用会社を含む 55,000 人以上の投資家に加わりましょう。",
      "Upgrade later to our paid plans for full-access.":
        "フルアクセスをご希望の場合は、後で有料プランにアップグレードしてください。",
      "Unlock research summaries": "研究概要のロックを解除",
      "Follow top, independent analysts": "トップの独立アナリストをフォロー",
      "Receive personalised alerts": "パーソナライズされたアラートを受信する",
      "Access Analytics, Events and more": "分析、イベントなどにアクセス",
      Detail: "詳細",
      "Full Insight": "完全な洞察",
      "What is covered in the Full Insight": "フルインサイトの内容",
      "Related Insights": "関連する洞察",
      "Trending Collections": "トレンドコレクション",
      "Trending Insights": "トレンドの洞察",
      "Top Unpaywalled Insights": "ペイウォールのないトップの洞察",
      "minute read": "分読み",
    },
  },
  ko: {
    translation: {
      "EXECUTIVE SUMMARY": "요약",
      SUMMARY: "요약",
      "INSIGHT STREAM": "인사이트 스트림",
      "Insight Stream": "인사이트 스트림",
      "Sign Up to Access": "접속하기 위해 가입하세요",
      "Related Entities": "관련 기관",
      "Price Chart": "가격 차트",
      Discussions: "토론",
      "Paid Plans Only": "유료 플랜만",
      "Your subscription enables you to engage in discussions with our network of independent analysts, investor relations professionals, and investors.":
        "귀하의 구독을 통해 독립 분석가, 투자자 관계 전문가, 투자자로 구성된 네트워크와 논의에 참여하실 수 있습니다.",
      "Subscribe to read our full archive of Insights produced by our network of independent analysts. Select the right plan for you.":
        "독립적인 분석가 네트워크에서 제작한 통찰력의 전체 보관소를 읽으려면 구독하세요. 본인에게 맞는 플랜을 선택하세요.",
      "See all plans": "모든 계획 보기",
      "Try Now": "지금 시도해보세요",
      "Begin exploring Smartkarma's AI-augmented investing intelligence platform with a complimentary Preview Pass to:":
        "무료 미리보기 패스로 Smartkarma의 AI 증강 투자 인텔리전스 플랫폼을 탐색해보세요.",
      "Join 55,000+ investors, including top global asset managers overseeing $13+ trillion.":
        "13조 달러 이상을 감독하는 세계 최고의 자산 관리자를 포함하여 55,000명 이상의 투자자와 함께하세요.",
      "Upgrade later to our paid plans for full-access.":
        "나중에 모든 기능에 액세스하려면 유료 플랜으로 업그레이드하세요.",
      "Unlock research summaries": "연구 요약 잠금 해제",
      "Follow top, independent analysts": "최고의 독립 분석가를 팔로우하세요",
      "Receive personalised alerts": "개인화된 알림 수신",
      "Access Analytics, Events and more": "분석, 이벤트 등에 액세스",
      Detail: "세부 사항",
      "Full Insight": "전체 통찰력",
      "What is covered in the Full Insight": "전체 통찰력에서 다루는 내용",
      "Related Insights": "관련 통찰력",
      "Trending Collections": "인기 컬렉션",
      "Trending Insights": "트렌드 통찰력",
      "Top Unpaywalled Insights": "최고의 유료화되지 않은 통찰력",
      "minute read": "분 읽기",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
