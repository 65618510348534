export const schema = {
  id: "id",
  afterDeserialize: (data: any) => {
    return {
      type: "messages",
      ...(typeof window !== "undefined" ? transform(data) : data),
    };
  },
  relationships: {
    messageGroup: {
      type: "message-groups",
    },
    sender: {
      type: "accounts",
    },
  },
};

export const transform = (raw: any) => {
  return {
    ...raw,
  };
};
