import { createContext, useContext, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

import type { Stripe } from "@stripe/stripe-js/types/stripe-js";

export const StripeContext = createContext<{
  stripe: Stripe | null;
  setStripe: Dispatch<SetStateAction<Stripe | null>>;
}>({
  stripe: null,
  setStripe: () => {},
});

type StripeContextProviderProps = {
  children: React.ReactNode;
};

export const StripeContextProvider = ({
  children,
}: StripeContextProviderProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  return (
    <StripeContext.Provider value={{ stripe, setStripe }}>
      {children}
    </StripeContext.Provider>
  );
};

export default function useStripe() {
  return useContext(StripeContext);
}
