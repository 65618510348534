import React, { useCallback, useEffect, useMemo, useState } from "react";
import OtpInput from "react-otp-input";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import "react-phone-number-input/style.css";
import addDays from "date-fns/addDays";
import addMinutes from "date-fns/addMinutes";
import sub from "date-fns/fp/sub";
import isAfter from "date-fns/isAfter";
import parseJSON from "date-fns/parseJSON";
import { twMerge } from "tailwind-merge";

import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowCircleRight";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

import { REVERIFY_DAYS } from "components/AccountMFA";
import Countdown from "components/Countdown";
import FontAwesomeIcon from "components/FontAwesomeIcon";
import Modal from "components/Modal";
import useNotification from "components/Notification/useNotification";
import useAuthentication from "hooks/useAuthentication";
import { useEffectOnce } from "hooks/useEffectOnce";
import useMediaQuery from "hooks/useMediaQuery";
import { fetchApi } from "utils/baseFetcher";
import { privacyPolicy, termsOfUse } from "consts/urls";

import { CountrySelectWithIcon } from "./CountrySelect";

export const MAX_REMINDERS = 3;
export const RESEND_DURATION = 1; // minute
export const OTP_LENGTH = 6;

const AccountMFAModal = ({
  onClose,
  phoneNumber,
}: {
  onClose?: () => void;
  phoneNumber?: any;
}) => {
  const { mobile } = useMediaQuery();

  const { interfaceSetting, updateInterfaceSetting } = useAuthentication();

  const { setNotification } = useNotification();

  const mfaReminderCount = interfaceSetting?.mfaReminderCount || 0;
  const mfaReminderLeft = MAX_REMINDERS - mfaReminderCount;
  const canRemind = mfaReminderLeft > 0;

  const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumber?.digits);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPhoneNumberId, setNewPhoneNumberId] = useState(phoneNumber?.id);
  const [resendTime, setResendTime] = useState(
    addMinutes(new Date(), RESEND_DURATION)
  );
  const [sending, setSending] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [canResend, setCanResend] = useState(!!phoneNumber?.verifiedAt);

  const [skipVerification, setSkipVerification] = useState(false);
  const [isContactable, setIsContactable] = useState(true);
  const [shouldHideContactable, setShouldHideContactable] = useState(true);

  const canSend = useMemo(() => {
    return !!newPhoneNumber?.length && isPossiblePhoneNumber(newPhoneNumber);
  }, [newPhoneNumber]);

  const canVerify = useMemo(() => {
    return verificationCode?.length === 6;
  }, [verificationCode?.length]);

  const setReminder = useCallback(() => {
    if (!canRemind) return;

    updateInterfaceSetting({
      mfaReminderCount: mfaReminderCount + 1,
      mfaReminderTime: new Date().toISOString(),
    });

    onClose?.();
  }, [canRemind, mfaReminderCount, onClose, updateInterfaceSetting]);

  const sendOTP = useCallback(
    async (phoneNumber?: string, successCallback?: () => void) => {
      if (!canSend) return;
      if (sending) return;

      setSending(true);
      try {
        const newPhoneNumberRes = await fetchApi(`/api/v2/phone-numbers`, {
          method: "POST",
          body: JSON.stringify({
            data: {
              type: "phone-numbers",
              attributes: {
                digits: phoneNumber || newPhoneNumber,
                "is-contactable": isContactable,
              },
            },
          }),
        });
        console.log(newPhoneNumberRes);
        if (newPhoneNumberRes.errors) {
          const error = newPhoneNumberRes.errors?.find((e: any) =>
            e?.title?.startsWith("Please wait ")
          );
          if (error) {
            setNotification(error.title, "error");
            try {
              const matches = error?.title?.match(/Please wait (\d+):(\d+)/);
              const minutes = -parseInt(matches[1]);
              const seconds = -parseInt(matches[2]);
              setResendTime(sub({ minutes, seconds }, new Date()));
            } catch {}
          } else {
            setNotification("Something went wrong!", "error");
          }
        } else if (newPhoneNumberRes.skipVerification) {
          setSkipVerification(true);
        } else {
          setNewPhoneNumberId(newPhoneNumberRes?.id);
          setResendTime(addMinutes(new Date(), RESEND_DURATION));
          setCanResend(false);
        }
        successCallback?.();
      } catch (error: any) {
        setNotification("Something went wrong!", "error");
      } finally {
        setSending(false);
      }
    },
    [canSend, isContactable, newPhoneNumber, sending, setNotification]
  );

  const resendOTP = useCallback(async () => {
    if (!canResend) return;
    if (sending) return;

    setSending(true);
    try {
      const response = await fetchApi(
        `/api/v2/phone-numbers/${newPhoneNumberId}/resend-otp`,
        {
          method: "POST",
        }
      );
      if (response.errors) {
        const error = response.errors?.find((e: any) =>
          e?.title?.startsWith("Please wait ")
        );
        console.log(response, error);
        if (error) {
          setNotification(error.title, "error");
        } else {
          setNotification("Something went wrong!", "error");
        }
      } else {
        setResendTime(addMinutes(new Date(), RESEND_DURATION));
        setCanResend(false);
      }
    } catch {
    } finally {
      setSending(false);
    }
  }, [canResend, newPhoneNumberId, sending, setNotification]);

  const verifyOTP = useCallback(async () => {
    if (!canVerify) return;
    if (verifying) return;

    setVerifying(true);
    try {
      const response = await fetchApi(
        `/api/v2/phone-numbers/${newPhoneNumberId}/verify`,
        {
          method: "PATCH",
          body: JSON.stringify({
            data: {
              attributes: {
                ["verification-code"]: verificationCode,
              },
            },
          }),
        }
      );
      if (response.errors) {
        if (canResend) {
          setNotification("OTP is expired!", "error");
        } else {
          setNotification("Wrong OTP entered", "error");
          setVerificationCode("");
        }
      } else {
        setNotification("Phone number verified successfully!", "success");
        updateInterfaceSetting({
          mfaReminderCount: 3,
          mfaReminderTime: null,
        });
        onClose?.();
      }
    } catch {
      setNotification("Wrong OTP entered", "error");
      setVerificationCode("");
    } finally {
      setVerifying(false);
      setVerificationSent(true);
    }
  }, [
    canResend,
    canVerify,
    newPhoneNumberId,
    onClose,
    setNotification,
    updateInterfaceSetting,
    verificationCode,
    verifying,
  ]);

  const onOTPChange = useCallback((otp) => {
    setVerificationCode(otp);
    setVerificationSent(false);
  }, []);

  useEffect(() => {
    if (
      !verificationSent &&
      !verifying &&
      verificationCode?.length === OTP_LENGTH
    ) {
      verifyOTP();
    }
  }, [verificationCode, verificationSent, verifyOTP, verifying]);

  useEffectOnce(
    (ack) => {
      ack();
      if (phoneNumber?.id) {
        console.log(phoneNumber);
        if (
          !phoneNumber.verifiedAt ||
          isAfter(
            new Date(),
            addDays(parseJSON(phoneNumber.verifiedAt), REVERIFY_DAYS)
          )
        ) {
          setNewPhoneNumberId(phoneNumber.id);
          setIsContactable(phoneNumber.isContactable);
          setShouldHideContactable(phoneNumber.isContactable);
          sendOTP(phoneNumber.digits, () => {
            if (phoneNumber.verifiedAt) {
              updateInterfaceSetting({
                mfaLastVerified: phoneNumber.verifiedAt,
              });
            }
          });
        }
      }
    },
    [phoneNumber]
  );

  const onContactablePress = useCallback(async () => {
    if (newPhoneNumberId) {
      try {
        await fetchApi(`/api/v2/phone-numbers/${newPhoneNumberId}`, {
          method: "PATCH",
          body: JSON.stringify({
            data: {
              attributes: {
                "is-contactable": !isContactable,
              },
            },
          }),
        });
      } catch (error) {
        console.log(error);
      }
    }

    setIsContactable(!isContactable);
  }, [isContactable, newPhoneNumberId]);

  const contactableOptIn = useMemo(() => {
    if (isContactable && newPhoneNumberId && shouldHideContactable) return null;

    return (
      <div
        className="flex items-center justify-center gap-[15px] cursor-pointer italic mt-[10px]"
        onClick={onContactablePress}
      >
        <div
          className={twMerge(
            "flex items-center justify-center w-[20px] h-[20px] min-w-[20px] min-h-[20px] rounded border-[1px] border-solid border-shady-gray",
            isContactable && "bg-smart-teal border-0"
          )}
        >
          {isContactable && (
            <FontAwesomeIcon
              icon={faCheck}
              color="white"
              className="text-white"
            />
          )}
        </div>
        <span className="text-left max-w-[210px] md:max-w-none">
          I am okay to receive important updates from Smartkarma
        </span>
      </div>
    );
  }, [
    isContactable,
    newPhoneNumberId,
    onContactablePress,
    shouldHideContactable,
  ]);

  const form = useMemo(() => {
    if (newPhoneNumberId) {
      return (
        <>
          <h3 className="mb-[25px">Enter OTP to continue</h3>
          <div className="my-[25px]">
            We have sent an OTP to your verified telephone number ending in **
            {newPhoneNumber.slice(newPhoneNumber.length - 3)}
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center my-[15px] space-y-[15px] md:space-x-[15px] md:space-y-0">
            <OtpInput
              shouldAutoFocus
              value={verificationCode}
              onChange={onOTPChange}
              numInputs={OTP_LENGTH}
              renderSeparator={<div className="w-[5px]" />}
              renderInput={(props: any) => (
                <input
                  {...props}
                  {...(mobile ? { type: "number" } : {})}
                  className="text-active-black !w-[30px] !p-[5px] rounded-lg focus:!border-smart-teal focus:ring-smart-teal"
                />
              )}
              onPaste={verifyOTP}
            />

            <button
              className={twMerge(
                canVerify
                  ? "bg-smart-teal hover:bg-dark-teal"
                  : "bg-shady-gray cursor-none",
                "border-none rounded-full",
                "text-white font-medium",
                "leading-[1] md:leading-[24px]",
                "text-[15px]",
                "px-[1.25em] py-[0.75em] md:px-[1em] md:py-[0.5em]"
              )}
              onClick={verifyOTP}
            >
              Continue{" "}
              <FontAwesomeIcon
                className={twMerge(verifying && "animate-spin")}
                icon={verifying ? faSpinner : faArrowCircleRight}
              />
            </button>
          </div>
          <div className="flex items-center justify-center space-x-[30px]">
            <div
              className="text-smart-teal hover:text-dark-teal cursor-pointer"
              onClick={() => setNewPhoneNumberId(undefined)}
            >
              Change phone number
            </div>
            <div
              className={twMerge(
                "flex space-x-[4px]",
                "my-[15px]",
                canResend
                  ? "text-smart-teal hover:text-dark-teal cursor-pointer"
                  : "text-shady-gray cursor-not-allowed"
              )}
              onClick={resendOTP}
            >
              <span>Resend OTP</span>
              {!canResend && (
                <>
                  <span>in</span>
                  <Countdown
                    className="flex space-x-[4px]"
                    digitClassName="font-normal"
                    endTime={resendTime}
                    minutesOnly
                    onEnded={() => setCanResend(true)}
                  />
                </>
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <h3 className="mb-[25px">Mobile Number Verification</h3>
        <div className="my-[25px]">
          Enter your mobile phone number to continue
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center my-[15px] space-y-[15px] md:space-x-[15px] md:space-y-0">
          <PhoneInput
            autoFocus
            name="phone-number"
            className="text-[15px] border-shady-gray"
            international
            value={newPhoneNumber}
            onChange={(value) => {
              setNewPhoneNumber(value || "");
            }}
            countrySelectComponent={CountrySelectWithIcon}
          />

          <button
            className={twMerge(
              canSend
                ? "bg-smart-teal hover:bg-dark-teal"
                : "bg-shady-gray cursor-none",
              "border-none rounded-full",
              "text-white font-medium",
              "leading-[1] md:leading-[24px]",
              "text-[15px]",
              "px-[1.25em] py-[0.75em] md:px-[1em] md:py-[0.5em]"
            )}
            onClick={() => sendOTP()}
          >
            Continue{" "}
            <FontAwesomeIcon
              className={twMerge(sending && "animate-spin")}
              icon={sending ? faSpinner : faArrowCircleRight}
            />
          </button>
        </div>

        {canRemind && (
          <div className="flex justify-center">
            <div
              className="my-[15px] text-smart-teal hover:text-dark-teal cursor-pointer"
              onClick={setReminder}
            >
              Remind me later ({mfaReminderLeft} reminder
              {mfaReminderLeft > 1 ? "s" : ""} left)
            </div>
          </div>
        )}
      </>
    );
  }, [
    newPhoneNumberId,
    newPhoneNumber,
    canSend,
    sending,
    canRemind,
    setReminder,
    mfaReminderLeft,
    verificationCode,
    onOTPChange,
    verifyOTP,
    canVerify,
    verifying,
    canResend,
    resendOTP,
    resendTime,
    mobile,
    sendOTP,
  ]);

  const disclaimer = useMemo(
    () => (
      <>
        {contactableOptIn}

        <div className="italic mt-[15px]">
          To keep Smartkarma secure, we periodically require users to enter an
          OTP.
          <br />
          Refer to our{" "}
          <a href={privacyPolicy} target="_blank" className="text-smart-teal">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href={termsOfUse} target="_blank" className="text-smart-teal">
            T&Cs
          </a>{" "}
          for more information
        </div>
      </>
    ),
    [contactableOptIn]
  );

  if (skipVerification) {
    return (
      <Modal
        className="md:w-[800px] md:h-[500px]"
        overlayClassName="bg-black/80 flex flex-col md:justify-center"
        onClose={onClose}
      >
        <div
          className={twMerge(
            "p-[30px]",
            "text-center",
            "flex flex-col items-center",
            "h-full"
          )}
        >
          <FontAwesomeIcon
            icon={faShieldAlt}
            className="text-[80px] text-smart-teal mb-[45px]"
          />

          <div className="flex-1">
            <div className="text-[40px]">Thank You!</div>
          </div>

          {disclaimer}
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      hideCloseCTA
      className="md:w-[800px] md:h-[500px]"
      overlayClassName="bg-black/80 flex flex-col md:justify-center"
    >
      <div
        className={twMerge(
          "p-[30px]",
          "text-center",
          "flex flex-col items-center",
          "h-full"
        )}
      >
        <FontAwesomeIcon
          icon={faShieldAlt}
          className="text-[80px] text-smart-teal mb-[45px]"
        />

        <div className="flex-1">{form}</div>

        {disclaimer}
      </div>
    </Modal>
  );
};

export default AccountMFAModal;
