import { useCallback, useContext, useState } from "react";

import { getMessaging, getToken } from "firebase/messaging";

import { fetchApi } from "utils/baseFetcher";
import app from "utils/firebase";

import { useGlobalState } from "./useGlobalState";
import { UserAgentContext } from "./useMediaQuery";

export default function usePushNotifications() {
  const { userAgent } = useContext(UserAgentContext);

  const [notificationRequested, setNotificationRequested] = useState(false);
  const [pushNotficationToken, setPushNotificationToken] = useGlobalState(
    "push-notification-token",
    null
  );

  const postPushNotificationToken = useCallback(
    async (token) => {
      await fetchApi(`/api/v2/push-notification-tokens`, {
        method: "POST",
        body: JSON.stringify({
          data: {
            type: "push-notification-tokens",
            attributes: {
              token,
              platform: "web",
              browser: "chrome",
              "user-agent": userAgent,
            },
          },
        }),
      });
    },
    [userAgent]
  );

  const requestPushNotificationToken = useCallback(() => {
    const messaging = getMessaging(app);
    const getPushToken = getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_publicKey,
    });
    getPushToken
      .then((currentToken) => {
        if (currentToken !== pushNotficationToken) {
          setPushNotificationToken(currentToken);
          postPushNotificationToken(currentToken);
        }
      })
      .catch((err) => {
        const error =
          "AbortError: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker";
        if (err.toString() === error) {
          getPushToken;
        } else {
          throw err;
        }
      });
  }, [
    postPushNotificationToken,
    pushNotficationToken,
    setPushNotificationToken,
  ]);

  const requestNotificationPermission = useCallback(async () => {
    if ("serviceWorker" in navigator) {
      Notification.requestPermission().then(async (permission) => {
        setNotificationRequested(true);
        if (permission === "granted") {
          navigator.serviceWorker
            .register(`/notifications.js?${new Date().getTime()}`)
            .then(async () => {
              requestPushNotificationToken();
            });
        }
      });
    }
  }, [requestPushNotificationToken]);

  return {
    notificationRequested,
    requestNotificationPermission,
    pushNotficationToken,
    postPushNotificationToken,
    requestPushNotificationToken,
  };
}
