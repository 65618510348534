import dynamic from "next/dynamic";
import Head from "next/head";

import { ReactNode, useEffect, useState } from "react";

import InsightProviderPublicReferencesAgreement from "components/InsightProviderAgreement/PublicSourceAgreement";
import TopNavBar from "components/TopNavBar";
import useAuthentication from "hooks/useAuthentication";
import usePushNotifications from "hooks/usePushNotifications";
import useTheme from "hooks/useTheme";

const AISearch = dynamic(() => import("components/AISearch"), {
  ssr: false,
});

const Footer = dynamic(() => import("components/Footer"));
const MessagingDock = dynamic(() => import("components/MessagingDock"), {
  ssr: false,
});
const InsightProviderAgreement = dynamic(
  () => import("components/InsightProviderAgreement"),
  {
    ssr: false,
  }
);

type LayoutProps = {
  children: ReactNode;
  theme?: string;
};

const Layout = ({ children }: LayoutProps) => {
  const { authenticated } = useAuthentication();
  const [notificationPermission, setNotificationPermission] = useState<any>();

  const { requestPushNotificationToken } = usePushNotifications();
  const theme = useTheme();

  useEffect(() => {
    if (notificationPermission === "granted") {
      requestPushNotificationToken();
    }
  }, [notificationPermission, requestPushNotificationToken]);

  useEffect(() => {
    if ("Notification" in window) {
      setNotificationPermission(Notification.permission);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="theme-color" content="#40b187" />
        <meta name="msapplication-TileColor" content="#414141" />
        <meta
          name="msapplication-TileImage"
          content="https://assets.smartkarma.com/v2/img/apple-touch-icon.png"
        />
        <meta property="og:site_name" content="Smartkarma" />
        <meta name="twitter:site" content="@smartkarma" />
      </Head>
      <div className={`${theme}-theme`}>
        <TopNavBar />
        {children}
        {!authenticated && <Footer />}
        {authenticated && <MessagingDock />}
        {authenticated && <InsightProviderAgreement />}
        {authenticated && <InsightProviderPublicReferencesAgreement />}
        {authenticated && <AISearch />}
      </div>
    </>
  );
};

export default Layout;
