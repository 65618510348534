import { useRouter } from "next/router";
import Script from "next/script";

import { useEffect } from "react";

import useActivityTracker from "hooks/useActivityTracker";
import useAuthentication from "hooks/useAuthentication";

export const Gtag = () => {
  const router = useRouter();
  const { trackEvent } = useActivityTracker();

  useEffect(() => {
    trackEvent("page_view", {
      page_title: document.title,
      page_location: document.location.href,
    });
    const handleRouteChange = () => {
      trackEvent("page_view", {
        page_title: document.title,
        page_location: document.location.href,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, trackEvent]);

  const { account } = useAuthentication();

  useEffect(() => {
    if (!account) return;
    if (typeof gtag === "undefined") return;

    const config = {
      send_page_view: false,
      user_id: account.id,
      userId: account.id,
      account_type: account.accountType,
      account_subtype: account.clientType || account.insightProviderType,
      Accountsubtype: account.clientType || account.insightProviderType,
    };

    if (process.env.gaMeasurementId2) {
      gtag?.("config", process.env.gaMeasurementId2, config);
    }
    if (process.env.gaMeasurementId3) {
      gtag?.("config", process.env.gaMeasurementId3, config);
    }
    if (process.env.gaMeasurementId) {
      gtag?.("config", process.env.gaMeasurementId, config);
    }
  }, [account]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.gaMeasurementId}`}
        strategy="worker"
      />
      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              window.gtag = function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              if ('${process.env.gaMeasurementId2}') {
                gtag('config', '${process.env.gaMeasurementId2}', { 
                  send_page_view: false,
                });
              }
              if ('${process.env.gaMeasurementId3}') {
                gtag('config', '${process.env.gaMeasurementId3}', { 
                  send_page_view: false,
                });
              }
              gtag('config', '${process.env.gaMeasurementId}', { 
                  send_page_view: false,
              });
          `,
        }}
      />
    </>
  );
};
