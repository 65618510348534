export const apiBaseUrl = process.env.apiBaseUrl as string;
export const apiToken = process.env.apiToken as string;
export const emberAppBaseUrl = process.env.emberAppBaseUrl as string;
export const dataCloudHost = process.env.dataCloudHost as string;
export const recaptchaKey = process.env.recaptchaKey as string;
export const websocketUrl = process.env.websocketUrl as string;
export const stripePublishableKey = process.env.stripePublishableKey as string;
export const adobePdfViewerKey = process.env.adobePdfViewerKey as string;
export const chatbotWhatsapp = process.env.chatbotWhatsapp as string;
export const getSiteControlId = process.env.getSiteControlId as string;
export const openAItoken = process.env.openAItoken as string;
export const ragContextOpenAIKey = process.env.ragContextOpenAIKey as string;
export const ragContextEndpoint = process.env.ragContextEndpoint as string;
export const ragContextApiKey = process.env.ragContextApiKey as string;
export const canonicalUrl = emberAppBaseUrl;
export const GOOGLE_SSO_CLIENT_ID = process.env.GOOGLE_SSO_CLIENT_ID as string;
export const NEXT_PUBLIC_PAYMENT_ANALYTICS_DISABLED_ID = process.env
  .NEXT_PUBLIC_PAYMENT_ANALYTICS_DISABLED_ID as string;
