import Bugsnag from "@bugsnag/js";
import { serializer, typeConfig } from "models";
import type { GetServerSidePropsContext } from "next";

import { apiBaseUrl, apiToken, emberAppBaseUrl } from "utils/envVariables";

import getRequestIP from "./getRequestIP";
import parseCookie from "./parseCookie";

export const botTypes = [
  "bot",
  "googlebot",
  "Googlebot",
  // "Chrome-Lighthouse",
  "DuckDuckBot",
  "ia_archiver",
  "bingbot",
  "yandex",
  "baiduspider",
  "Facebot",
  "facebookexternalhit",
  "facebookexternalhit/1.1",
  "twitterbot",
  "rogerbot",
  "linkedinbot",
  "embedly",
  "quora link preview",
  "showyoubot",
  "outbrain",
  "pinterest",
  "slackbot",
  "vkShare",
  "W3C_Validator",
];

export class ApiEndpoint {
  _pathname: string;
  searchParams: URLSearchParams;

  constructor(pathname: string) {
    this._pathname = pathname;
    this.searchParams = new URLSearchParams();
  }

  get href() {
    return `${this._pathname}${
      this._pathname.includes("?") ? "&" : "?"
    }${this.searchParams.toString()}`;
  }
}

export const baseFetcher = async (
  endpoint: string,
  context?: GetServerSidePropsContext,
  otherOptions?: {
    authenticatedOnly?: boolean;
    includeStatusCode?: boolean;
    fetchOptions?: any;
  }
) => {
  let url = endpoint;
  let headers: { [key: string]: string } = {};

  if (
    typeof document !== "undefined" &&
    document.cookie &&
    parseCookie(document.cookie)["ember_simple_auth-session"]
  ) {
    const session = JSON.parse(
      decodeURIComponent(
        parseCookie(document.cookie)["ember_simple_auth-session"]
      )
    );
    if (session.authenticated?.token) {
      headers.authorization = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
      headers[
        "x-sk-authorization"
      ] = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
    }
  } else if (context) {
    const session = JSON.parse(
      decodeURIComponent(
        context.req.cookies["ember_simple_auth-session"] || "{}"
      )
    );
    if (session.authenticated?.token) {
      headers.authorization = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
      headers[
        "x-sk-authorization"
      ] = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
    } else if (otherOptions?.authenticatedOnly) {
      if (otherOptions?.includeStatusCode) {
        return [null, 403];
      }
      return null;
    }

    const ip = getRequestIP(context.req);
    if (ip) headers["x-sk-client-ip"] = ip;

    const userAgent: string = context.req.headers["user-agent"] || "";
    const isBot = new RegExp(botTypes.join("|").slice(0, -1), "i").test(
      userAgent
    );
    if (isBot) headers["user-agent"] = "nextjs user agent - (prerender)";
  }

  if (!/https?:\/\/.*/.exec(url)) {
    if (typeof window === "undefined") {
      url = `${apiBaseUrl}${endpoint}`;
      headers["API-TOKEN"] = apiToken;
    } else if (
      ["/api/v2", "/api/v3"].some((prefix) => endpoint.startsWith(prefix))
    ) {
      url = `${endpoint}`;
    } else {
      url = `${emberAppBaseUrl}${endpoint}`;
    }
  }

  try {
    const res = await fetch(url, {
      headers,
      ...(otherOptions?.fetchOptions || {}),
    });

    if (res.headers.get("content-type") === "text/html") return null;

    const json = await res.json();

    if (json.errors) {
      // Bugsnag.notify(new Error("API error"), (event) => {
      //   event.severity = "warning";
      //   event.addMetadata("response", {
      //     endpoint,
      //     headers: {
      //       ...headers,
      //       authorization: null,
      //       "x-sk-authorization": null,
      //     },
      //     status: res.status,
      //     response: json,
      //   });
      // });
      throw { status: res.status, response: json };
    }

    if (!json.data) {
      // not json:api response
      if (otherOptions?.includeStatusCode) {
        return [json, res.status];
      }
      return json;
    }

    let data: any;

    try {
      data = serializer.deserialize(typeConfig, json);
    } catch (err: any) {
      console.debug(err);

      if (err.message?.startsWith("No type registered for ")) {
        const type = err.message.match(/No type registered for ([\w-]+)/)?.[1];
        if (type) {
          console.log("register new type", type);
          serializer.register(type, {
            id: "id",
            afterDeserialize: (data: any) => {
              return {
                type: type,
                ...data,
              };
            },
          });
          data = serializer.deserialize(typeConfig, json);
        }
      } else {
        Bugsnag.notify(err, (e) => {
          e.severity = "info";
          e.unhandled = false;
          e.addMetadata("fetch info", {
            endpoint,
            headers: {
              ...headers,
              authorization: null,
              "x-sk-authorization": null,
            },
          });
          return true;
        });

        if (otherOptions?.includeStatusCode) {
          return [json, res.status];
        }
        return json;
      }
    }

    if (json.meta?.["total-pages"]) {
      data.forEach((item: any) => {
        if (typeof item.primaryEntity === "string") {
          const entity = data.find((i: any) => i.id === item.primaryEntity);
          if (entity) {
            item.primaryEntity = entity;
          }
        }
      });

      const rs = {
        meta: {
          totalPages: json.meta["total-pages"],
          recordCount: json.meta["record-count"],
          totalCount: json.meta["total-count"] ?? 0,
          // search endpoint
          searchjoySearchId: json.meta["searchjoy-search-id"] || null,
        },
        data,
      };

      if (otherOptions?.includeStatusCode) {
        return [rs, res.status];
      }

      return rs;
    }

    if (otherOptions?.includeStatusCode) {
      return [data, res.status];
    }
    return data;
  } catch (err: any) {
    console.debug("err", err);

    // Bugsnag.notify(err, (event) => {
    //   event.severity = "error";
    //   event.unhandled = false;
    // });

    if (otherOptions?.includeStatusCode) {
      return [null, err.status || 500];
    }
    return null;
  }
};

type optional = {
  stream?: boolean;
};

export const fetchApi = async (
  endpoint: string,
  options?: any,
  optional?: optional
) => {
  const { stream } = optional || {};

  let url = endpoint;
  let headers: { [key: string]: string } = {
    "content-type": stream ? "text/event-stream" : "application/vnd.api+json",
    ...options?.headers,
  };

  if (
    typeof document !== "undefined" &&
    document.cookie &&
    parseCookie(document.cookie)["ember_simple_auth-session"]
  ) {
    const session = JSON.parse(
      decodeURIComponent(
        parseCookie(document.cookie)["ember_simple_auth-session"]
      )
    );
    if (session.authenticated?.token) {
      headers.authorization = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
      headers[
        "x-sk-authorization"
      ] = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
    }
  } else if (options?.context) {
    const context = options.context;
    const session = JSON.parse(
      decodeURIComponent(
        context.req.cookies["ember_simple_auth-session"] || "{}"
      )
    );
    if (session.authenticated?.token) {
      headers.authorization = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
      headers[
        "x-sk-authorization"
      ] = `Token token="${session.authenticated.token}", email="${session.authenticated.email}"`;
    } else if (options?.authenticatedOnly) {
      if (options?.includeStatusCode) {
        return [null, 403];
      }
      return null;
    }

    const ip = getRequestIP(context.req);
    if (ip) headers["x-sk-client-ip"] = ip;

    const userAgent: string = context.req.headers["user-agent"] || "";
    const isBot = new RegExp(botTypes.join("|").slice(0, -1), "i").test(
      userAgent
    );
    if (isBot) headers["user-agent"] = "nextjs user agent - (prerender)";
  }

  if (!/https?:\/\/.*/.exec(url)) {
    if (typeof window === "undefined") {
      url = `${apiBaseUrl}${endpoint}`;
      headers["API-TOKEN"] = apiToken;
    } else if (
      ["/api/v2", "/api/v3"].some((prefix) => endpoint.startsWith(prefix))
    ) {
      url = `${endpoint}`;
    } else {
      url = `${emberAppBaseUrl}${endpoint}`;
    }
  }

  const response = await fetch(url, { ...options, headers });

  if (options?.method === "DELETE") {
    try {
      return await response.json();
    } catch {
      return response;
    }
  }

  if (stream) {
    return response;
  }

  try {
    const json = await response.json();

    if (!json.data) {
      // not json:api response
      return json;
    }

    let data;

    try {
      data = serializer.deserialize(typeConfig, json);
    } catch (err: any) {
      console.log("err", err);

      if (err.message?.startsWith("No type registered for ")) {
        const type = err.message.match(/No type registered for ([\w-]+)/)?.[1];
        if (type) {
          console.log("register new type", type);
          serializer.register(type, {
            id: "id",
            afterDeserialize: (data: any) => {
              return {
                type: type,
                ...data,
              };
            },
          });
          data = serializer.deserialize(typeConfig, json);
        }
      } else {
        Bugsnag.notify(err, (e) => {
          e.severity = "info";
          e.unhandled = false;
          e.addMetadata("fetch info", {
            endpoint,
            headers: {
              ...headers,
              authorization: null,
              "x-sk-authorization": null,
            },
          });
          return true;
        });

        return json;
      }
    }

    if (json.meta?.["total-pages"]) {
      return {
        meta: {
          totalPages: json.meta["total-pages"],
          recordCount: json.meta["record-count"],
        },
        data: data,
      };
    }

    return data;
  } catch {
    return "";
  }
};
