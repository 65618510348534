import Image from "next/image";

import React, { useMemo } from "react";

import { twMerge } from "tailwind-merge";

import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowCircleRight";

import FontAwesomeIcon from "components/FontAwesomeIcon";
import Modal from "components/Modal";

const AccountSessionLockModal = ({
  onContinue,
}: {
  onContinue?: () => void;
}) => {
  const form = useMemo(() => {
    return (
      <>
        <h3 className="mb-[25px">Use Smartkarma Here</h3>
        <div className="my-[15px] md:my-[25px] text-[15px]">
          You seem to be using Smartkarma on another device / browser.
          <br className="hidden md:inline" />
          Press the button below to continue using here.
        </div>
        <div className="flex justify-center my-[15px] space-x-[15px]">
          <button
            className={twMerge(
              "bg-smart-teal hover:bg-dark-teal",
              "border-none rounded-full",
              "text-white font-medium",
              "leading-[1] md:leading-[24px]",
              "text-[15px]",
              "px-[1.25em] py-[0.75em] md:px-[1em] md:py-[0.5em]"
            )}
            onClick={onContinue}
          >
            Continue Here <FontAwesomeIcon icon={faArrowCircleRight} />
          </button>
        </div>
      </>
    );
  }, [onContinue]);

  return (
    <Modal
      hideCloseCTA
      className="h-[333px] fixed !bottom-0 md:static md:bottom-auto md:w-[800px] md:h-[350px] rounded-b-none md:rounded-xl"
      overlayClassName="bg-black/90 flex flex-col md:justify-center"
    >
      <div className={twMerge("p-[30px]", "text-center")}>
        <div>
          <Image
            src={require("./monitors-couple-with-two-screens.png")}
            width={80}
            height={80}
            alt="two-screens"
            style={{
              filter:
                "invert(51%) sepia(73%) saturate(422%) hue-rotate(130deg) brightness(93%) contrast(92%)",
            }}
            // className="invert-[51%] sepia-[73%] saturate-[422%] hue-rotate-[130deg] brightness-[93%] contrast-[92%] "
            className="mb-[15px]"
          />
        </div>

        {form}
      </div>
    </Modal>
  );
};

export default AccountSessionLockModal;
