import dynamic from "next/dynamic";

import React from "react";

import { twMerge } from "tailwind-merge";

import useMediaQuery from "hooks/useMediaQuery";

const ReactModal = dynamic(() => import("react-modal"), { ssr: false });

const TopNavBarMobile = dynamic(
  () => import("components/TopNavBar/TopNavBarMobile"),
  {
    ssr: false,
  }
);

const Modal = ({
  children,
  isOpen,
  onClose,
  className,
  overlayClassName,
  portalClassName,
  closeCTAClassName,
  hideCloseCTA,
  fullMobilePage,
  onAfterClose,
  onAfterOpen,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  customBackButtonLabel,
}: {
  children?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  overlayClassName?: string;
  portalClassName?: string;
  closeCTAClassName?: string;
  hideCloseCTA?: boolean;
  fullMobilePage?: boolean;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  customBackButtonLabel?: string;
}) => {
  const { mobile } = useMediaQuery();

  return (
    <ReactModal
      portalClassName={twMerge("z-[1999]", portalClassName)}
      overlayClassName={twMerge(
        "z-[1999] fixed inset-0 bg-[rgba(31,31,32,.77)] overflow-y-auto",
        overlayClassName
      )}
      className={twMerge(
        mobile
          ? "h-full overflow-y-auto"
          : "relative w-[720px] mx-auto my-[130px]",
        mobile && !hideCloseCTA && "pt-[63px]",
        "outline-none rounded-xl bg-white",
        "flex flex-col",
        className
      )}
      isOpen={typeof isOpen !== "undefined" ? isOpen : true}
      onAfterOpen={() => {
        if (!mobile || !fullMobilePage) {
          document.body.style.overflowY = "hidden";
        }

        if (onAfterOpen) {
          onAfterOpen();
        }
      }}
      onAfterClose={() => {
        if (!mobile || !fullMobilePage) {
          document.body.style.overflowY = "";
        }

        if (onAfterClose) {
          onAfterClose();
        }
      }}
      onRequestClose={onClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {!hideCloseCTA &&
        (mobile ? (
          <TopNavBarMobile
            onGoBack={onClose}
            customBackButtonLabel={customBackButtonLabel || "Cancel"}
          />
        ) : (
          <button
            className={twMerge(
              "absolute top-[-5px] right-[10px] border-0 bg-transparent text-[40px] text-shady-gray hover:text-active-black",
              closeCTAClassName
            )}
            onClick={onClose}
          >
            ×
          </button>
        ))}

      {children}
    </ReactModal>
  );
};

export default Modal;
