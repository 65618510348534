export const transform = (raw: any) => {
  return {
    ...raw,

    get text() {
      return raw.name;
    },

    get isTeamPlan() {
      return raw.name === "Teamwide";
    },

    get isInstaPassPlan() {
      return raw.name === "Insta Pass";
    },
  };
};
