import { useContext } from "react";

import { NotificationContext } from "./NotificationContext";

const useNotification = () => {
  const notificationContext = useContext(NotificationContext);

  return notificationContext;
};

export default useNotification;
