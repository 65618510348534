import { useRouter } from "next/router";
import type { GetServerSidePropsContext } from "next/types";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import Bugsnag from "@bugsnag/js";
import type { Account } from "models/account";
import { useEffectOnce } from "usehooks-ts";

import { setSessionCookies } from "utils/setSessionCookies";
import { expirationTime } from "consts/expireTime";

import { ApiEndpoint, baseFetcher, fetchApi } from "../utils/baseFetcher";
import kebabCase from "../utils/kebabCase";
import parseCookie from "../utils/parseCookie";

import { useApi } from "./useApi";

type AuthenticationContextValue = {
  authenticated: boolean;
  accountId?: string;
  account: Account | null;
  managedEntity?: any;
  isAccountLoading: boolean;
  interfaceSetting?: any;
  updateInterfaceSetting: (_newValue: any) => void;
  updateAccount: (_newAttributes: any) => void;
  mutateAccount: (_newValue?: any, _options?: any) => void;
};

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  authenticated: false,
  account: null,
  managedEntity: null,
  isAccountLoading: true,
  interfaceSetting: null,
  updateInterfaceSetting: () => {},
  updateAccount: () => {},
  mutateAccount: () => {},
});

type AuthenticationContextProviderProps = {
  children: React.ReactNode;
  cookie: string;
};

export const authenticatedAccountUrl = (accountId?: string | number) => {
  if (!accountId) return "";

  const url = new ApiEndpoint(`/api/v2/accounts/${accountId}`);
  url.searchParams.append("no-view-count", "true");
  url.searchParams.append(
    "include",
    "introducer-company,company,company.corporate-plan,plan,account-lead"
  );
  url.searchParams.append(
    "fields[accounts]",
    [
      "introducer-company",
      "company",
      "plan",
      "account-lead",

      "slug",
      "name",
      "email",
      "short-name",
      "company-name",
      "title",
      "country",
      "avatar-url",
      "is-client",
      "is-insight-provider",
      "is-corporate-officer",
      "is-compliance-officer",
      "is-preview-pass",
      "is-individual-pass",
      "is-introducer",
      "is-ps-manager",
      "can-post-comments",
      "client-type",
      "insight-provider-type",
      "total-unread-message-groups",
      "total-unread-notifications",
      "notifications-read-at",
      "preview-pass-interest",
      "preview-pass-related-company",
      "access-end-at",
      "trial-end-date",
      "interface-setting",
      "current-month-republished-insights",
      "has-changed-password",

      "country-mandates",
      "geography-mandates",
      "sector-mandates",
      "max-market-cap",
      "min-market-cap",

      "muted-contents",
      "time-zone",

      "funnel-status",

      "top-author-ids",
      "top-entity-ids",
      "top-content-vertical-ids",
      "top-sectors",
      "top-countries",
      "total-activities",
      "total-watched-entities",
      "total-followed-insight-providers",
      "source-type",
      "source-id",
      "cfa-expiry-at",
      "created-at",
    ].join(",")
  );
  url.searchParams.append(
    "fields[companies]",
    [
      "slug",
      "name",
      "corporate-plan",
      "disable-viewing-comments",
      "disable-posting-comments",
      "disable-private-messages",
      "enable-email-communication",
      "enable-ps-requests",
    ].join(",")
  );

  return url.href;
};

export const managedEntitiesUrl = (companyId?: string | number) => {
  if (!companyId) return "";

  const url = new ApiEndpoint(
    `/api/v2/companies/${companyId}/managed-entities`
  );
  url.searchParams.append("fields[entities]", "slug");

  return url.href;
};

export const sessionFromContextCookie = (
  context: GetServerSidePropsContext
) => {
  return JSON.parse(
    decodeURIComponent(context.req.cookies["ember_simple_auth-session"] || "{}")
  );
};

export const AuthenticationContextProvider = ({
  children,
  cookie,
}: AuthenticationContextProviderProps) => {
  const router = useRouter();

  const authSessionTokenCookie = parseCookie(cookie || "")?.[
    "ember_simple_auth-session"
  ];
  const token = JSON.parse(authSessionTokenCookie || "{}");

  const [authenticated] = useState(!!token?.authenticated?.token);

  const {
    data: account,
    isValidating: isAccountLoading,
    mutate: mutateAccount,
  } = useApi<Account>(
    authenticated
      ? authenticatedAccountUrl(token?.authenticated?.account_id)
      : null,
    (url: string) =>
      baseFetcher(url + (url.includes("?") ? "&" : "?") + new Date().getTime()),
    {
      onSuccess: (account: Account) => {
        Bugsnag.setUser(account?.id, account?.email, account?.name);
      },
    }
  );

  const { data: managedEntities } = useApi(
    account?.isCorporateOfficer
      ? managedEntitiesUrl(account?.company?.id)
      : null,
    baseFetcher
  );

  const interfaceSetting = account?.interfaceSetting;

  const updateInterfaceSetting = useCallback(
    async (newSetting: any) => {
      if (!authenticated || !account) return;

      const payload = kebabCase({
        data: {
          id: account?.id,
          attributes: {
            interfaceSetting: {
              ...newSetting,
            },
          },
          type: "accounts",
        },
      });
      await fetchApi(`/api/v2/accounts/${account?.id}`, {
        method: "PATCH",
        body: JSON.stringify(payload),
      });
      await mutateAccount(
        {
          ...account,
          interfaceSetting: { ...interfaceSetting, ...newSetting },
        },
        { revalidate: false }
      );
    },
    [account, authenticated, interfaceSetting, mutateAccount]
  );

  const updateAccount = useCallback(
    (newAttributes: any) => {
      if (!authenticated || !account) return;

      const payload = kebabCase({
        data: {
          id: account?.id,
          attributes: newAttributes,
          type: "accounts",
        },
      });
      fetchApi(`/api/v2/accounts/${account?.id}`, {
        method: "PATCH",
        body: JSON.stringify(payload),
      });
      mutateAccount(
        {
          ...account,
          ...newAttributes,
        },
        { revalidate: false }
      );
    },
    [account, authenticated, mutateAccount]
  );

  const managedEntity = managedEntities?.data?.[0];

  const value = {
    authenticated,
    accountId: token?.authenticated?.account_id,
    account,
    managedEntity,
    isAccountLoading,
    interfaceSetting,
    updateInterfaceSetting,
    mutateAccount,
    updateAccount,
  };

  useEffect(() => {
    if (!account) return;

    if (account?.introducerCompany?.id === "673") {
      const theme = "bos";
      document.cookie = `sk-theme=${theme}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }
    if (account.isCorporateOfficer) {
      document.cookie = `sk-account-type=corporate-officer; path=/`;
      document.cookie = `sk-account-subtype=; path=/`;
      document.cookie = `sk-managed-entity=${managedEntity?.slug}; path=/`;
      document.cookie = `sk-preview-pass-interest=; path=/`;
    } else if (account.isPreviewPass) {
      document.cookie = `sk-account-type=preview-pass; path=/`;
      document.cookie = `sk-account-subtype=; path=/`;
      document.cookie = `sk-preview-pass-interest=${account?.previewPassInterest}; path=/`;
      document.cookie = `sk-preview-pass-interest=; path=/`;
    } else {
      document.cookie = `sk-account-type=${account?.accountCookieType}; path=/`;
      document.cookie = `sk-account-subtype=${
        account?.clientType || account?.insightProviderType
      }; path=/`;
      document.cookie = `sk-managed-entity=; path=/`;
      document.cookie = `sk-preview-pass-interest=; path=/`;
    }
    document.cookie = `sk-top-author-ids=${account?.topAuthorIds}; path=/`;
    document.cookie = `sk-top-entity-ids=${account?.topEntityIds}; path=/`;
    document.cookie = `sk-top-content-vertical-ids=${account?.topContentVerticalIds}; path=/`;
    document.cookie = `sk-top-sectors=${account?.topSectors}; path=/`;
    document.cookie = `sk-top-countries=${account?.topCountries}; path=/`;
    document.cookie = `sk-total-activities=${account?.totalActivities}; path=/`;
    document.cookie = `sk-total-watched-entities=${account?.totalWatchedEntities}; path=/`;
    document.cookie = `sk-total-followed-insight-providers=${account?.totalFollowedInsightProviders}; path=/`;
    document.cookie = `sk-signup-source-type=${account?.sourceType}; path=/`;
    document.cookie = `sk-signup-source-id=${account?.sourceId}; path=/`;
    document.cookie = `sk-introducer-company-id=${account?.introducerCompany?.id}; path=/`;
    document.cookie = `sk-cfa-expiry-at=${account?.cfaExpiryAt}; path=/`;

    if (
      interfaceSetting?.requiresOnboarding &&
      !router.asPath?.startsWith?.("/onboarding")
    ) {
      if (account?.isPreviewPass) {
        router.push("/onboarding/v2");
      } else {
        location.href = "/onboarding";
      }
      return;
    }
  }, [
    account,
    interfaceSetting,
    interfaceSetting?.requiresOnboarding,
    managedEntity?.slug,
    router,
  ]);

  useEffectOnce(() => {
    console.log("authProvider useEffectOnce", new Date().getTime());
    const magicToken = router.query["magic-token"] as string;
    const temporaryToken = router.query["temporary-token"] as string;

    if (magicToken || temporaryToken) {
      if (authenticated) {
        const query = { ...router.query };
        delete query["magic-token"];
        delete query["temporary-token"];
        router.replace({
          pathname: router.pathname,
          query,
        });
        return;
      }

      fetchApi(
        `/api/v2/accounts/${magicToken ? "magic" : "temporary"}-authentication`,
        {
          method: "POST",
          body: JSON.stringify(
            magicToken
              ? { "magic-token": magicToken }
              : { "temporary-token": temporaryToken }
          ),
        }
      ).then(async (data) => {
        if (data?.token) {
          const expireTime = expirationTime();

          data["authenticator"] = "authenticator:access-token";
          document.cookie = `ember_simple_auth-session=${encodeURIComponent(
            JSON.stringify({ authenticated: data })
          )}; path=/; expires=${expireTime};`;
          document.cookie = `ember_simple_auth-session-expiration_time=2592000; expires=${expireTime}; path=/`;

          const account = await setSessionCookies(data);

          if (account?.interfaceSetting?.requiresOnboarding) {
            localStorage.setItem("firstLogin", "true");
          } else if (localStorage.getItem("firstLogin")) {
            localStorage.removeItem("firstLogin");
          }

          const url = new URL(location.href);
          url.searchParams.delete("magic-token");
          url.searchParams.delete("temporary-token");
          history.replaceState({}, "", url.href);
          router.reload();
        }
      });
    }
  });

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default function useAuthentication() {
  return useContext(AuthenticationContext);
}
