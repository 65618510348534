import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import type { Dispatch } from "react";

type GlobalStateContextValue = {
  state: any;
  dispatch: Dispatch<{
    [key: string]: any;
    type: string;
  }>;
};

const DEFAULT_STATE = {
  state: {},
  dispatch: () => {},
};

export const GlobalStateContext =
  createContext<GlobalStateContextValue>(DEFAULT_STATE);

const reducer = (state: any, action: { type: string; [key: string]: any }) => {
  switch (action.type) {
    case "merge": {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data,
        },
      };
    }
    case "replace": {
      return {
        ...state,
        [action.key]: action.data,
      };
    }
    default:
      return state;
  }
};

export const GlobalStateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, {});

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalReducer = (
  key: string | number,
  options?: {
    initialData?: any;
  }
) => {
  const initialData = options?.initialData;

  const { state, dispatch } = useContext(GlobalStateContext);
  const data = state[`${key}`];

  const merge = useCallback(
    (data) => {
      dispatch({ type: "merge", key, data });
    },
    [dispatch, key]
  );

  const replace = useCallback(
    (data) => {
      dispatch({ type: "replace", key, data });
    },
    [dispatch, key]
  );

  useEffect(() => {
    if (typeof initialData !== "undefined" && typeof data === "undefined") {
      replace(initialData);
    }
  }, [data, initialData, replace]);

  return {
    data: typeof data !== "undefined" ? data : initialData,
    merge,
    replace,
    dispatch,
  };
};

export const useGlobalState = (key: string | number, initialData?: any) => {
  const { data, replace, merge, dispatch } = useGlobalReducer(key, {
    initialData,
  });

  return [data, replace, merge, dispatch];
};

export const useGlobalAction = (
  key: string | number,
  callback?: (..._props: any[]) => void
) => {
  const { data, replace } = useGlobalReducer(key);

  useEffect(() => {
    if (callback && !data?.callbacks?.includes(callback)) {
      replace({
        callbacks: [callback],
      });
    }

    return () => {
      if (callback && data?.callbacks?.includes(callback)) {
        replace({
          callbacks: data?.callbacks?.filter((c: any) => c === callback) || [],
        });
      }
    };
  }, [callback, data?.callbacks, replace]);

  const dispatchAction = useCallback(
    (...props) => {
      data?.callbacks?.forEach((callback: any) => {
        try {
          callback?.(...props);
        } catch (error) {
          console.debug(error);
        }
      }, []);
    },
    [data?.callbacks]
  );

  const rs = useMemo(() => [dispatchAction], [dispatchAction]);

  return rs;
};
